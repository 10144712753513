import PropTypes from 'prop-types';
import React from 'react';
import { Help } from '@material-ui/icons';

const styles = require('./HelpboxContainer.scss');

const HelpboxContainer = ({ children, headline }) => {
  return (
    <div className={styles.help}>
      <div className={styles.helpIcon}>
        <Help color="primary" style={{ width: 50, height: 50 }} />
      </div>
      {headline && <h3>{headline}</h3>}
      {children}
    </div>
  );
};

HelpboxContainer.propTypes = {
  children: PropTypes.any.isRequired,
  headline: PropTypes.string
};

export default HelpboxContainer;
