import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { asyncConnect } from 'redux-connect';

import { getAllCountries } from 'actions/location/location';

import { saveProperty } from 'signals/property';

import ContactForm from './ContactForm/ContactForm';

let Contact = ({ property, savePropertySignal }) => {
  const onSubmit = React.useCallback(data =>
    savePropertySignal({
      propertyId: property.id,
      data
    })
  );
  return <ContactForm {...{ property, onSubmit }} />;
};

Contact.propTypes = {
  property: PropTypes.object.isRequired,
  savePropertySignal: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  property: state.property
});

const mapDispatchToProps = { savePropertySignal: saveProperty };
Contact = connect(mapStateToProps, mapDispatchToProps)(Contact);

export default asyncConnect([
  {
    promise: ({ store: { dispatch } }) => {
      const promises = [];
      promises.push(dispatch(getAllCountries()));
      return Promise.all(promises);
    }
  }
])(Contact);
