import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { FormContainer } from 'rb-toolset/lib/ui/FormControls';
import { CheckCircle } from '@material-ui/icons';
import { connect } from 'react-redux';
import { ListItem, List } from '@material-ui/core';
import { pick } from 'lodash';
import { Column } from 'rb-toolset/lib/ui/Layout';

const PoliciesTaxes = ({ propertyId, taxes }) => {
  const { push } = useHistory();

  const handleClick = React.useCallback(id =>
    push(`/${propertyId}/housing/policies/tax/edit/${id}`)
  );

  const handleNewTax = React.useCallback(() =>
    push(`/${propertyId}/housing/policies/tax/new`)
  );

  return (
    <FormContainer
      title="Endreinigung & Besteuerungsgruppen"
      handleNew={() => handleNewTax()}
      hideFooter
      fullWidth
    >
      <List>
        {taxes.map((tax, index) => {
          return (
            <ListItem button onClick={() => handleClick(tax.id)} key={index}>
              <Column width={1 / 2}>{tax.name}</Column>
              <Column width={1 / 2}>
                {tax.isDefault ? <CheckCircle color="primary" /> : null}
              </Column>
            </ListItem>
          );
        })}
      </List>
    </FormContainer>
  );
};

PoliciesTaxes.propTypes = {
  propertyId: PropTypes.number.isRequired,
  taxes: PropTypes.array.isRequired
};

export default connect(state => ({
  taxes: state.property.fees,
  initialValues: {
    ...pick(state.property, ['termsText', 'taxTermsText', 'termsLink'])
  }
}))(PoliciesTaxes);
