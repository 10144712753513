import React, { Component } from 'react';
import { connect } from 'react-redux';
import { asyncConnect } from 'redux-connect';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getUpcomingBookings } from 'actions/booking/booking';
import { Const } from 'rb-domain';
import GuestsDetailsScreen from './GuestsDetailsScreen/GuestsDetailsScreen';

const mapStateToProps = state => ({
  bookings: state.dashboard.upcomingBookings
});

const mapDispatchToProps = dispatch => {
  return {
    dispatchGetUpcomingBookings: (propertyId, { start, end }) => {
      dispatch(
        getUpcomingBookings(propertyId, {
          arrivalFrom: moment(start)
            .subtract(1, 'month')
            .startOf('month')
            .format(Const.DAY_PATTERN),
          arrivalTo: moment(end)
            .add(1, 'month')
            .endOf('month')
            .format(Const.DAY_PATTERN)
        })
      );
    }
  };
};

@asyncConnect([
  {
    key: 'test',
    promise: options => {
      const { store: { dispatch } } = options;
      const propertyId = parseInt(options.match.params.propertyId, 10);

      return dispatch(
        getUpcomingBookings(propertyId, {
          arrivalFrom: moment()
            .subtract(1, 'month')
            .startOf('month')
            .format(Const.DAY_PATTERN),
          arrivalTo: moment()
            .add(1, 'month')
            .endOf('month')
            .format(Const.DAY_PATTERN)
        })
      );
    }
  },
  {
    key: 'propertyId',
    promise: options => {
      return Promise.resolve(parseInt(options.match.params.propertyId, 10));
    }
  }
])
@connect(mapStateToProps, mapDispatchToProps)
export default class GuestDetails extends Component {
  static propTypes = {
    bookings: PropTypes.array.isRequired,
    propertyId: PropTypes.number.isRequired,
    dispatchGetUpcomingBookings: PropTypes.func.isRequired
  };

  onCalendarRangeChange = ({ start, end }) => {
    const { propertyId, dispatchGetUpcomingBookings } = this.props;
    dispatchGetUpcomingBookings(propertyId, { start, end });
  };

  render() {
    return (
      <GuestsDetailsScreen
        onCalendarRangeChange={this.onCalendarRangeChange}
        bookings={this.props.bookings.map(e => ({
          title: `${e.bookingUserDataFinal.firstName} ${e.bookingUserDataFinal
            .lastName}`,
          start: moment(e.bookingOffers.reverse()[0].arrival).toDate(),
          end: moment(e.bookingOffers.reverse()[0].departure).toDate(),
          booking: e
        }))}
      />
    );
  }
}
