import React from 'react';
import PropTypes from 'prop-types';
import { min } from 'lodash';
import {
  Card,
  CardHeader,
  CardContent,
  Collapse,
  IconButton
} from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';

import RoomOptionForm from './RoomOptionForm/RoomOptionForm';
import useStyles from './RoomFormStyles';

const RoomFormOptions = ({
  cancellationTerms,
  cleanupFees,
  expandedOption,
  fields,
  guarantees,
  occupancyMax,
  options,
  setExpandedOption,
  showConfirmation,
  vistorFees
}) => {
  const styles = useStyles();
  return fields.map((option, index) => {
    const expanded = expandedOption === index;
    return (
      <Card key={index} className={styles.option}>
        <CardHeader
          className={styles.optionHeader}
          title={options[index].name || 'Geben Sie bitte einen Namen an'}
          subheader={`ab € ${min([
            ...options[index].prices,
            ...options[index].pricesWeekend
          ]) || '0'}`}
          action={
            <IconButton
              onClick={() => {
                setExpandedOption(!expanded ? index : null);
              }}
              aria-expanded={expanded}
            >
              {expanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          }
        />
        <Collapse in={expanded}>
          <CardContent className={styles.optionBody}>
            <RoomOptionForm
              name={option}
              {...{
                cancellationTerms,
                cleanupFees,
                fields,
                guarantees,
                vistorFees,
                occupancyMax
              }}
              onDeleteRoomOption={() =>
                showConfirmation('roomOption.delete', {
                  roomId: options[index].propertyRoomId,
                  roomOptionId: options[index].id,
                  index
                })}
              values={options[index]}
            />
          </CardContent>
        </Collapse>
      </Card>
    );
  });
};

RoomFormOptions.propTypes = {
  cancellationTerms: PropTypes.array.isRequired,
  cleanupFees: PropTypes.array.isRequired,
  expandedOption: PropTypes.number,
  fields: PropTypes.object.isRequired,
  guarantees: PropTypes.array.isRequired,
  occupancyMax: PropTypes.number,
  options: PropTypes.array.isRequired,
  setExpandedOption: PropTypes.func,
  showConfirmation: PropTypes.func.isRequired,
  vistorFees: PropTypes.array.isRequired
};

export default RoomFormOptions;
