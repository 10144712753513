import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { FormContainer } from 'rb-toolset/lib/ui/FormControls';
import { CheckCircle } from '@material-ui/icons';
import { connect } from 'react-redux';
import { ListItem, List } from '@material-ui/core';
import { Column } from 'rb-toolset/lib/ui/Layout';
import { pick } from 'lodash';

const PoliciesReservations = ({ guarantees, propertyId }) => {
  const { push } = useHistory();

  const handleClick = React.useCallback(id =>
    push(`/${propertyId}/housing/policies/reservation/edit/${id}`)
  );

  const handleNewGuaranty = React.useCallback(() =>
    push(`/${propertyId}/housing/policies/reservation/new`)
  );
  return (
    <FormContainer
      title="Buchungsgarantien"
      handleNew={() => handleNewGuaranty()}
      fullWidth
      hideFooter
    >
      <List>
        {guarantees.map((guaranty, index) => {
          return (
            <ListItem onClick={() => handleClick(guaranty.id)} key={index}>
              <Column width={1 / 2}>{guaranty.name}</Column>
              <Column width={1 / 2}>
                {guaranty.isDefault
                  ? <CheckCircle color="primary" />
                  : <span />}
              </Column>
            </ListItem>
          );
        })}
      </List>
    </FormContainer>
  );
};

PoliciesReservations.propTypes = {
  guarantees: PropTypes.array.isRequired,
  propertyId: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
  guarantees: state.property.guarantees,
  initialValues: {
    ...pick(state.property, ['termsText', 'reservationTermsText', 'termsLink'])
  }
});

export default connect(mapStateToProps)(PoliciesReservations);
