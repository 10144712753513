function addCancellationGroup(
  { id, name, description, isDefault },
  propertyId,
  api
) {
  return api().entity('policy-cancellation-group').post({
    id,
    name,
    description,
    isDefault,
    propertyId
  });
}

function updateCancellationGroup({ id, name, description, isDefault }, api) {
  return api()
    .entity('policy-cancellation-group')
    .patch({ id, name, description, isDefault });
}

export function deleteCancellationGroupAction({
  input: { id, propertyId },
  api
}) {
  return api().entity('policy-cancellation-group').delete({
    id: id,
    propertyId: propertyId
  });
}

export function updateCancellationGroupAction({
  input: { cancellationGroup, propertyId },
  api
}) {
  return cancellationGroup.id
    ? updateCancellationGroup(cancellationGroup, api)
    : addCancellationGroup(cancellationGroup, propertyId, api).then(result => ({
        cancellationGroupId: result.id
      }));
}
