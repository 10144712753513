import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, getFormValues, FormSection } from 'redux-form';
import {
  AutoCompleteInput,
  MultiList,
  SelectInput,
  TextInput,
  ToggleInput
} from 'rb-toolset/lib/ui/FormControls';
import {
  FormContainer,
  FormHeader,
  TimePickerInput
} from 'rb-toolset/lib/ui/FormControls';
import { mapValues, mapKeys } from 'lodash';
import { connect } from 'react-redux';
import { Unit, Column } from 'rb-toolset/lib/ui/Layout';
import { Const } from 'rb-domain';
import moment from 'moment';
import { pick, isInteger } from 'lodash';

import WeekendSelector from 'components/FormControls/WeekendSelector/WeekendSelector';

import { CHAINS, OPTIONS, THEMES } from './config';

const getFormattedDate = value =>
  value
    ? moment(`${moment().format(Const.DAY_PATTERN)} ${value}`).toDate()
    : null;

const parseTime = value =>
  value ? moment(value).format(Const.TIME_PATTERN) : null;

const parseAmount = value => {
  return value ? parseFloat(value) : null;
};

const validateAmount = value => {
  return value ? (!isInteger(value) ? 'Only integers' : null) : null;
};

let HousingForm = ({
  accommodations,
  formValues,
  handleSubmit,
  providers,
  tags
}) => {
  const aTypes = accommodations.map(type => {
    return {
      value: type.id,
      label: type.name
    };
  });

  const pTypes = providers.map(type => {
    return {
      value: type.id,
      label: type.name
    };
  });

  // const typeOptions = [
  //   { value: 'ACCOMMONDATION', label: 'Travelworld Classic' },
  //   { value: 'ELITE_VILLA', label: 'EliteClub Clubhouse' },
  //   { value: 'ELITE_YACHT', label: 'EliteClub Yacht' },
  //   { value: 'ELITE_JET', label: 'EliteClub Aviation' }
  // ];

  return (
    <FormContainer title="Information über das Hotel" {...{ handleSubmit }}>
      <Field component={TextInput} label="Hotelname" name="name" fullWidth />
      <br />
      {/* <Field */}
      {/* label="Kettenhotel" */}
      {/* name="isChain" */}
      {/* component={CheckboxInput} */}
      {/* /> */}
      {formValues.isChain &&
        <span>
          <Field
            component={SelectInput}
            options={CHAINS}
            name="chain"
            label="Name der Kette"
          />
          <br />
        </span>}
      <Field
        component={SelectInput}
        options={OPTIONS}
        name="stars"
        label="Anzahl der Sterne"
        autoWidth
      />
      <br />
      <FormHeader>
        Elite Section (everything required for elite properties)
      </FormHeader>
      <Unit>
        <Field
          component={ToggleInput}
          label="EliteClub Accomodation"
          name="elite"
        >
          EliteClub Accomodation
        </Field>
      </Unit>
      <Unit>
        <Column width={1 / 2}>
          <Field
            component={TextInput}
            label="Roomcount"
            type="number"
            parse={parseAmount}
            validate={validateAmount}
            name="roomsCount"
          />
        </Column>
        <Column width={1 / 2}>
          <Field component={TextInput} label="Location" name="elite_location" />
        </Column>
      </Unit>
      <Unit>
        <Column width={1 / 2}>
          <Field
            component={TextInput}
            label="Maximum of Persons"
            type="number"
            parse={parseAmount}
            validate={validateAmount}
            name="elite_maxPersons"
          />
        </Column>
        <Column width={1 / 2}>
          <Field
            component={TextInput}
            label="CountryId"
            type="number"
            parse={parseAmount}
            validate={validateAmount}
            name="countryId"
          />
        </Column>
      </Unit>
      <br />
      <FormHeader>
        Folgende Tage gelten als Wochenend-Nächtigung:
      </FormHeader>
      <Unit>
        <Column width={1 / 2} overflow="visible">
          <FormSection name="weekendDefinition">
            <WeekendSelector />
          </FormSection>
        </Column>
      </Unit>
      <br />
      <Field
        component={TextInput}
        fullWidth
        label="Email für Reservierung"
        name="email"
      />
      <br />
      <Unit>
        <Column width={1 / 2}>
          <Field
            component={TimePickerInput}
            name="checkinTime"
            format={getFormattedDate}
            parseValue={parseTime}
            placeholder="15:00"
            label="Checkin"
            fullWidth
          />
        </Column>
        <Column width={1 / 2}>
          <Field
            component={TimePickerInput}
            name="checkoutTime"
            format={getFormattedDate}
            parseValue={parseTime}
            placeholder="11:00"
            label="Checkout"
            fullWidth
          />
        </Column>
      </Unit>
      <br />
      <Field
        component={TextInput}
        name="telephone"
        placeholder="+43 123456"
        label="Telefonnummer"
        fullWidth
      />
      <br />
      <Field
        component={TextInput}
        name="fax"
        placeholder="+43 123456"
        label="Fax"
        fullWidth
      />
      <br />
      <Field
        component={TextInput}
        name="websiteUrl"
        placeholder="http://www.example.com"
        label="Webseite"
        type="url"
        fullWidth
      />
      <br />
      <Field
        component={TextInput}
        name="facebookUrl"
        placeholder="http://www.facebook.com/example"
        label="Facebook"
        type="url"
        fullWidth
      />
      <br />
      <Field
        component={AutoCompleteInput}
        options={tags}
        name="tags_de"
        multiSelection
        fullWidth
        label="Stichwörter"
      />
      <br /><br />
      <Field
        component={ToggleInput}
        label="Gäste sollen uns auch per Anfrageformular kontaktieren können, falls keine Direktbuchung möglich ist"
        name="negotiationFallback"
      >
        Gäste sollen uns auch per Anfrageformular kontaktieren können, falls
        keine Direktbuchung möglich ist
      </Field>
      <br />
      {/* <Field
        component={SelectInput}
        fullWidth
        options={typeOptions}
        label="General Asset Type"
        name="type"
        disabled
        style={{ width: 200 }}
      />
      <br /> */}
      <Field
        component={SelectInput}
        options={aTypes}
        label="Primärer Hoteltyp"
        name="primaryAccommodationType"
        style={{ width: 200 }}
      />
      <Field
        component={MultiList}
        items={aTypes}
        heading="Hoteltyp"
        name="accommodationTypes"
      />
      <Field
        component={MultiList}
        items={pTypes}
        heading="Buchungsplattformen"
        name="providers"
      />
      <FormHeader>Customize</FormHeader>
      <Field component={TextInput} label="Logo Url" name="logoUrl" fullWidth />
      <Field
        component={SelectInput}
        options={THEMES}
        name="theme"
        label="Hotel Theme"
        autoWidth
      />
      <div style={{ marginBottom: '32px' }} />
    </FormContainer>
  );
};

HousingForm.propTypes = {
  formValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  accommodations: PropTypes.array.isRequired,
  providers: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  invalid: PropTypes.bool.isRequired
};

HousingForm = connect(state => ({
  formValues: getFormValues('Housing')(state) || {}
}))(HousingForm);

HousingForm = reduxForm({
  form: 'Housing',
  validate: (values = {}) => {
    const errors = {};

    if (!values.name) {
      errors.name = 'Bitte geben Sie einen Hotelnamen ein.';
    }
    if (!values.email) {
      errors.email = 'Bitte geben Sie eine E-Mail Adresse an.';
    }

    if (!values.telephone) {
      errors.telephone = 'Bitte geben Sie eine Telefonnummer an.';
    }
    return errors;
  }
})(HousingForm);

export default connect(state => ({
  accommodations: state.ui.accommodationTypes,
  providers: state.ui.providers,
  tags: state.ui.tags,
  enableReinitialize: true,
  initialValues: {
    ...pick(state.property, [
      'name',
      'stars',
      'elite',
      'countryId',
      'elite_maxPersons',
      'elite_location',
      'weekendDefinition',
      'roomsCount',
      'email',
      'checkinTime',
      'checkoutTime',
      'telephone',
      'fax',
      'websiteUrl',
      'facebookUrl',
      'tags_de',
      'negotiationFallback',
      'logoUrl',
      'theme',
      'primaryAccommodationType',
      'type'
    ]),
    accommodationTypes: mapValues(
      mapKeys(state.property.accommodationTypes, 'id'),
      () => true
    ),
    providers: mapValues(mapKeys(state.property.providers, 'id'), () => true)
  }
}))(HousingForm);
