import React from 'react';
import { BackLink } from 'rb-toolset/lib/ui/FormControls';
import { connect } from 'react-redux';
import {
  deleteFee as deleteFeeSignal,
  updateFee as updateFeeSignal
} from 'signals/property-fee';
import PaperContainer from 'components/PaperContainer/PaperContainer';
import HelpboxContainer from 'components/HelpboxContainer/HelpboxContainer';
import { showConfirmation as showConfirmationAction } from 'actions/ui/ui';
import PropTypes from 'prop-types';
import ConfirmationDialog from 'rb-toolset/lib/ui/ConfirmationDialog';

import TaxForm from './TaxForm/TaxForm';

const Tax = ({ deleteFee, propertyId, showConfirmation, tax, updateFee }) => {
  const onSubmit = React.useCallback(formValues => {
    updateFee({
      fee: {
        ...formValues,
        id: tax.id,
        propertyId
      },
      propertyId
    });
  });

  const onDelete = React.useCallback(() =>
    deleteFee({ id: tax.id, propertyId })
  );

  const onDeleteFee = React.useCallback(() => showConfirmation('tax.delete'));

  return (
    <PaperContainer
      helpBox={[
        <HelpboxContainer headline="Endreinigung & Besteuerungsgruppen">
          <p>
            <strong>Beispiele</strong>
          </p>
          <ul>
            <li>Kurtaxe, City Taxe, etc.</li>
            <li>Endreinigung</li>
            <li>Servicegebühr</li>
          </ul>
        </HelpboxContainer>
      ]}
    >
      <div>
        <BackLink to={`/${propertyId}/housing/policies`}>
          « zurück zur AGBs/Stornierung
        </BackLink>
        <TaxForm {...{ onDeleteFee, onSubmit, tax }} initialValues={tax} />
        <ConfirmationDialog
          id="tax.delete"
          confirmText="Ja"
          cancelText="Nein"
          title="Buchungsgarantie löschen"
          onConfirm={onDelete}
        >
          Wollen Sie diese Buchungsgarantie wirklich löschen?
        </ConfirmationDialog>
      </div>
    </PaperContainer>
  );
};

Tax.propTypes = {
  deleteFee: PropTypes.func.isRequired,
  propertyId: PropTypes.number.isRequired,
  showConfirmation: PropTypes.func.isRequired,
  tax: PropTypes.object,
  updateFee: PropTypes.func.isRequired
};

const mapStateToProps = (state, options) => ({
  isUploading: state.ui.isUploading,
  propertyId: parseInt(state.property.id, 10),
  tax: options.match.params.id
    ? state.property.fees.filter(
        item => item.id === parseInt(options.match.params.id, 10)
      )[0]
    : {}
});

const mapDispatchToProps = {
  deleteFee: deleteFeeSignal,
  updateFee: updateFeeSignal,
  showConfirmation: showConfirmationAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Tax);
