import React from 'react';
import PropTypes from 'prop-types';
import { FormContainer } from 'rb-toolset/lib/ui/FormControls';
import Remove from '@material-ui/icons/Remove';
import Add from '@material-ui/icons/Add';
import { TextInput } from 'rb-toolset/lib/ui/FormControls';
import { Field, getFormValues } from 'redux-form';
import { connect } from 'react-redux';

import { reduxForm } from 'redux-form';
import IconButton from '@material-ui/core/IconButton';

const selector = getFormValues('AvailabilityCtn');

let AvailabilityForm = ({
  change,
  formValues: { availableCount } = {},
  handleSubmit
}) => {
  const changeAvailability = React.useCallback(offset => {
    const newValue = parseInt(availableCount || 0, 10) + offset;
    change('availableCount', newValue > -1 ? newValue : 0);
  });

  return (
    <div>
      <FormContainer title="Zimmerverfügbarkeit ändern" {...{ handleSubmit }}>
        <div>
          <div>
            <IconButton onClick={() => changeAvailability(-1)}>
              <Remove />
            </IconButton>
            <Field
              component={TextInput}
              name="availableCount"
              inputstyle={{ textAlign: 'center' }}
              style={{ width: 50 }}
            />
            <IconButton onClick={() => changeAvailability(1)}>
              <Add />
            </IconButton>
          </div>
        </div>
      </FormContainer>
    </div>
  );
};

AvailabilityForm.propTypes = {
  room: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  formValues: PropTypes.object
};

AvailabilityForm = connect(state => ({
  formValues: selector(state)
}))(AvailabilityForm);

export default reduxForm({
  form: 'AvailabilityCtn'
})(AvailabilityForm);
