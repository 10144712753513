import React from 'react';
import { Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Unit, Column } from 'rb-toolset/lib/ui/Layout';
import { isArray } from 'lodash';

import PaperContainerHelpBox from './PaperContainerHelpBox';

import useStyles from './PaperContainerStyles';

const PaperContainer = ({ center = false, children, depth, helpBox }) => {
  const childrenArray = isArray(children) ? children : [children];
  const styles = useStyles();

  return (
    <Unit style={{ justifyContent: center ? 'center' : 'flex-start' }}>
      <Column width={2 / 3} maxWidth={800} overflow="show">
        {childrenArray.map((child, index) =>
          <Paper key={index} className={styles.content} elevation={depth}>
            {child}
          </Paper>
        )}
      </Column>
      <PaperContainerHelpBox {...{ depth, helpBox }} />
    </Unit>
  );
};

PaperContainer.propTypes = {
  center: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  depth: PropTypes.number,
  helpBox: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

PaperContainer.defaultProps = {
  depth: 1
};

export default PaperContainer;
