import React from 'react';
import PropTypes from 'prop-types';
import { asyncConnect } from 'redux-connect';
import { connect } from 'react-redux';
import { getBookingById } from 'actions/booking/booking';
import { Booking } from 'rb-domain';

import BookingDetailNegotiation from './BookingDetailNegotiation';
import BookingDetailDirect from './BookingDetailDirect';

let BookingDetail = ({ booking, propertyId }) => {
  const userData = booking.bookingUserDataFinal;
  const backLink = `/${propertyId}/bookings`;

  return Booking.isDirect(booking)
    ? <BookingDetailDirect {...{ backLink, booking, userData }} />
    : <BookingDetailNegotiation {...{ backLink, booking, userData }} />;
};

BookingDetail.propTypes = {
  booking: PropTypes.object.isRequired,
  propertyId: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
  booking: state.booking.bookingDetail,
  propertyId: state.property.id
});

BookingDetail = connect(mapStateToProps)(BookingDetail);

export default asyncConnect([
  {
    key: 'test',
    promise: options => {
      const { store: { dispatch } } = options;

      const propertyId = parseInt(options.match.params.id, 10);

      const promises = [];
      promises.push(dispatch(getBookingById(propertyId)));
      return Promise.all(promises);
    }
  }
])(BookingDetail);
