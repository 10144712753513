import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Drawer, List } from '@material-ui/core';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { NavigationItem } from 'rb-toolset/lib/ui/Navigation';
import { ChannelManager } from 'rb-domain';

import Colors from 'consts/Colors';

import useStyles from './NavigationStyles';
import menuConfig from './config';

const { HOTELPARTNER, SEEKDA, INTERALP } = ChannelManager.CODES;

const eliteClubTypes = ['ELITE_YACHT', 'ELITE_VILLA', 'ELITE_JET'];

const Navigation = ({ property, externalCM }) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const styles = useStyles();
  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: styles.paper
      }}
    >
      <AppBar className={styles.appBar} position="relative">
        Menü
      </AppBar>
      {pathname &&
        push &&
        <List
          disablePadding
          classes={{
            root: styles.listRoot
          }}
        >
          {property &&
            property.id &&
            menuConfig(
              externalCM,
              property.id,
              eliteClubTypes.includes(property.type)
            ).map((item, key) =>
              <NavigationItem
                selectedBackground={Colors.roombonus}
                selectedColor={Colors.white}
                subSelectedBackground={Colors.grey2}
                subListBackground={Colors.grey}
                rootColor={Colors.dark}
                {...{ key, pathname, push, ...item }}
              />
            )}
        </List>}
    </Drawer>
  );
};

Navigation.propTypes = {
  externalCM: PropTypes.bool.isRequired,
  property: PropTypes.object
};

const mapStateToProps = state => ({
  externalCM:
    [HOTELPARTNER, SEEKDA, INTERALP].indexOf(
      state.property.channelManagerCode
    ) > -1,
  property: state.property
});

export default connect(mapStateToProps)(Navigation);
