import React from 'react';
import {
  AccountCircle,
  AddShoppingCart,
  // CardGiftcard,
  ChildFriendly,
  CompareArrows,
  Description,
  EuroSymbol,
  EventAvailable,
  ExitToApp,
  Group,
  Home,
  Hotel,
  List,
  LocationOn,
  Loyalty,
  Photo,
  RoomService,
  Settings,
  Store,
  Toc,
  VerifiedUser,
  EventNote
} from '@material-ui/icons';

export default (externalCM, propertyId, ownAsset) => {
  const part1 = [
    {
      icon: <Home />,
      to: `/${propertyId}/dashboard`,
      text: 'Home'
    },
    {
      icon: <Loyalty />,
      to: `/${propertyId}/bookings`,
      text: 'Buchungen'
    },
    {
      icon: <Store />,
      to: `/${propertyId}/housing`,
      text: 'Unterkunft',
      items: [
        {
          to: `/${propertyId}/housing`,
          icon: <List />,
          text: 'Allgemeine Daten'
        },
        {
          to: `/${propertyId}/housing/location`,
          icon: <LocationOn />,
          text: 'Lage & Ort'
        },
        {
          to: `/${propertyId}/housing/amenities`,
          icon: <RoomService />,
          text: 'Ausstattung'
        },
        {
          to: `/${propertyId}/housing/description`,
          icon: <Description />,
          text: 'Beschreibung'
        },
        {
          to: `/${propertyId}/housing/photos`,
          icon: <Photo />,
          text: 'Fotos'
        },
        {
          to: `/${propertyId}/housing/rooms`,
          icon: <Hotel />,
          additionalProps: {
            disabled: externalCM
          },
          text: 'Zimmerkategorien'
        },
        {
          to: `/${propertyId}/housing/children`,
          icon: <ChildFriendly />,
          additionalProps: {
            disabled: externalCM
          },
          text: 'Altersgruppen Kinder'
        },
        {
          to: `/${propertyId}/housing/policies`,
          icon: <Toc />,
          additionalProps: {
            disabled: externalCM
          },
          text: 'AGBs / Stornierung'
        },
        {
          to: `/${propertyId}/housing/terms`,
          icon: <Toc />,
          additionalProps: {
            disabled: externalCM
          },
          text: 'Cancellationtext'
        }
      ]
    },
    {
      to: `/${propertyId}/bonuses`,
      icon: <AddShoppingCart />,
      text: 'Boni'
      // items: [
      //   {
      //     to: `/${propertyId}/bonuses`,
      //     icon: <CardGiftcard />,
      //     text: 'Boni verwalten'
      //   },
      //   {
      //     to: `/${propertyId}/bonuses/settings`,
      //     icon: <Settings />,
      //     text: 'Einstellungen'
      //   },
      // ]
    },
    {
      to: `/${propertyId}/availability`,
      icon: <EventAvailable />,
      additionalProps: {
        disabled: externalCM
      },
      text: 'Verfügbarkeit'
    }
  ];
  const part2 = [
    {
      to: `/${propertyId}/channel-manager`,
      icon: <CompareArrows />,
      text: 'Channel Manager'
    },
    {
      icon: <Settings />,
      to: `/${propertyId}/profile`,
      text: 'Profil',
      items: [
        {
          to: `/${propertyId}/profile/contact`,
          icon: <Group />,
          text: 'Kontakt'
        },
        {
          to: `/${propertyId}/profile`,
          icon: <AccountCircle />,
          text: 'Konto'
        }
      ]
    },
    {
      to: `/${propertyId}/partnership`,
      icon: <VerifiedUser />,
      text: 'Partnermodell'
    },
    {
      to: `/${propertyId}/payment`,
      icon: <EuroSymbol />,
      additionalProps: {
        divider: true
      },
      text: 'Bezahlung'
    },
    {
      to: '/logout',
      icon: <ExitToApp />,
      text: 'Abmelden'
    }
  ];
  if (ownAsset) {
    part1.push({
      to: `/${propertyId}/guestsDetails`,
      icon: <EventNote />,
      additionalProps: {
        disabled: externalCM
      },
      text: 'Guests details'
    });
  }

  return part1.concat(...part2);
};
