import * as actions from '../actionTypes';
import api from 'utils/api';
// import {toggleNotification} from 'actions/ui/ui';

export function getAllProviderTypes() {
  return (dispatch, getState, { api }) => {
    return api().entity('provider/list').get().then(result => {
      return dispatch({
        type: actions.UI_DATA_PROVIDER_TYPES,
        payload: result
      });
    });
  };
}

function addProvider(propertyId, providerId, api) {
  return api().entity('property-provider').post({ propertyId, providerId });
}

export function deleteProvider(propertyId, providerId, api) {
  return api().entity('property-provider').delete({ propertyId, providerId });
}

export function toggleProvidersAction({
  input: { propertyId, providerIds = {} },
  api
}) {
  return Promise.all(
    Object.keys(providerIds).map(id => {
      if (providerIds[id]) {
        return addProvider(propertyId, id, api).then(result => ({
          added: {
            id: result.providerId
          }
        }));
      }
      return deleteProvider(propertyId, id, api).then(() => ({
        deleted: id
      }));
    })
  ).then(result => ({
    providerType: result.reduce(
      (acc, item) => {
        if (item.added) {
          acc.added.push(item.added);
        }

        if (item.deleted) {
          acc.deleted.push(item.deleted);
        }

        return acc;
      },
      {
        added: [],
        deleted: []
      }
    )
  }));
}

export function toggleProvidersRedux({ input: { providerType }, dispatch }) {
  providerType.added.forEach(type => {
    dispatch({
      type: actions.API_PROPERTY_PROVIDER_ADDED,
      payload: type
    });
  });

  providerType.deleted.forEach(id => {
    dispatch({
      type: actions.API_PROPERTY_PROVIDER_DELETED,
      payload: parseInt(id, 10)
    });
  });
}
