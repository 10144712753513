import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { FlashOn } from '@material-ui/icons';
import yellow from '@material-ui/core/colors/yellow';
import { asyncConnect } from 'redux-connect';

import {
  getBookingsForProperty,
  setBookingSearchParams
} from 'actions/booking/booking';

import HelpboxContainer from 'components/HelpboxContainer/HelpboxContainer';
import PaperContainer from 'components/PaperContainer/PaperContainer';

import { decodeQueryParams, encodeQueryParams } from 'utils/url';

import BookingList from './BookingList/BookingList';

let Bookings = ({
  bookings,
  location,
  propertyId,
  searchParams,
  totalBookings
}) => {
  const { push } = useHistory();

  const onFilter = React.useCallback(params => {
    push({
      pathname: location.pathname,
      search: encodeQueryParams({
        ...location.query,
        ...params
      })
    });
  });

  const onSelect = React.useCallback(booking => {
    push(`/${propertyId}/bookings/${booking.id}`);
  });

  return (
    <PaperContainer
      helpBox={
        <HelpboxContainer headline="Buchungsübersicht">
          Buchungen mit einem{' '}
          <span style={{ color: yellow.A700 }}>
            <FlashOn />
          </span>{' '}
          sind Direktbuchungen.
        </HelpboxContainer>
      }
    >
      <BookingList
        {...{
          bookings,
          onFilter,
          onSelect,
          propertyId,
          searchParams,
          totalBookings
        }}
      />
    </PaperContainer>
  );
};

Bookings.propTypes = {
  bookings: PropTypes.array.isRequired,
  searchParams: PropTypes.object,
  location: PropTypes.object.isRequired,
  propertyId: PropTypes.number.isRequired,
  totalBookings: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
  bookings: state.booking.bookings,
  totalBookings: state.booking.total,
  searchParams: state.booking.searchParams
});

const mapDispatchToProps = {
  getBookingsForProperty
};

Bookings = connect(mapStateToProps, mapDispatchToProps)(Bookings);

export default asyncConnect([
  {
    key: 'test',
    promise: options => {
      const { store: { dispatch, getState } } = options;
      const propertyId = parseInt(options.match.params.propertyId, 10);

      dispatch(
        setBookingSearchParams(decodeQueryParams(options.location.search))
      );
      const { booking: { searchParams } } = getState();

      return dispatch(getBookingsForProperty(propertyId, searchParams));
    }
  },
  {
    key: 'propertyId',
    promise: options => {
      return Promise.resolve(parseInt(options.match.params.propertyId, 10));
    }
  }
])(Bookings);
