import { dispatch, dispatchInput } from 'rb-toolset/lib/signal/factories';
import { signal } from 'rb-toolset/lib/signal';

import * as actions from '../actions/actionTypes';

import {
  loginAction,
  saveTokenToCookie,
  loginSuccessAction,
  loadUsersToImpersonateAction,
  impersonateAsAction,
  refreshTokenAction,
  tokenRefresherAction,
  reAuthenticateAction,
  logout
} from '../actions/auth/auth';
import {
  getPropertiesAction,
  checkHotelAssignment
} from '../actions/property/property';

const loadUsersToImpersonate = [
  loadUsersToImpersonateAction,
  dispatchInput(actions.UI_LOGIN_IMPERSONATE, 'users')
];

const loginSuccess = [
  saveTokenToCookie,
  loginSuccessAction,
  {
    admin: loadUsersToImpersonate,
    propertyManager: [
      getPropertiesAction,
      {
        success: [
          dispatch(actions.UI_TOGGLE_MODAL_LOGIN, false),
          dispatch(actions.UI_LOGIN_PROPERTY_MANAGER_ERROR, false),
          dispatch(actions.UI_TOGGLE_LOGOUT_WARNING, false),
          dispatchInput(actions.UI_LOGIN_SUCCESS, 'user'),
          dispatchInput(actions.API_PROPERTIES_LOADED, 'properties'),
          checkHotelAssignment
        ],
        error: [
          dispatch(actions.UI_LOGIN_PROPERTY_MANAGER_ERROR, true),
          dispatch(actions.UI_LOGIN_IMPERSONATE, null),
          logout
        ]
      }
    ]
  }
];

export const login = signal([
  dispatch(actions.UI_LOGGING_IN),
  loginAction,
  {
    success: loginSuccess,
    error: [dispatch(actions.UI_LOGIN_ERROR)]
  }
]);

export const impersonate = signal([
  impersonateAsAction,
  {
    success: loginSuccess
  }
]);

export const loadUsers = signal([...loadUsersToImpersonate]);

export const tokenRefresher = signal([
  refreshTokenAction,
  {
    success: [saveTokenToCookie, tokenRefresherAction],
    error: []
  }
]);

export const reAuthenticate = signal([
  reAuthenticateAction,
  {
    hasToken: [
      refreshTokenAction,
      {
        success: [...loginSuccess],
        error: [logout, dispatch(actions.UI_LOGOUT)]
      }
    ],
    noToken: [dispatch(actions.UI_LOGOUT)]
  }
]);

export const logout$ = signal([logout, dispatch(actions.UI_LOGOUT)]);
