import { pick } from 'lodash';
import { API_HOTEL_ADDED } from 'actions/actionTypes';

import * as actions from '../actionTypes';

export function getPropertyByIdAction({ input: { propertyId }, api }) {
  return api()
    .entity(`property`)
    .get({
      id: propertyId
    })
    .then(property => ({ property }));
}

export function getPropertyById(id) {
  return (dispatch, getState, { api }) => {
    return api().entity(`property?id=${id}`).get().then(result => {
      dispatch({
        type: actions.API_PROPERTY_LOADED,
        payload: result
      });
    });
  };
}

export function addProperty(formObject, callback) {
  // debugger;
  return (dispatch, getState, { api }) => {
    return api().entity(`property`).post(formObject).then(result => {
      dispatch({
        type: API_HOTEL_ADDED
      });
      return callback(result.id);
    });
  };
}

export function updateProperty(payload) {
  return {
    type: actions.API_PROPERTY_UPDATED,
    payload
  };
}

export function saveProperty({ input: { propertyId, data }, api }) {
  return api()
    .entity(`property?id=${propertyId}`)
    .patch(data)
    .then(property => ({ property }));
}

export function savePropertyAction({ input: { propertyId, data }, path, api }) {
  if (data && Object.keys(data).length > 0) {
    return api()
      .entity(`property?id=${propertyId}`)
      .patch(data)
      .then(result => path.inject({ property: result }));
  }
  return path.nothingToSave();
}

export function injectPropertyFieldsAction({
  input: { property, fieldsToInject },
  dispatch
}) {
  if (fieldsToInject) {
    dispatch(updateProperty(pick(property, fieldsToInject)));
  }
}

// export function injectPropertyAccomodationsAction({ input: {}})

export function getPropertiesAction({ path, api }) {
  return api()
    .entity(`property/list`)
    .get()
    .then(result => path.success({ properties: result }))
    .catch(path.error);
}

export function checkHotelAssignment({ dispatch, input: { properties } }) {
  dispatch({
    type: actions.UI_TOGGLE_NO_HOTELS_ASSIGNED,
    payload: properties.length === 0
  });
}

export function requestTrustedMembership({ propertyId, comment }) {
  return (dispatch, getState, { api }) => {
    return api()
      .entity(`property/requestTrustedMembership`)
      .post({
        propertyId,
        comment
      })
      .then(() => {
        dispatch(getPropertyById(propertyId));
      });
  };
}
