import PropTypes from 'prop-types';
import React from 'react';
import { IconButton, Button } from '@material-ui/core';
import { NavigateNext, NavigateBefore } from '@material-ui/icons';
import { NavbarPropTypes } from 'react-day-picker';
import moment from 'moment';

const CalendarHeader = ({
  currentDate,
  onPreviousClick,
  onNextClick,
  onTodayClick
}) => {
  const styles = require('./CalendarHeader.scss');
  return (
    <div className={styles.container}>
      <div className="DayPicker-Caption">
        {moment(currentDate).format('MMMM YYYY')}
      </div>
      <div className={styles.controls}>
        <IconButton onClick={() => onPreviousClick()}>
          <NavigateBefore />
        </IconButton>
        <Button onClick={onTodayClick}>
          Heute
        </Button>
        <IconButton onClick={() => onNextClick()}>
          <NavigateNext />
        </IconButton>
      </div>
    </div>
  );
};

CalendarHeader.propTypes = {
  ...NavbarPropTypes,
  onTodayClick: PropTypes.func.isRequired
};

export default CalendarHeader;
