import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent, Button } from '@material-ui/core';
import { Unit, Column } from 'rb-toolset/lib/ui/Layout';
import { BackLink } from 'rb-toolset/lib/ui/FormControls';
import PaperContainer from 'components/PaperContainer/PaperContainer';
import moment from 'moment';

const BookingDetailNegotiation = ({ backLink, booking, userData }) => {
  return (
    <div>
      <PaperContainer>
        <Card>
          <BackLink to={backLink}>
            &laquo; Zurück zur Buchungs-Übersicht
          </BackLink>
          <CardHeader
            title={`Buchung: ${booking.bookingNumber}`}
            subheader={
              <div>
                {`${userData.firstName} ${userData.lastName}`}
                <br />
                {userData.street &&
                  <div>
                    {userData.street}, {userData.zip} {userData.city},{' '}
                    {userData.country && userData.country.name}
                    <br />
                    Tel: {userData.telephone}
                    <br />
                  </div>}
              </div>
            }
          />
          <CardContent>
            <a
              href={`https://orders.roombonus.com/anfragen/${booking.hotelUrl}h`}
              target="_blank"
            >
              <Button
                color="primary"
                label="Buchung ändern"
                target="_blank"
                variant="contained"
              >
                Buchung änderns
              </Button>
            </a>
          </CardContent>
        </Card>
      </PaperContainer>
      {booking.bookingOffers.reverse().map(offer =>
        <PaperContainer key={offer.id}>
          <Card>
            <CardHeader
              title={offer.type === 'USER' ? 'Kundenanfrage' : 'Ihre Antwort'}
            />
            <CardContent>
              <Unit>
                <Column width={1 / 4}>Anfrage ID:</Column>
                <Column width={3 / 4}>{booking.bookingNumber}</Column>
              </Unit>
              <Unit>
                <Column width={1 / 4}>Anreisedatum:</Column>
                <Column width={3 / 4}>
                  {moment(offer.arrival).format('dddd, DD.MM.YYYY')}
                </Column>
              </Unit>
              <Unit>
                <Column width={1 / 4}>Abreisedatum:</Column>
                <Column width={3 / 4}>
                  {moment(offer.departure).format('dddd, DD.MM.YYYY')}
                </Column>
              </Unit>
              <Unit>
                <Column width={1 / 4}>Anzahl Personen:</Column>
                <Column width={3 / 4}>{`${offer.adults}`}</Column>
              </Unit>
              <Unit>
                <Column width={1 / 4}>Anzahl Zimmer:</Column>
                <Column width={3 / 4}>{`${offer.roomsCount}`}</Column>
              </Unit>
              {offer.rooms.length > 0 &&
                <Unit>
                  <Column width={1 / 4}>Zimmerkategorie:</Column>
                  <Column width={3 / 4}>
                    {offer.rooms.map(room => room.name).join(', ')}
                  </Column>
                </Unit>}
              {offer.bonuses.length > 0 &&
                <Unit>
                  <Column width={1 / 4}>Gewünschter Boni:</Column>
                  <Column width={3 / 4}>
                    {offer.bonuses.map((bonus, i) =>
                      <div key={i}>
                        <a
                          href={`https://www.roombonus.com/bonus/${bonus.code}`}
                          target="_blank"
                        >
                          {i + 1}. {bonus.title}
                        </a>
                      </div>
                    )}
                  </Column>
                </Unit>}
              {offer.price &&
                <Unit>
                  <Column width={1 / 4}>Gefundener Preis:</Column>
                  <Column width={3 / 4}>EUR {offer.price}</Column>
                </Unit>}
              <Unit>
                <Column width={1 / 4}>Gesehen auf:</Column>
                <Column width={3 / 4}>Booking.com</Column>
              </Unit>
              {offer.inclusions &&
                offer.inclusions.length > 0 &&
                <Unit>
                  <Column width={1 / 4}>Inkludiert:</Column>
                  <Column width={3 / 4}>
                    {offer.inclusions
                      .map(inclusion => inclusion.name)
                      .join(', ')}
                  </Column>
                </Unit>}
              {offer.comment &&
                <Unit>
                  <Column width={1 / 4}>Kommentar:</Column>
                  <Column width={3 / 4}>{offer.comment}</Column>
                </Unit>}
            </CardContent>
          </Card>
        </PaperContainer>
      )}
    </div>
  );
};

BookingDetailNegotiation.propTypes = {
  backLink: PropTypes.string.isRequired,
  booking: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired
};

export default BookingDetailNegotiation;
