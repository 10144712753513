import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Delete } from '@material-ui/icons';
import {
  SelectInput,
  LabelInput,
  TextInput
} from 'rb-toolset/lib/ui/FormControls';
import { IconButton } from '@material-ui/core';
import { Unit, Column } from 'rb-toolset/lib/ui/Layout';
import { Property } from 'rb-domain';

import { discountTypes, translateAgeGroup } from './ChildrenFormUtils';
import useStyles from './ChildrenFormStyles';

const ChildrenFormCategories = ({ categories, deleteCategory, fields }) => {
  const styles = useStyles();

  return fields.map((category, index) =>
    <Unit className={styles.category} key={index} align="center">
      <Column width={3 / 16}>
        <Field
          component={LabelInput}
          name={`${category}.childAgeGroupCode`}
          format={value => translateAgeGroup(value)}
          style={{ width: 150 }}
        />
      </Column>
      <Column width={3 / 16} align="left">
        <Field
          component={LabelInput}
          name={`${category}.ageFrom`}
        />&nbsp;-&nbsp;
        <Field
          component={LabelInput}
          name={`${category}.ageTo`}
          format={value => value - 0.1}
        />{' '}
        Jahre
      </Column>
      <Column width={4 / 16}>
        <Field
          component={SelectInput}
          options={discountTypes}
          name={`${category}.type`}
          style={{ width: 150 }}
        />
      </Column>
      <Column width={4 / 16}>
        <Unit align="center">
          <Field
            component={TextInput}
            name={`${category}.value`}
            style={{ flexGrow: 1 }}
          />
          {categories[index].type === Property.DISCOUNT_TYPE.FIXED ? '€' : '%'}
        </Unit>
      </Column>
      <Column width={2 / 16} align="center">
        <IconButton onClick={() => deleteCategory(index)}>
          <Delete />
        </IconButton>
      </Column>
    </Unit>
  );
};

ChildrenFormCategories.propTypes = {
  categories: PropTypes.array,
  deleteCategory: PropTypes.func,
  fields: PropTypes.object.isRequired
};

export default ChildrenFormCategories;
