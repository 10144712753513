import { createUseStyles } from 'react-jss';

export default createUseStyles({
  passwordRow: {
    display: 'flex'
  },
  container: {
    '& .MuiFormControl-root': {
      width: '50%'
    },
    '& input': {
      width: '100%'
    }
  }
});
