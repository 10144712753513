import { toArray } from 'lodash';
import { toggleNotification } from 'actions/ui/ui';
import * as actions from '../actionTypes';

export function getAllAmenityTypes() {
  return (dispatch, getState, { api }) => {
    return api().entity('amenity/list').get().then(result => {
      const items = result.items;
      const amenityTypes = {};

      items.forEach(({ id, name, amenityType }) => {
        if (!amenityTypes[amenityType.position]) {
          amenityTypes[amenityType.position] = {
            id: amenityType.id,
            name: amenityType.name,
            items: []
          };
        }

        amenityTypes[amenityType.position].items.push({
          id,
          name
        });
      });

      dispatch({
        type: actions.UI_DATA_AMENITY_TYPES,
        payload: toArray(amenityTypes)
      });
    });
  };
}

export function getAmenitiesForProperty(propertyId) {
  return (dispatch, getState, { api }) => {
    return api()
      .entity(`property-amenity/list?propertyId=${propertyId}`)
      .get()
      .then(result => {
        return dispatch({
          type: actions.API_PROPERTY_AMENITIES_LOADED,
          payload: result
        });
      });
  };
}

export function addAmenitiy(propertyId, amenityId) {
  return (dispatch, getState, { api }) => {
    return api()
      .entity('property-amenity')
      .post({ propertyId, amenityId })
      .then(result => {
        return dispatch({
          type: actions.API_PROPERTY_AMENITY_ADDED,
          payload: {
            id: result.amenityId
          }
        });
      });
  };
}

export function deleteAmenity(propertyId, amenityId) {
  return (dispatch, getState, { api }) => {
    return api()
      .entity('property-amenity')
      .delete({ propertyId, amenityId })
      .then(() => {
        return dispatch({
          type: actions.API_PROPERTY_AMENITY_DELETED,
          payload: amenityId
        });
      });
  };
}

export function toggleAmenities(propertyId, amenityIds) {
  return dispatch => {
    const promises = [];

    Object.keys(amenityIds).forEach(id => {
      if (amenityIds[id]) {
        promises.push(dispatch(addAmenitiy(propertyId, id)));
      } else {
        promises.push(dispatch(deleteAmenity(propertyId, id)));
      }
    });

    return Promise.all(promises).then(() =>
      dispatch(toggleNotification('Daten gespeichert'))
    );
  };
}
