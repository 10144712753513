import { createUseStyles } from 'react-jss';

export default createUseStyles({
  genderWrapper: {
    display: 'flex !important',
    flexDirection: 'row !important',
    paddingLeft: '12px'
  },
  firstRow: {
    display: 'flex !important',
    alignItems: 'flex-end !important'
  },
  container: {
    '& .MuiListSubheader-root:first-child': {
      marginTop: '0 !important'
    },
    '& .MuiFormControl-root': {
      width: '50%'
    },
    '& input': {
      width: '100%'
    }
  },
  formContainer: {
    paddingTop: '0 !important'
  }
});
