import {
  APP_PAYMENT_SET_XSRF_TOKEN,
  APP_PAYMENT_AUTHENTICATING,
  APP_PAYMENT_AUTHENTICATING_ERROR,
  APP_PAYMENT_AUTHENTICATING_INIT,
  APP_PAYMENT_AUTHENTICATING_SUCCESS
} from './types';

const initialState = {
  csrfToken: null,
  authenticating: false,
  authSuccess: false
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case APP_PAYMENT_AUTHENTICATING_INIT:
      return {
        ...state,
        error: null,
        authenticating: true,
        authSuccess: false
      };
    case APP_PAYMENT_SET_XSRF_TOKEN:
      return {
        ...state,
        csrfToken: action.payload
      };
    case APP_PAYMENT_AUTHENTICATING:
      return {
        ...state,
        authenticating: action.payload
      };
    case APP_PAYMENT_AUTHENTICATING_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case APP_PAYMENT_AUTHENTICATING_SUCCESS:
      return {
        ...state,
        authSuccess: action.payload
      };
    default:
      return state;
  }
};
