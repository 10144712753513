import { Booking } from 'rb-domain';

import * as actions from '../actionTypes';

export const getUpcomingBookings = (propertyId, { arrivalFrom, arrivalTo }) => {
  return (dispatch, getState, { api }) => {
    return api()
      .entity(`booking/search`)
      .post({
        propertyId,
        arrivalFrom,
        arrivalTo,
        statuses: [
          Booking.STATUSES.USER_ACCEPTED,
          Booking.STATUSES.CREATED_DIRECT
        ],
        limit: 100
      })
      .then(resp => {
        dispatch({
          type: actions.API_UPCOMING_BOOKINGS_LOADED,
          payload: resp.items
        });
      });
  };
};

export function getBookingsForProperty(propertyId, params) {
  return (dispatch, _, { api }) => {
    const { page, limit, orderBy } = params;
    const offset = (page - 1) * limit;
    return api()
      .entity(`booking/search`)
      .post({
        limit,
        offset,
        orderBy: [orderBy],
        propertyId
      })
      .then(result => {
        dispatch({
          type: actions.API_BOOKINGS_FOR_PROPERTY,
          payload: {
            ...result
          }
        });
      });
  };
}

export function getBookingById(id) {
  return (dispatch, getState, { api }) => {
    return api().entity(`booking/?id=${id}`).get().then(result => {
      dispatch({
        type: actions.API_BOOKINGS_FOR_PROPERTY_DETAIL,
        payload: result
      });
    });
  };
}

export function setBookingSearchParams(params) {
  return {
    type: actions.API_BOOKINGS_SET_SEARCH_PARAMS,
    payload: params
  };
}
