import { createUseStyles } from 'react-jss';

import Colors from 'consts/Colors';

export default createUseStyles({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '500px'
  },
  containerModal: {
    marginTop: '15px'
  },
  toolbar: {
    textAlign: 'right'
  },
  errorBox: {
    padding: '20px',
    fontSize: '1.4rem',
    color: Colors.red
  }
});
