import { createUseStyles } from 'react-jss';

export default createUseStyles({
  content: {
    margin: '30px 18px 6px 30px'
  },
  helpBox1: {
    margin: '30px 10px 2px 2px'
  },
  helpBox2: {
    margin: '30px 10px 2px 10px'
  },
  helpBoxPaper: {
    marginBottom: '30px'
  },
  oneColumn: {
    zIndex: 100,
    maxWidth: '300px'
  },
  twoColumns: {
    zIndex: 100,
    width: '600px'
  }
});
