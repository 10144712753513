import * as actions from '../actionTypes';
// import {toggleNotification} from 'actions/ui/ui';

export function getAllAccommodationTypes() {
  return (dispatch, getState, { api }) => {
    return api().entity('accommodationtype/list').get().then(result =>
      dispatch({
        type: actions.UI_DATA_ACCOMMODATION_TYPES,
        payload: result.items
      })
    );
  };
}

export function addAccommodationType(propertyId, accommodationTypeId, api) {
  return api()
    .entity('property-accommodationtype')
    .post({ propertyId, accommodationTypeId });
}

export function deleteAccommodationType(propertyId, accommodationTypeId, api) {
  return api()
    .entity('property-accommodationtype')
    .delete({ propertyId, accommodationTypeId });
}

export function toggleAccommodationTypesAction({
  input: { propertyId, accommodationTypeIds = {} },
  api
}) {
  return Promise.all(
    Object.keys(accommodationTypeIds).map(id => {
      if (accommodationTypeIds[id]) {
        return addAccommodationType(propertyId, id, api).then(result => ({
          added: {
            id: result.accommodationTypeId
          }
        }));
      }

      return deleteAccommodationType(propertyId, id, api).then(() => ({
        deleted: id
      }));
    })
  ).then(result => ({
    accommodationType: result.reduce(
      (acc, item) => {
        if (item.added) {
          acc.added.push(item.added);
        }

        if (item.deleted) {
          acc.deleted.push(item.deleted);
        }

        return acc;
      },
      {
        added: [],
        deleted: []
      }
    )
  }));
}

export function toggleAccommodationTypesRedux({
  input: { accommodationType },
  dispatch
}) {
  accommodationType.added.forEach(type => {
    dispatch({
      type: actions.API_PROPERTY_ACOMMODATION_ADDED,
      payload: type
    });
  });

  accommodationType.deleted.forEach(id => {
    dispatch({
      type: actions.API_PROPERTY_ACOMMODATION_DELETED,
      payload: parseInt(id, 10)
    });
  });
}
