/* eslint-disable indent */
import * as actions from '../../actions/actionTypes';

const initialState = {
  availableProperties: [],
  error: false,
  impersonateUsers: null,
  isLoggingIn: true,
  loggedIn: false,
  propertiesLoaded: false
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actions.UI_LOGGING_IN:
      return {
        ...state,
        error: false,
        isLoggingIn: true
      };
    case actions.UI_LOGIN_SUCCESS:
      return {
        ...state,
        error: false,
        impersonateUsers: null,
        isLoggingIn: false,
        loggedIn: true,
        user: action.payload
      };
    case actions.UI_LOGIN_IMPERSONATE:
      return {
        ...state,
        error: false,
        impersonateUsers: action.payload,
        isLoggingIn: false
      };
    case actions.UI_LOGIN_ERROR:
      return {
        ...state,
        error: true,
        isLoggingIn: false
      };
    case actions.UI_LOGIN_PROPERTY_MANAGER_ERROR:
      return {
        ...state,
        errorPropertyManager: action.payload
      };
    case actions.UI_LOGOUT:
      return {
        ...state,
        availableProperties: [],
        error: false,
        impersonateUsers: null,
        isLoggingIn: false,
        loggedIn: false,
        propertiesLoaded: false,
        selectedProperty: null,
        user: null
      };
    case actions.API_PROPERTIES_LOADED:
      return {
        ...state,
        availableProperties: action.payload,
        propertiesLoaded: true
      };
    default:
      return state;
  }
};
