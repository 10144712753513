/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import { flatten } from 'lodash';
import { Paper } from '@material-ui/core';
import { Unit, Column } from 'rb-toolset/lib/ui/Layout';

import useStyles from './PaperContainerStyles';

const PaperContainerHelpBox = ({ depth, helpBox }) => {
  const styles = useStyles();
  if (!helpBox) {
    return false;
  }

  return (
    <Column width={1 / 3} overflow="visible">
      {helpBox.column2
        ? <div className={styles.twoColumns}>
            <Unit>
              <Column width={1 / 2}>
                <div className={styles.helpBox1}>
                  {flatten([helpBox.column1]).map((help, index) =>
                    <Paper
                      className={styles.helpBoxPaper}
                      elevation={depth}
                      key={index}
                    >
                      {help}
                    </Paper>
                  )}
                </div>
              </Column>
              <Column width={1 / 2}>
                <div className={styles.helpBox2}>
                  {flatten([helpBox.column2]).map((help, index) =>
                    <Paper
                      className={styles.helpBoxPaper}
                      elevation={depth}
                      key={index}
                    >
                      {help}
                    </Paper>
                  )}
                </div>
              </Column>
            </Unit>
          </div>
        : <div className={styles.oneColumn}>
            <div className={styles.helpBox1}>
              {flatten([helpBox]).map((help, index) =>
                <Paper
                  className={styles.helpBoxPaper}
                  elevation={depth}
                  key={index}
                >
                  {help}
                </Paper>
              )}
            </div>
          </div>}
    </Column>
  );
};

PaperContainerHelpBox.propTypes = {
  depth: PropTypes.number,
  helpBox: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default PaperContainerHelpBox;
