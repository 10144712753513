import React from 'react';
import PropTypes from 'prop-types';
import { FormContainer } from 'rb-toolset/lib/ui/FormControls';
import { TextInput } from 'rb-toolset/lib/ui/FormControls';
import { reduxForm, Field } from 'redux-form';

import useStyles from './ProfileFormStyles';

const ProfileForm = ({ handleSubmit, invalid }) => {
  const styles = useStyles();
  return (
    <div>
      {/* <FormContainer title="Profil bearbeiten"> */}
      {/* <TextField label="Benutzername" {...name} /> */}
      {/* </FormContainer> */}
      <FormContainer
        title="Passwort ändern"
        handleSubmit={handleSubmit}
        invalid={invalid}
        classes={{
          container: styles.container
        }}
      >
        <Field
          component={TextInput}
          type="password"
          label="Aktuelles Passwort"
          name="password"
        />
        <div className={styles.passwordRow}>
          <Field
            component={TextInput}
            type="password"
            label="Neues Passwort"
            name="newPassword"
          />
          <Field
            component={TextInput}
            type="password"
            label="Neues Passwort wiederholen"
            name="newPassword2"
          />
        </div>
        <br /><br />
      </FormContainer>
    </div>
  );
};

ProfileForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired
};

export default reduxForm({
  form: 'Profile',
  validate(values) {
    const errors = {};

    if (values.password && values.newPassword !== values.newPassword2) {
      errors.newPassword2 = 'Die beiden Passwörter müssen übereinstimmen';
    }

    return errors;
  }
})(ProfileForm);
