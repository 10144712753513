import React from 'react';
import { FormContainer } from 'rb-toolset/lib/ui/FormControls';
import { Radio, FormControlLabel } from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import { RadioButtonGroupInput } from 'rb-toolset/lib/ui/FormControls';
import PropTypes from 'prop-types';
import { Property } from 'rb-domain';

import { connect } from 'react-redux';

let BonusSettingsForm = ({ handleSubmit }) => {
  const bonusMode = Property.BONUS_MODES;

  return (
    <FormContainer title="Bonus Modus" handleSubmit={handleSubmit}>
      <Field component={RadioButtonGroupInput} name="boniMode">
        <FormControlLabel
          value={bonusMode['10_PERCENT']}
          control={<Radio />}
          label="10 Prozent Modus"
        />
        <FormControlLabel
          value={bonusMode.DAY}
          control={<Radio />}
          label="Tag Modus"
        />
      </Field>
    </FormContainer>
  );
};

BonusSettingsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

BonusSettingsForm = reduxForm({
  form: 'BonusSettings'
})(BonusSettingsForm);

export default connect(state => ({
  initialValues: {
    boniMode: state.property.boniMode
  }
}))(BonusSettingsForm);
