import Api from 'rb-any-api';
import { RbError } from 'rb-domain';

export function getWepAppPath() {
  if (!process.env.RAZZLE_WEB) {
    console.log('Please provide an WEB path variable');
    throw new Error('No WEB path was specified!');
  }
  return process.env.RAZZLE_WEB;
}

export function getApiPath() {
  if (!process.env.RAZZLE_API) {
    console.log('Please provide an API variable');
    throw new Error('No API was specified!');
  }
  return process.env.RAZZLE_API;
}

export function getName() {
  if (!process.env.RAZZLE_NAME) {
    console.log('Please provide an NAME variable');
    throw new Error('No NAME was specified!');
  }
  return process.env.RAZZLE_NAME;
}

export const apiCreator = ({ cookies }) => () => {
  const headers = {
    'x-api-locale': 'en',
    'x-auth-token': cookies.get('x-auth-token')
  };

  const base = getApiPath();

  return new Api(base, RbError, getName())
    .map(result => (result === true ? { success: result } : result))
    .headers(headers);
};
