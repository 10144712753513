import { signal } from 'rb-toolset/lib/signal';
import { goTo, notify } from 'rb-toolset/lib/signal/factories';
import {
  updateCancellationAction,
  deleteCancellationAction
} from 'actions/property/property-cancellation';
import {
  updateCancellationGroupAction,
  deleteCancellationGroupAction
} from 'actions/property/property-cancellation-group';

export const updateCancellationGroup = signal([
  updateCancellationGroupAction,
  updateCancellationAction,
  notify('Stornierungsrichtlinie wurde gespeichert'),
  goTo(({ propertyId }) => `/${propertyId}/housing/policies`)
]);

export const deleteCancellationGroup = signal([
  deleteCancellationGroupAction,
  notify('Stornierungsrichtlinie wurde gelöscht'),
  goTo(({ propertyId }) => `/${propertyId}/housing/policies`)
]);

export const deleteCancellation = signal([deleteCancellationAction]);
