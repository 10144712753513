import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { changePassword } from 'actions/user/user';

import PaperContainer from 'components/PaperContainer/PaperContainer';

import ProfileForm from './ProfileForm/ProfileForm';

const Profile = ({ changePasswordAction }) => {
  const onSubmit = React.useCallback(formData => {
    changePasswordAction(formData.password, formData.newPassword);
  });

  return (
    <PaperContainer>
      <ProfileForm onSubmit={onSubmit} />
    </PaperContainer>
  );
};

Profile.propTypes = {
  changePasswordAction: PropTypes.func.isRequired
};

export default connect(null, { changePasswordAction: changePassword })(Profile);
