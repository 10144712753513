import React from 'react';
import PropTypes from 'prop-types';
import { asyncConnect } from 'redux-connect';
import { connect } from 'react-redux';
import { getAllBonuses } from 'actions/bonus/bonus';
import { addBonus$, removeBonus$ } from 'signals/bonus';

import PaperContainer from 'components/PaperContainer/PaperContainer';
import HelpboxContainer from 'components/HelpboxContainer/HelpboxContainer';

import BonusForm from './BonusForm/BonusForm';

let Bonuses = ({
  addBonus,
  bonuses,
  activeBonuses,
  propertyId,
  removeBonus
}) => {
  const onBonusClick = React.useCallback(
    (bonus, flag) => {
      return flag
        ? addBonus({ code: bonus.code, propertyId })
        : removeBonus({ code: bonus.code, propertyId });
    },
    [propertyId]
  );

  return (
    <PaperContainer
      helpBox={[
        <HelpboxContainer headline="Willkommen in der Bonuswelt von ROOMBONUS!">
          {`Unterschiedlichste Bonusleistungen können Sie zukünftig Ihren Gästen
          über unsere Website
          anbieten. Je interessanter der Hotelbonus und je größer die Auswahl
          an unterschiedlichen
          Zusatzleistungen, desto höher auch die Wahrscheinlichkeit, dass ein
          Gast Ihr Haus für eine
          Buchung auswählen wird.`}
        </HelpboxContainer>,
        <HelpboxContainer>
          {`Entweder Sie verwenden unsere große Auswahl an vordefinierten
          Zusatzleistungen oder seien
          Sie kreativ und erstellen Ihre individuellen & hotelspezifischen
          Boni!
          Dies ist ganz einfach: Klicken Sie das Häkchen bei dem
          entsprechenden vordefinierten Bonus
          an und klicken auf das Schraubenschlüssel-Symbol rechts. Prüfen bzw.
          ändern Sie Bonusname,
          Bonuswert, Bild (über das + Symbol, quadr. Format mindestens
          500x500), Kurzbeschreibung
          und Beschreibung.
          Sollte ein Bonus nicht aufgeführt und hotelspezifisch sein, so
          nehmen Sie einfach einen
          vom Typ nahe liegenden Bonus und ändern diesen entsprechend ab.
          Ein Minimum an drei Bonusleistungen je Unterkunft muss angeboten
          werden. Bitte beachten
          Sie, dass der Gast den ausgewählten Bonus im Rahmen seines
          Aufenthaltes erhält und
          verwenden Sie nur in absoluten Ausnahmefällen Restriktionen wie "auf
          Anfrage und nach
          Verfügbarkeit" (z.B. bei auslastungsabhängigen Boni wie Early Check
          In oder Late Check
          Out)`}
        </HelpboxContainer>
      ]}
    >
      <BonusForm
        {...{ onBonusClick }}
        bonuses={bonuses}
        activeBonuses={activeBonuses}
        propertyId={propertyId}
      />
    </PaperContainer>
  );
};

Bonuses.propTypes = {
  activeBonuses: PropTypes.array.isRequired,
  addBonus: PropTypes.func.isRequired,
  bonuses: PropTypes.array.isRequired,
  propertyId: PropTypes.number.isRequired,
  removeBonus: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  bonuses: state.bonus.bonuses,
  propertyId: parseInt(state.property.id, 10),
  activeBonuses: state.property.bonuses
});

const mapDispatchToProps = {
  addBonus: addBonus$,
  removeBonus: removeBonus$
};

Bonuses = connect(mapStateToProps, mapDispatchToProps)(Bonuses);

export default asyncConnect([
  {
    promise: options => {
      const { store: { dispatch } } = options;

      const promises = [];
      promises.push(dispatch(getAllBonuses()));
      return Promise.all(promises);
    }
  }
])(Bonuses);
