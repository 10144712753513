import * as actions from 'actions/actionTypes';

const initialState = {};

export default function locationReducer(state = initialState, action = {}) {
  switch (action.type) {
    case actions.API_LOCTION_COUNTRIES_LOADED:
      return {
        ...state,
        countries: action.payload
      };
    case actions.API_LOCTION_CITIES_LOADED:
      return {
        ...state,
        cities: action.payload
      };
    case actions.API_LOCTION_REGION_LOADED:
      return {
        ...state,
        regions: action.payload
      };
    case actions.API_LOCTION_SUBREGIONS_LOADED:
      return {
        ...state,
        subRegions: action.payload
      };
    case actions.API_LOCTION_TOURISTIC_REGIONS_LOADED:
      return {
        ...state,
        touristicRegions: action.payload
      };
    default:
      return state;
  }
}
