import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { pick } from 'lodash';
import { FormContainer, WYSIWYGEditor } from 'rb-toolset/lib/ui/FormControls';

let DescriptionForm = ({ handleSubmit, description }) =>
  <FormContainer title="Hotelbeschreibung" {...{ handleSubmit }}>
    <p><strong>Der Inseratstext der Unterkunft:</strong></p>
    <div dangerouslySetInnerHTML={{ __html: description }} />
    <br />
    <p><strong>Eigene Beschreibung:</strong></p>
    <Field
      component={WYSIWYGEditor}
      name="customizedHotelDescription"
      placeholder="Beschreiben Sie die besonderheiten Ihres Hotels"
    />
    <br />
  </FormContainer>;

DescriptionForm.propTypes = {
  description: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

DescriptionForm = reduxForm({
  form: 'Description'
})(DescriptionForm);

export default connect(state => ({
  initialValues: {
    ...pick(state.property, ['customizedHotelDescription'])
  },
  descValues: getFormValues('Description')(state) || {}
}))(DescriptionForm);
