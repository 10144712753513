import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationDialog from 'rb-toolset/lib/ui/ConfirmationDialog';

const CancellationGroupFormDialog = ({
  handleCancellationGroupDelete,
  handleCancellationDelete
}) =>
  <div>
    <ConfirmationDialog
      id="confirmation.group"
      title="Stornierungsrichtlinie löschen"
      confirmText="Ja"
      cancelText="Nein"
      onConfirm={handleCancellationGroupDelete}
    >
      Wollen Sie diese Stornierungsrichtlinie wirklich löschen?
    </ConfirmationDialog>
    <ConfirmationDialog
      id="confirmation.cancellation"
      title="Stornierungsbedigung löschen"
      confirmText="Ja"
      cancelText="Nein"
      onConfirm={handleCancellationDelete}
    >
      Wollen Sie diese Stornierungsbedingung wirklich löschen?
    </ConfirmationDialog>
  </div>;

CancellationGroupFormDialog.propTypes = {
  handleCancellationGroupDelete: PropTypes.func.isRequired,
  handleCancellationDelete: PropTypes.func.isRequired
};

export default CancellationGroupFormDialog;
