import { reducer as reduxAsyncConnect } from 'redux-connect';
import { reducer as form } from 'redux-form';
import auth from './auth';
import bonus from './bonus';
import ui from './ui';
import property from './property';
import rooms from './rooms';
import availability from './availability';
import booking from './booking';
import location from './location';
import childAges from './childAges';
import dashboard from './dashboard';
import { confirmation, notification, geo } from 'rb-toolset/lib/redux';
import payment from 'features/Payment/redux/reducer';
import { connectRouter } from 'connected-react-router'

export default function getReducers(history) {
  const router = connectRouter(history);
  return {
    auth,
    availability,
    childAges,
    form,
    bonus,
    booking,
    location,
    dashboard,
    reduxAsyncConnect,
    property,
    rooms,
    ui,
    confirmation: confirmation.reducer,
    notification: notification.reducer,
    geo: geo.reducer,
    payment,
    router: router
  }
}
