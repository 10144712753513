import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  savePropertyPhotoPosition as savePropertyPhotoPositionAction,
  uploadPropertyPhoto as uploadPropertyPhotoAction,
  deletePropertyPhoto as deletePropertyPhotoAction
} from 'actions/photos/photos';
import { getPropertyById as getPropertyByIdAction } from 'actions/property/property';

import PaperContainer from 'components/PaperContainer/PaperContainer';
import HelpboxContainer from 'components/HelpboxContainer/HelpboxContainer';
import { PhotosForm } from 'components/Forms';

const Photos = ({
  deletePropertyPhoto,
  getPropertyById,
  isUploading,
  photos,
  propertyId,
  savePropertyPhotoPosition,
  uploadPropertyPhoto
}) => {
  const onUpload = React.useCallback(file =>
    uploadPropertyPhoto(file, { propertyId }).then(() =>
      getPropertyById(propertyId)
    )
  );

  const onChange = React.useCallback(photo =>
    savePropertyPhotoPosition(photo.url, photo.index)
  );
  const onDelete = React.useCallback(photo =>
    deletePropertyPhoto(photo.url, propertyId)
  );

  return (
    <PaperContainer
      helpBox={
        <HelpboxContainer>
          Bilder sind Ihre digitalen Visitenkarten für Ihre zukünftigen Gäste.
          Bitte verwenden Sie nur
          aussagekräftige und vor allem aktuelle Bilder Ihrer Unterkunft,
          bestmöglich ab einer Auflösung von
          1024x768, vorzugsweise in Querformat. Es müssen mindestens vier Bilder
          hochgeladen werden um Ihr
          Haus ordnungsgemäß auf ROOMBONUS darzustellen. Bitte beachten Sie,
          dass Foto 1 immer das Titelfoto
          Ihres Hauses darstellt. Wählen Sie einfach mit dem+ Symbol das
          passende Bild von Ihrem Rechner aus.
          Via Drag & Drop können Sie ganz einfach die Anordnung der Bilder
          verändern. Sind Sie mit einem Ihrer
          Fotos nicht mehr zufrieden, löschen Sie dieses einfach mit dem
          Papierkorbsymbol. Ingesamt können
          max. 30 Bilder hochgeladen werden. Folgende Empfehlung hinsichtlich
          der Bildanordnung geben wir:
          Außenansicht, Zimmer, Öffentliche Bereiche/SPA/Wellness,
          Freizeitaktivitäten, Bar, Restaurant
        </HelpboxContainer>
      }
    >
      <PhotosForm
        title="Hotelfotos"
        onUpload={onUpload}
        onChange={onChange}
        onDelete={onDelete}
        isUploading={isUploading}
        photos={photos}
      />
    </PaperContainer>
  );
};

Photos.propTypes = {
  deletePropertyPhoto: PropTypes.func.isRequired,
  getPropertyById: PropTypes.func.isRequired,
  isUploading: PropTypes.bool.isRequired,
  photos: PropTypes.array.isRequired,
  propertyId: PropTypes.number,
  savePropertyPhotoPosition: PropTypes.func.isRequired,
  uploadPropertyPhoto: PropTypes.func.isRequired
};

const mapStateToProps = (state, params) => ({
  photos: state.property.photos,
  propertyId: parseInt(params.match.params.propertyId, 10),
  isUploading: state.ui.isUploading
});

const mapDispatchToProps = {
  deletePropertyPhoto: deletePropertyPhotoAction,
  getPropertyById: getPropertyByIdAction,
  savePropertyPhotoPosition: savePropertyPhotoPositionAction,
  uploadPropertyPhoto: uploadPropertyPhotoAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Photos);
