import React from 'react';
import PropTypes from 'prop-types';
import { DatePickerInput } from 'rb-toolset/lib/ui/FormControls';
import moment from 'moment';

const minDate = moment().toDate();

const DateRangeInput = ({ from, to }) => {
  const changeDate = React.useCallback((date, field) => {
    if (field.name === 'from') {
      if (date > to.input.value) {
        from.input.onChange(to.input.value || date);
        to.input.onChange(date);
      } else {
        from.input.onChange(date);
      }
    } else if (date < from.input.value) {
      from.input.onChange(date);
      to.input.onChange(from.input.value);
    } else {
      to.input.onChange(date);
    }
  });

  return (
    <div>
      <DatePickerInput
        label="Von"
        locale="de"
        format="DD/MM/YYYY"
        input={from.input}
        minDate={minDate}
        onChange={date => changeDate(date, from.input)}
        onDismiss={() => from.input.onChange(from.input.value)}
      />
      <DatePickerInput
        label="Bis"
        locale="de"
        format="DD/MM/YYYY"
        input={to.input}
        minDate={minDate}
        onChange={date => changeDate(date, to.input)}
        onDismiss={() => to.input.onChange(to.input.value)}
      />
    </div>
  );
};

DateRangeInput.propTypes = {
  from: PropTypes.object.isRequired,
  to: PropTypes.object.isRequired
};

export default DateRangeInput;
