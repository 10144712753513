import React from 'react';
import PropTypes from 'prop-types';
import { FormHeader } from 'rb-toolset/lib/ui/FormControls';
import { FieldArray } from 'redux-form';

import Colors from 'consts/Colors';

import { getFieldNames } from './CalculationsUtils';

import CalculationsDropdowns from './CalculationsDropdowns';

const buttonStyle = {
  color: Colors.roombonus,
  cursor: 'pointer',
  display: 'inline'
};

const Calculations = ({ array, formValues: { calculationBases }, name }) => {
  const { calculationAmountsName, calculationBasesName } = getFieldNames(name);

  const addToFieldArray = React.useCallback(() => {
    array.push(calculationBasesName);
    array.push(calculationAmountsName, {});
  });

  return (
    <div>
      <FormHeader>Höhe der Garantie</FormHeader>
      <FieldArray
        name={calculationBasesName}
        props={{ array, calculationBases, name }}
        component={CalculationsDropdowns}
      />

      {
        // disabled until we rebuild extranet
        /* <div
        style={buttonStyle}
        onClick={event => {
          event.preventDefault();
          addToFieldArray();
        }}
      >
        Regel hinzufügen
      </div> */
      }
      <br />
      <br />
    </div>
  );
};

Calculations.propTypes = {
  formValues: PropTypes.object,
  array: PropTypes.object.isRequired,
  name: PropTypes.string
};

export default Calculations;
