import { createUseStyles } from 'react-jss';

export default createUseStyles({
  container: {
    margin: '0 256px',
    boxShadow: '0 0 4px #d9d9d9'
  },

  menuButton: {
    marginTop: '6px !important',
    color: '#fff !important'
  },

  center: {
    textAlign: 'center'
  },
  content: {
    position: 'relative',
    zIndex: 2,
    marginTop: 20
  },

  navigation: {
    position: 'relative',
    zIndex: 1
  }
});
