import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Checkbox,
  IconButton
} from '@material-ui/core';
import { Build } from '@material-ui/icons';
import { FormContainer } from 'rb-toolset/lib/ui/FormControls';
import { SelectInput } from 'rb-toolset/lib/ui/FormControls';
import { Bonus } from 'rb-domain';

import useStyles from './BonusFormStyles';

const selector = formValueSelector('BonusFilter');

let BonusForm = ({
  bonuses,
  onBonusClick,
  activeBonuses,
  categoryId,
  propertyId
}) => {
  const { push } = useHistory();
  const styles = useStyles();

  const customizeBonus = React.useCallback(code => {
    push(`/${propertyId}/bonuses/${code}/customize`);
  });

  const activeBonusIds = activeBonuses.reduce((acc, activeBonus) => {
    return {
      ...acc,
      [activeBonus.code]: activeBonus
    };
  }, {});

  return (
    <FormContainer
      title="Boni verwalten"
      hideFooter
      fullWidth
      toolbar={
        <div className={styles.categorySelect}>
          <Field
            component={SelectInput}
            asMenu
            name="categoryId"
            options={Object.keys(Bonus.BONUS_CATEGORIES).map(key => ({
              label: Bonus.bonusCategoryToDE(Bonus.BONUS_CATEGORIES[key]),
              value: Bonus.BONUS_CATEGORIES[key]
            }))}
          />
        </div>
      }
    >
      <List>
        {bonuses
          .filter(bonus => categoryId === 0 || bonus.categoryId === categoryId)
          .map(bonus => {
            const bonusToRender = activeBonusIds[bonus.code] || bonus;
            return (
              <ListItem key={bonusToRender.id} button dense>
                <ListItemIcon>
                  <Checkbox
                    color="primary"
                    onChange={(event, isInputChecked) =>
                      onBonusClick(bonusToRender, isInputChecked)}
                    checked={!!activeBonusIds[bonus.code]}
                  />
                </ListItemIcon>
                <ListItemText>
                  <div
                    onClick={() =>
                      activeBonusIds[bonus.code]
                        ? customizeBonus(bonus.code)
                        : onBonusClick(bonusToRender, true)}
                  >
                    {bonusToRender.title}
                  </div>
                </ListItemText>
                <ListItemSecondaryAction>
                  {activeBonusIds[bonus.code] &&
                    <IconButton onClick={() => customizeBonus(bonus.code)}>
                      <Build />
                    </IconButton>}
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
      </List>
    </FormContainer>
  );
};

BonusForm.propTypes = {
  activeBonuses: PropTypes.array.isRequired,
  bonuses: PropTypes.array.isRequired,
  categoryId: PropTypes.number,
  onBonusClick: PropTypes.func.isRequired,
  propertyId: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
  categoryId: selector(state, 'categoryId')
});

BonusForm = connect(mapStateToProps)(BonusForm);

export default reduxForm({
  form: 'BonusFilter',
  initialValues: {
    categoryId: 0
  }
})(BonusForm);
