import { red, lightGreen } from '@material-ui/core/colors';

export default {
  day: {
    // position: 'absolute',
    top: 0,
    // left: 0,
    // right: 0,
    bottom: 0,
    // padding: '5px',
    height: '100%'
  },

  priceContainer: {
    position: 'absolute',
    left: '5px',
    bottom: '0',
    color: '#bbb',
    textAlign: 'left',
    lineHeight: '23px'
  },
  price: {
    fontSize: '18px'
  },
  availability: {
    position: 'absolute',
    bottom: '2px',
    right: '2px'
  },
  availabilityActive: {
    height: '30px',
    width: '25px',
    backgroundColor: lightGreen['500'],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff'
  },
  noAvailability: {
    backgroundColor: red['800']
  },
  blockedIcon: {
    position: 'absolute !important',
    top: 4,
    padding: '0 !important',
    left: 4,
    height: '20px !important',
    width: '20px !important'
  },
  minimumIcon: {
    position: 'absolute !important',
    top: 4,
    padding: '0 !important',
    left: 30,
    height: '20px !important',
    width: '20px !important'
  },
  pastDay: {
    backgroundColor: '#efefef',
    opacity: 0.8
  },
  priceIcon: {
    position: 'absolute',
    left: '20px',
    bottom: '25px'
  },
  legend: {
    fontSize: '13px'
  },
  legendElement: {
    height: '30px',
    display: 'flex',
    alignItems: 'center'
  }
};
