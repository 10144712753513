import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { BackLink } from 'rb-toolset/lib/ui/FormControls';
import PaperContainer from 'components/PaperContainer/PaperContainer';
import yellow from '@material-ui/core/colors/yellow';
import { Unit, Column } from 'rb-toolset/lib/ui/Layout';
import moment from 'moment';
import { FlashOn } from '@material-ui/icons';
import { User } from 'rb-domain';

const BookingDetailDirect = ({ backLink, booking, userData }) => {
  const directBooking = booking.bookingOffers.reverse()[0];
  const departureMoment = moment(directBooking.departure);
  const arrivalMoment = moment(directBooking.arrival);

  return (
    <PaperContainer>
      <Card>
        <BackLink to={backLink}>&laquo; Zurück zur Buchungs-Übersicht</BackLink>
        <CardHeader
          title={
            <span>
              Buchung: {booking.bookingNumber}
              <FlashOn style={{ color: yellow.A700 }} />
            </span>
          }
          subheader={
            <div>
              {`${userData.gender === 'MALE' ? 'Herr' : 'Frau'}${userData.title
                ? ` ${User.getTitleName(userData.title)}`
                : ''} ${userData.firstName} ${userData.lastName} - ${userData.email}`}
              <br />
              {userData.street &&
                <div>
                  {userData.street}, {userData.zip} {userData.city},{' '}
                  {userData.country && userData.country.name}
                  <br />
                  Tel: {userData.telephone}
                  <br />
                </div>}
            </div>
          }
        />
        <CardContent>
          <Unit>
            <Column width={1 / 4}>Anreisedatum:</Column>
            <Column width={3 / 4}>
              {arrivalMoment.format('dddd, DD.MM.YYYY')}
            </Column>
          </Unit>
          <Unit>
            <Column width={1 / 4}>Abreisedatum:</Column>
            <Column width={3 / 4}>
              {departureMoment.format('dddd, DD.MM.YYYY')}
            </Column>
          </Unit>
          <Unit>
            <Column width={1 / 4}>Reisedauer:</Column>
            <Column width={3 / 4}>
              {departureMoment.diff(arrivalMoment, 'days')} Nächte
            </Column>
          </Unit>
          <Unit>
            <Column width={1 / 4}>Gäste:</Column>
            <Column width={3 / 4}>
              {directBooking.adults} Erwachsene <br />
              {directBooking.children &&
                <span>
                  {directBooking.children} Kinder (Alter:{' '}
                  {directBooking.children.map(child => child.age).join(', ')}
                  Jahre)
                </span>}
            </Column>
          </Unit>
          <br />
          <Unit>
            <Column width={7 / 8}>
              <strong>
                Gesamtpreis für {directBooking.roomsText}:{' '}
                {directBooking.roomsOptionText}
                <br />
                (inkl. Ust / exkl. Kurtaxen / Beherbergungsabgabe)
              </strong>
            </Column>
            <Column width={1 / 8}>
              <strong>{`€ ${directBooking.price}`}</strong>
            </Column>
          </Unit>
          <br />
          {directBooking.bonuses.length > 0 &&
            <Unit>
              <Column width={1 / 4}>Gewünschte Boni:</Column>
              <Column width={3 / 4}>
                {directBooking.bonuses.map((bonus, i) =>
                  <div key={i}>
                    <a
                      href={`https://www.roombonus.com/bonus/${bonus.code}`}
                      target="_blank"
                    >
                      {i + 1}. {bonus.title}
                    </a>
                  </div>
                )}
              </Column>
            </Unit>}
          {directBooking.comment &&
            <Unit>
              <Column width={1 / 4}>Kommentar:</Column>
              <Column width={3 / 4}>{directBooking.comment}</Column>
            </Unit>}
        </CardContent>
      </Card>
    </PaperContainer>
  );
};

BookingDetailDirect.propTypes = {
  backLink: PropTypes.string.isRequired,
  booking: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired
};

export default BookingDetailDirect;
