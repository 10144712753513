import { createUseStyles } from 'react-jss';

export default createUseStyles({
  roomPrice: {
    marginRight: 10,
    width: '100px !important'
  },
  field: {
    display: 'flex',
    alignContent: 'center'
  },
  roomIcons: {
    marginRight: 10,
    paddingTop: 10,
    maxWidth: 100,
    width: 100
  }
});
