import React from 'react';
import PropTypes from 'prop-types';
import {
  reduxForm,
  FieldArray,
  formValueSelector,
  propTypes
} from 'redux-form';
import { connect } from 'react-redux';

import { FormContainer } from 'rb-toolset/lib/ui/FormControls';
import { Unit, Column } from 'rb-toolset/lib/ui/Layout';
import { Property } from 'rb-domain';

import { RangeSlider } from 'components/FormControls';

import ChildrenFormCategories from './ChildrenFormCategories';

import {
  CHILD_AGE,
  ORDERED_GROUP_TYPES,
  translateAgeGroup
} from './ChildrenFormUtils';
import useStyles from './ChildrenFormStyles';

const selector = formValueSelector('Children');

let ChildrenForm = ({
  array,
  change,
  categories = [],
  handleSubmit,
  invalid
}) => {
  const styles = useStyles();
  const categoriesLength = categories.length;
  const addCategory = React.useCallback(() => {
    if (categoriesLength === 0) {
      array.push('categories', {
        childAgeGroupCode: Property.CHILD_AGE_GROUPS.BABY,
        ageFrom: 0,
        ageTo: CHILD_AGE
      });
    } else {
      const currentValue = categories[categoriesLength - 1];

      change(`categories[${categoriesLength - 1}]`, {
        ...currentValue,
        ageTo: currentValue.ageTo - 2
      });

      array.push('categories', {
        childAgeGroupCode: ORDERED_GROUP_TYPES[categoriesLength],
        ageFrom: currentValue.ageTo - 1,
        ageTo: currentValue.ageTo
      });
    }
  });

  const deleteCategory = React.useCallback(index => {
    const deletedCategory = categories[index];

    if (categoriesLength > 1) {
      if (index === 0) {
        change(`categories[${index + 1}]`, {
          ...categories[index + 1],
          ageFrom: deletedCategory.ageFrom
        });
      } else {
        change(`categories[${index - 1}]`, {
          ...categories[index - 1],
          ageTo: deletedCategory.ageTo
        });
      }
    }

    // No group types should exist twice!
    let realPosition = 0;
    categories.forEach((category, position) => {
      if (position !== index) {
        change(
          `categories[${position}].childAgeGroupCode`,
          ORDERED_GROUP_TYPES[realPosition]
        );
        realPosition += 1;
      }
    });

    array.remove('categories', index);
  });

  const updateCategories = React.useCallback(ages =>
    categories.forEach((category, index) => {
      change(`categories[${index}]`, {
        ...category,
        ageFrom: ages[index],
        ageTo: ages[index + 1]
      });
    })
  );

  const ages = categories && categories.length > 0
    ? categories.reduce((acc, category, index) => {
        if (index === 0) {
          acc.push(category.ageFrom);
        }
        acc.push(category.ageTo);

        return acc;
      }, [])
    : [CHILD_AGE];

  const marks = categories && categories.length > 0
    ? ages.reduce(
        (acc, value, index) => ({
          ...acc,
          [value]: categories[index]
            ? translateAgeGroup(categories[index].childAgeGroupCode)
            : 'Erwachsener'
        }),
        {}
      )
    : {};

  return (
    <FormContainer
      title="Altersgruppen"
      {...{
        handleSubmit,
        invalid
      }}
      handleNewDisabled={categoriesLength > 2}
      handleNew={addCategory}
    >
      <div className={styles.sliderWrapper}>
        {categoriesLength > 0
          ? <RangeSlider
              min={0}
              max={18}
              {...{ marks }}
              input={{
                value: ages,
                onChange: updateCategories
              }}
            />
          : <strong>
              Fügen Sie Altersgruppen hinzu, um Preise zu konfigurieren
            </strong>}
      </div>
      <FieldArray
        component={ChildrenFormCategories}
        name="categories"
        {...{ categories, deleteCategory }}
      />
      <Unit style={{ height: 50 }} align="center">
        <Column width={3 / 16}>Erwachsener</Column>
        <Column width={3 / 16}>
          <span>{ages[ages.length - 1]} Jahre - ∞</span>&nbsp;
        </Column>
        <Column width={4 / 16} offset={4 / 16}>
          Normalpreis
        </Column>
      </Unit>
    </FormContainer>
  );
};

ChildrenForm.propTypes = {
  ...propTypes,
  handleSubmit: PropTypes.func.isRequired,
  categories: PropTypes.array
};

const mapStateToProps = state => ({
  categories: selector(state, 'categories')
});

ChildrenForm = connect(mapStateToProps)(ChildrenForm);

export default reduxForm({
  form: 'Children',
  validate: values => {
    const errors = {};

    if (!values.categories || values.categories.length === 0) {
      errors.categories = {
        ...errors.categories,
        _error: 'required'
      };
    } else {
      const categoriesErrors = [];

      values.categories.forEach((category, index) => {
        const categoryError = {};

        if (!category || !category.childAgeGroupCode) {
          categoryError.childAgeGroupCode = 'required';
        }

        if (!category || !category.type) {
          categoryError.type = 'required';
        }

        if (!category || !category.value) {
          categoryError.price = ' ';
        }

        if (Object.keys(categoryError).length > 0) {
          categoriesErrors[index] = categoryError;
        }
      });

      if (categoriesErrors.length > 0) {
        errors.categories = categoriesErrors;
      }
    }

    return errors;
  }
})(ChildrenForm);
