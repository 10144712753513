import * as actions from 'actions/actionTypes';

const initialState = {
  notification: {
    open: false,
    message: ''
  },
  modalLogin: false,
  isUploading: false,
  noHotelsAssigned: false,
  confirmation: {}
};

export default function uiReducer(state = initialState, action = {}) {
  switch (action.type) {
    case actions.UI_DATA_CHANNELMANAGER:
      return {
        ...state,
        channelManagers: action.payload
      };
    case actions.UI_TOGGLE_NOTIFICATION:
      return {
        ...state,
        notification: {
          message: action.payload.message || '',
          open: !!action.payload.message,
          error: action.payload.error
        }
      };
    case actions.UI_TOGGLE_UPLOADING_PROGRESS:
      return {
        ...state,
        isUploading: action.payload
      };
    case actions.UI_DATA_ACCOMMODATION_TYPES:
      return {
        ...state,
        accommodationTypes: action.payload
      };
    case actions.UI_DATA_TAGS:
      return {
        ...state,
        tags: action.payload
      };
    case actions.UI_DATA_AMENITY_TYPES:
      return {
        ...state,
        amenityTypes: action.payload
      };
    case actions.UI_DATA_PROVIDER_TYPES:
      return {
        ...state,
        providers: action.payload
      };
    case actions.UI_TOGGLE_MODAL_LOGIN:
      return {
        ...state,
        modalLogin: action.payload
      };
    case actions.UI_TOGGLE_LOGOUT_WARNING:
      return {
        ...state,
        modalLogoutWarning: action.payload
      };
    case actions.UI_TOGGLE_NO_HOTELS_ASSIGNED:
      return {
        ...state,
        noHotelsAssigned: action.payload
      };
    case actions.UI_CONFIRMATION_SHOW:
      return {
        ...state,
        confirmation: action.payload
      };
    case actions.UI_CONFIRMATION_HIDE:
      return {
        ...state,
        confirmation: {}
      };
    default:
      return state;
  }
}
