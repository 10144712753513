import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Avatar
} from '@material-ui/core';
import { Add, KeyboardArrowDown } from '@material-ui/icons';
import { useHistory } from 'react-router';
import get from 'lodash/get';

import useStyles from './ScaffoldStyles';

const ScaffoldPropertySelector = ({ availableProperties, property }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const showMenu = React.useCallback(ev => setAnchorEl(ev.currentTarget));
  const hideMenu = React.useCallback(() => setAnchorEl(null));
  const styles = useStyles();
  const { push } = useHistory();

  return (
    <React.Fragment>
      <Button
        aria-haspopup="true"
        onClick={showMenu}
        color="inherit"
        label={property.name || ' '}
        className={styles.menuButton}
      >
        {property.name || ' '}
        <KeyboardArrowDown />
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={hideMenu}>
        {availableProperties.map(avProperty =>
          <MenuItem
            key={avProperty.id}
            onClick={() => {
              push(`/${avProperty.id}/dashboard`);
              hideMenu();
            }}
            selected={property.id === avProperty.id}
          >
            <ListItemIcon>
              <Avatar
                src={avProperty.logoUrl || get(avProperty, 'photos[0].url')}
              />
            </ListItemIcon>
            <ListItemText primary={avProperty.name} />
          </MenuItem>
        )}
        <MenuItem
          key="addProperty"
          onClick={() => {
            push(`/addProperty`);
            hideMenu();
          }}
        >
          <ListItemIcon>
            <div className={styles.addPropertyButton}>
              <Add />
            </div>
          </ListItemIcon>
          <ListItemText primary="Objekt hinzufügen" />
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

ScaffoldPropertySelector.propTypes = {
  availableProperties: PropTypes.array,
  property: PropTypes.object
};

const mapStateToProps = state => ({
  availableProperties: state.auth.availableProperties
});

export default connect(mapStateToProps)(ScaffoldPropertySelector);
