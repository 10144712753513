import React from 'react';
import PropTypes from 'prop-types';
import {
  TextInput,
  ToggleInput,
  SelectInput,
  FormHeader
} from 'rb-toolset/lib/ui/FormControls';
import { isInteger, range } from 'lodash';
import { Field, FieldArray } from 'redux-form';
import { Unit, Column } from 'rb-toolset/lib/ui/Layout';
import { IconButton } from '@material-ui/core';
import { Delete, Hotel } from '@material-ui/icons';

import RoomOptionFormPriceField from './RoomOptionFormPriceField';

import useStyles from './RoomOptionFormStyles';

const hintstyle = { color: '#000' };

const validateCommissionType = value => {
  return !value ? 'Select a commission type' : null;
};

const parseAmount = value => {
  return value ? parseFloat(value) : null;
};

const validateAmount = value => {
  return (!value && value !== 0) || !isInteger(value) ? 'Only integers' : null;
};

const RoomOptionForm = ({
  name,
  values: { weekend },
  onDeleteRoomOption,
  occupancyMax,
  // guarantees,
  cancellationTerms,
  vistorFees,
  cleanupFees
}) => {
  const styles = useStyles();
  const defaultCancellationTerm = `${cancellationTerms.reduce(
    (acc, term) => (term.isDefault ? term.label : acc),
    ''
  )} (Default)`;
  // const defaultGuarantee = guarantees.reduce((acc, guarantee) => guarantee.isDefault ? guarantee.label : acc, '') + ' (Default)';
  const defaultVistorFees = `${vistorFees.reduce(
    (acc, fee) => (fee.isDefault ? fee.label : acc),
    ''
  )} (Default)`;
  const defaultCleanupFees = `${cleanupFees.reduce(
    (acc, fee) => (fee.isDefault ? fee.label : acc),
    ''
  )} (Default)`;

  return (
    <div>
      <Unit>
        <Column width={1 / 2}>
          <Field component={TextInput} name={`${name}.name`} label="Name" />
        </Column>
        <Column width={1 / 2} align="right">
          <IconButton onClick={onDeleteRoomOption}>
            <Delete />
          </IconButton>
        </Column>
      </Unit>
      <Field
        component={TextInput}
        name={`${name}.description`}
        label="Beschreibung der Zimmerrate"
        multiline
        rows={2}
        fullWidth
      />
      <Unit>
        <Column width={3 / 5}>
          <Field
            {...{ hintstyle }}
            autoWidth
            component={SelectInput}
            options={[
              { value: null, label: 'None' },
              { value: 'EC2_RISING_STAR', label: 'Rising Star' },
              { value: 'EC2_VIP', label: 'Vip' }
            ]}
            dummyValue={null}
            placeholder={null}
            name={`${name}.ec_role`}
            label="Eliteclub Role"
          />
        </Column>
      </Unit>
      <br /><br />
      <FormHeader>
        Preise in € inkl. Ust / exkl. Kurtaxen / Beherbergungsabgabe
      </FormHeader>
      <Unit>
        <Column width={1 / 5}>
          <span className={styles.label}>Belegung</span>
          <div className={styles.roomIcons}>
            {range(occupancyMax).map(person =>
              <div key={person} className={styles.roomIconsRow}>
                {`${person + 1} x`}&nbsp;<Hotel />
              </div>
            )}
          </div>
        </Column>
        <Column width={0.25}>
          <FieldArray
            label="Standardpreis*"
            name={`${name}.prices`}
            component={RoomOptionFormPriceField}
          />
        </Column>
        {weekend &&
          <Column width={0.25}>
            <FieldArray
              label="Wochenendpreis*"
              name={`${name}.pricesWeekend`}
              component={RoomOptionFormPriceField}
            />
          </Column>}
      </Unit>
      <br />
      <Field
        component={ToggleInput}
        label="Anderen Preis für Wochenende festlegen"
        name={`${name}.weekend`}
      />
      <br /><br />
      <FormHeader>
        Commission
      </FormHeader>
      <Unit>
        <Column width={3 / 5}>
          <Field
            {...{ hintstyle }}
            autoWidth
            component={SelectInput}
            // placeholder={defaultCancellationTerm}
            options={[
              { value: 'PERCENTAGE', label: 'PERCENTAGE' },
              { value: 'FIXED', label: 'FIXED' }
            ]}
            // dummyValue={defaultCancellationTerm}
            name={`${name}.commissionType`}
            validate={validateCommissionType}
            label="Commission Type"
          />

        </Column>
        <Column width={0.25}>
          <Field
            label="Commission"
            component={TextInput}
            name={`${name}.commissionValue`}
            type="number"
            validate={validateAmount}
            parse={parseAmount}
          />
        </Column>
      </Unit>
      <FormHeader>Stornierung & Bedingungen</FormHeader>
      <Field
        {...{ hintstyle }}
        autoWidth
        component={SelectInput}
        placeholder={defaultCancellationTerm}
        options={cancellationTerms}
        dummyValue={defaultCancellationTerm}
        name={`${name}.policyCancellationGroupId`}
        label="Stornierungsrichtlinie"
      />
      {/* <Field */}
      {/* component={SelectInput} */}
      {/* options={guarantees} */}
      {/* floatingLabelFixed */}
      {/* hintstyle={{ color: '#000' }} */}
      {/* dummyValue={defaultGuarantee} */}
      {/* placeholder={defaultGuarantee} */}
      {/* name={`${name}.policyGuaranteeId`} */}
      {/* label="Buchungsgarantie" */}
      {/* /> */}
      <FormHeader>Endreinigung & Touristentaxen</FormHeader>
      <Field
        {...{ hintstyle }}
        autoWidth
        component={SelectInput}
        options={vistorFees}
        dummyValue={defaultVistorFees}
        placeholder={defaultVistorFees}
        name={`${name}.policyFeesIds[0]`}
        label="Touristentaxen"
      />
      <Field
        {...{ hintstyle }}
        autoWidth
        component={SelectInput}
        options={cleanupFees}
        dummyValue={defaultCleanupFees}
        placeholder={defaultCleanupFees}
        name={`${name}.policyFeesIds[1]`}
        label="Endreinigung"
      />
    </div>
  );
};

RoomOptionForm.propTypes = {
  name: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  occupancyMax: PropTypes.number.isRequired,
  onDeleteRoomOption: PropTypes.func.isRequired,
  guarantees: PropTypes.array.isRequired,
  cancellationTerms: PropTypes.array.isRequired,
  cleanupFees: PropTypes.array.isRequired,
  vistorFees: PropTypes.array.isRequired
};

export default RoomOptionForm;
