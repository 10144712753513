import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { User } from 'rb-domain';
import { useHistory } from 'react-router';
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader
} from '@material-ui/core';
import { Person } from '@material-ui/icons';

const DashboardGuests = ({ propertyId, upcomingBookings }) => {
  const { push } = useHistory();
  const goToBookingDetail = React.useCallback(id =>
    push(`/${propertyId}/bookings/${id}`)
  );

  return (
    <List>
      <ListSubheader>Diese Woche</ListSubheader>
      {upcomingBookings.map(
        ({ id, bookingUserDataFinal, bookingOffers }, index) => (
          <div key={id} onClick={() => goToBookingDetail(id)}>
            <ListItem button secondaryTextLines={2}>
              <ListItemAvatar>
                <Avatar icon={<Person />} />
              </ListItemAvatar>
              <ListItemText
                primary={`${
                  bookingUserDataFinal.gender === 'MALE' ? 'Herr' : 'Frau'
                }${
                  bookingUserDataFinal.title
                    ? ` ${User.getTitleName(bookingUserDataFinal.title)}`
                    : ''
                } ${bookingUserDataFinal.firstName} ${
                  bookingUserDataFinal.lastName
                } - Ankunft: 
                      ${moment(bookingUserDataFinal).format('DD.MM.YYYY')}`}
                secondary={
                  <p>
                    {bookingOffers[0].rooms.map(room => room.name).join(',')} -{' '}
                    {bookingOffers[0].adults} Erwachsene{' '}
                    {bookingOffers[0].children &&
                      ` | ${bookingOffers[0].children} Kinder`}
                    <br />
                    {bookingOffers[0].comment}
                  </p>
                }
              />
            </ListItem>
            {index < upcomingBookings.length - 1 && <Divider inset />}
          </div>
        )
      )}
    </List>
  );
};

DashboardGuests.propTypes = {
  propertyId: PropTypes.number.isRequired,
  upcomingBookings: PropTypes.array.isRequired
};

export default DashboardGuests;
