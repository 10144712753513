import * as actions from 'actions/actionTypes';

const initialState = {};

export default function authReducer(state = initialState, action = {}) {
  switch (action.type) {
    case actions.API_PROPERTY_LOADED:
      return {
        ...action.payload,
        tags: [],
        loaded: true
      };
    case actions.API_PROPERTY_UPDATED:
      return {
        ...state,
        ...action.payload
      };
    case actions.API_PROPERTY_PROVIDER_ADDED:
      return {
        ...state,
        providers: state.providers.concat([action.payload])
      };
    case actions.API_PROPERTY_AMENITIES_LOADED:
      return {
        ...state,
        amenities: action.payload
      };
    case actions.API_PROPERTY_PROVIDER_DELETED:
      return {
        ...state,
        providers: state.providers.filter(
          provider => provider.id !== action.payload
        )
      };
    case actions.API_PROPERTY_ACOMMODATION_ADDED:
      return {
        ...state,
        accommodationTypes: state.accommodationTypes.concat([action.payload])
      };
    case actions.API_PROPERTY_ACOMMODATION_DELETED:
      return {
        ...state,
        accommodationTypes: state.accommodationTypes.filter(
          type => type.id !== action.payload
        )
      };
    default:
      return state;
  }
}
