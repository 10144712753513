import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, getFormValues, propTypes } from 'redux-form';
import {
  WYSIWYGEditor,
  FormContainer,
  TextInput,
  SelectInput,
  ToggleInput
} from 'rb-toolset/lib/ui/FormControls';
import { connect } from 'react-redux';

import Calculations from 'components/Calculations/Calculations';

import { paymentType } from './ReservationFormUtils';

const formContainerStyle = { display: 'flex', flexDirection: 'column' };

let ReservationForm = ({
  formValues,
  handleSubmit,
  onDeleteGuaranty,
  array,
  invalid,
  anyTouched
}) =>
  <FormContainer
    title="Buchungsgarantie"
    handleSubmit={handleSubmit}
    invalid={anyTouched && invalid}
    deleteText="Buchungsgarantie löschen"
    handleDelete={onDeleteGuaranty}
  >
    <div style={formContainerStyle}>
      <Field component={TextInput} name="name" label="Name" />
      <br />
      <Field
        component={ToggleInput}
        label="Buchungsgarantie als Standard verwenden"
        name="isDefault"
      />
      <br />
      <Field
        component={SelectInput}
        options={paymentType}
        autoWidth
        name="paymentType"
        label="Anzahlungstyp"
      />
      <br />
      <Field component={TextInput} name="value" label="Wert" type="number" />
      <br />
      <Field
        component={ToggleInput}
        name="percentage"
        label="Prozent des Gesammtbetrages"
      />
      <Calculations {...{ array, formValues }} />
      <p>Beschreibung (optional):</p>
      <Field
        component={WYSIWYGEditor}
        height={150}
        name="description"
        placeholder="Beschreiben Sie die Buchungsgarantie"
      />
    </div>
  </FormContainer>;

ReservationForm.propTypes = {
  ...propTypes,
  formValues: PropTypes.object.isRequired,
  onDeleteGuaranty: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  formValues: getFormValues('Reservation')(state) || {}
});

ReservationForm = connect(mapStateToProps)(ReservationForm);

export default reduxForm({
  form: 'Reservation',
  enableReinitialize: true,
  validate: (values = {}) => {
    const errors = {};

    if (values.name === undefined) {
      errors.name = 'Bitte geben Sie einen Namen an';
    }

    if (values.paymentType === undefined) {
      errors.paymentType = 'Bitte wählen Sie eine Garantie';
    }

    return errors;
  }
})(ReservationForm);
