import PropTypes from 'prop-types';
import { asyncConnect } from 'redux-connect';
import { connect } from 'react-redux';
import { reAuthenticate } from 'signals/auth';
import { addLocaleData } from 'react-intl';
// import { IntlProvider, addLocaleData } from 'react-intl';
// import messages from 'rb-domain/lib/Error/de.error.messages';
import de from 'react-intl/locale-data/de';
import { renderRoutes } from 'react-router-config';

import './App.scss';
// import { withCookies } from 'react-cookie';

addLocaleData(de);

const App = ({ isLoggingIn, route }) =>
  !isLoggingIn && renderRoutes(route.routes);

App.propTypes = {
  route: PropTypes.object
};

const mapStateToProps = state => ({
  isLoggingIn: state.auth.isLoggingIn
});

const AppConnected = connect(mapStateToProps)(App);

export default asyncConnect([
  {
    promise: options => {
      const { store: { dispatch, getState } } = options;
      const promises = [];

      if (!getState().auth.loggedIn) {
        promises.push(dispatch(reAuthenticate()));
      }

      return Promise.all(promises);
    }
  }
])(AppConnected);
