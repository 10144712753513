import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import {
  FormContainer,
  SelectInput,
  TextInput
} from 'rb-toolset/lib/ui/FormControls';
import { Column, Unit } from 'rb-toolset/lib/ui/Layout';

import validate from './validate';

const AddPropertyForm = ({ handleSubmit }) => {
  let starsOptions = [
    {
      value: 0,
      label: 'keine'
    }
  ];

  for (let i = 1; i < 6; i += 0.5) {
    starsOptions = [
      ...starsOptions,
      {
        value: i,
        label: i.toString().replace('.5', 'S')
      }
    ];
  }

  const typeOptions = [
    { value: 'ACCOMMONDATION', label: 'Travelworld Classic' },
    { value: 'ELITE_VILLA', label: 'EliteClub Clubhouse' },
    { value: 'ELITE_YACHT', label: 'EliteClub Yacht' },
    { value: 'ELITE_JET', label: 'EliteClub Aviation' }
  ];

  return (
    <div>
      <FormContainer
        title="Hotel anlegen"
        handleSubmit={handleSubmit}
        submitButtonText="Hotel anlegen"
      >
        <Unit>
          <Column width={1 / 2}>
            <Field
              component={TextInput}
              fullWidth
              type="text"
              label="Name"
              name="name"
            />
          </Column>
          <Column width={1 / 2}>
            <Field
              fullWidth
              component={SelectInput}
              options={starsOptions}
              name="stars"
              label="Anzahl der Sterne"
            />
          </Column>
        </Unit>
        <Unit>
          <Column width={1 / 2}>
            <Field
              component={TextInput}
              type="text"
              fullWidth
              label="E-Mail"
              name="email"
            />
          </Column>
          <Column width={1 / 2}>
            <Field
              component={TextInput}
              type="text"
              fullWidth
              label="Telefonnummer"
              name="telephone"
            />
          </Column>
        </Unit>
        <Unit>
          <Field
            component={SelectInput}
            fullWidth
            options={typeOptions}
            label="General Asset Type"
            name="type"
          />
        </Unit>
      </FormContainer>
    </div>
  );
};

AddPropertyForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

export default reduxForm({
  form: 'addPropertyForm',
  validate
})(AddPropertyForm);
