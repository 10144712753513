import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Divider,
  Button,
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import {
  KeyboardArrowDown,
  AccountCircle,
  ExitToApp
} from '@material-ui/icons';
import { useHistory } from 'react-router';

import ScaffoldPropertySelector from './ScaffoldPropertySelector';

import useStyles from './ScaffoldStyles';

const ScaffoldAppBar = ({ property, user }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const showMenu = React.useCallback(ev => setAnchorEl(ev.currentTarget));
  const hideMenu = React.useCallback(() => setAnchorEl(null));
  const styles = useStyles();
  const { push } = useHistory();

  const userOpen = Boolean(anchorEl);
  const menuPropertyId = property.id;

  return (
    <AppBar position="static" className={styles.appBar}>
      <Toolbar>
        <ScaffoldPropertySelector {...{ property }} />
        <div className={styles.menuWrapper}>
          <Button
            aria-owns={userOpen ? 'menu-appbar' : undefined}
            aria-haspopup="true"
            onClick={showMenu}
            color="inherit"
            className={styles.menuButton}
          >
            {user.email}
            <KeyboardArrowDown />
          </Button>
          <Menu
            id="menu-appbar"
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            style={{ width: '224px' }}
            open={userOpen}
            anchorEl={anchorEl}
            onClose={hideMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
          >
            <MenuItem
              onClick={() => {
                push(`/${menuPropertyId}/profile`);
                hideMenu();
              }}
            >
              <ListItemIcon>
                <AccountCircle />
              </ListItemIcon>
              <ListItemText primary="Konto" />
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                push('/logout');
                hideMenu();
              }}
            >
              <ListItemIcon>
                <ExitToApp />
              </ListItemIcon>
              <ListItemText primary="Abmelden" />
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

ScaffoldAppBar.propTypes = {
  property: PropTypes.object,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  property: state.property,
  user: state.auth.user
});

export default connect(mapStateToProps)(ScaffoldAppBar);
