import React, { Component } from 'react';

const ClientRender = () => WrappedComponent =>
  class ClientRenderWrapper extends Component {
    constructor(props) {
      super(props);
      this.state = {
        clientReady: false
      };
    }

    componentDidMount() {
      this.setClientReady();
    }

    setClientReady() {
      this.setState({
        clientReady: true
      });
    }

    render() {
      return this.state.clientReady
        ? <WrappedComponent {...this.props} />
        : null;
    }
  };

export default ClientRender;
