import React from 'react';
import PropTypes from 'prop-types';
import { Fields } from 'redux-form';
import { FormContainer } from 'rb-toolset/lib/ui/FormControls';

import { DateRangeInput } from 'components/FormControls';

import AvailabilityPriceForm from './AvailabilityPriceForm/AvailabilityPriceForm';
import AvailabilityForm from './AvailabilityForm';

const AvailabilityScreenSidebars = ({
  formValues,
  handleSubmit,
  onResetToDefaultPrices,
  onSubmitAvailability,
  onSubmitPrices,
  selectedRoom,
  selectedRoomOption
}) => {
  const { from, to } = formValues;
  const hasSpecifiedRange = !!from && !!to;

  return [
    <div key="1">
      <FormContainer title="Gültigkeitsbereich" hideFooter>
        <Fields component={DateRangeInput} names={['from', 'to']} />
      </FormContainer>
    </div>,
    hasSpecifiedRange &&
      <AvailabilityForm
        key="2"
        room={selectedRoom}
        onSubmit={values => {
          onSubmitAvailability({
            ...formValues,
            ...values
          });
        }}
      />,
    hasSpecifiedRange &&
      <AvailabilityPriceForm
        key="3"
        selectedRoomOption={selectedRoomOption}
        onResetToDefaultPrices={event => {
          event.preventDefault();
          onResetToDefaultPrices(formValues);
        }}
        handleSubmit={handleSubmit(onSubmitPrices)}
      />
  ];
};

AvailabilityScreenSidebars.propTypes = {
  formValues: PropTypes.object,
  handleSubmit: PropTypes.func,
  onResetToDefaultPrices: PropTypes.func,
  onSubmitAvailability: PropTypes.func,
  onSubmitPrices: PropTypes.func,
  selectedRoom: PropTypes.object,
  selectedRoomOption: PropTypes.object
};

export default AvailabilityScreenSidebars;
