import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { CheckCircle } from '@material-ui/icons';
import { ListItem, List } from '@material-ui/core';
import { FormContainer } from 'rb-toolset/lib/ui/FormControls';
import { Unit, Column } from 'rb-toolset/lib/ui/Layout';

const PoliciesCancellationGroups = ({ cancellationGroups, propertyId }) => {
  const { push } = useHistory();

  const handleClick = React.useCallback(id =>
    push(`/${propertyId}/housing/policies/cancellationGroup/edit/${id}`)
  );

  const handleNewCancellationGroup = React.useCallback(() =>
    push(`/${propertyId}/housing/policies/cancellationGroup/new`)
  );

  return (
    <FormContainer
      title="Stornierungsrichtlinien"
      handleNew={() => handleNewCancellationGroup()}
      hideFooter
      fullWidth
    >
      <List>
        {cancellationGroups.map((cancellationGroup, index) => {
          return (
            <ListItem
              button
              onClick={() => handleClick(cancellationGroup.id)}
              key={index}
            >
              <Column width={2 / 5}>{cancellationGroup.name}</Column>
              <Column width={2 / 5}>
                {cancellationGroup.cancellations.map((term, ind) => {
                  return <Unit key={ind}><span>{term.name}</span></Unit>;
                })}
              </Column>
              <Column width={1 / 5}>
                {cancellationGroup.isDefault && <CheckCircle color="primary" />}
              </Column>
            </ListItem>
          );
        })}
      </List>{' '}
    </FormContainer>
  );
};

PoliciesCancellationGroups.propTypes = {
  cancellationGroups: PropTypes.array.isRequired,
  propertyId: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
  cancellationGroups: state.property.cancellationGroups
});

export default connect(mapStateToProps)(PoliciesCancellationGroups);
