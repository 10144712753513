import { Property } from 'rb-domain';

export const calculationBasesOptions = Object.keys(
  Property.Policy.POLICY_CALCULATION_BASE
).map(key => {
  return {
    value: Property.Policy.POLICY_CALCULATION_BASE[key],
    label: Property.Policy.POLICY_CALCULATION_BASE[key]
  };
});
