import { signal } from 'rb-toolset/lib/signal';

import { goTo, notify } from 'rb-toolset/lib/signal/factories';

import {
  updateFeeAction,
  deleteFeeAction
} from 'actions/property/property-tax';

export const updateFee = signal([
  updateFeeAction,
  notify('Besteuerungsgruppe wurde gespeichert'),
  goTo(({ propertyId }) => `/${propertyId}/housing/policies`)
]);

export const deleteFee = signal([
  deleteFeeAction,
  notify('Besteuerungsgruppe wurde gelöscht'),
  goTo(({ propertyId }) => `/${propertyId}/housing/policies`)
]);
