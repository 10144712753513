exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".src-components-Calendar-CalendarHeader__container--153-S {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  width: 100%;\n}\n\n.src-components-Calendar-CalendarHeader__controls--1ahJ3 {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: end;\n      -ms-flex-pack: end;\n          justify-content: flex-end;\n}\n", "", {"version":3,"sources":["/usr/src/extranet-web/src/components/Calendar/CalendarHeader.scss"],"names":[],"mappings":"AAAA;EACE,qBAAa;EAAb,qBAAa;EAAb,cAAa;EACb,0BAAmB;MAAnB,uBAAmB;UAAnB,oBAAmB;EACnB,0BAA8B;MAA9B,uBAA8B;UAA9B,+BAA8B;EAC9B,YAAW;CACZ;;AAED;EAGE,qBAAa;EAAb,qBAAa;EAAb,cAAa;EACb,0BAAmB;MAAnB,uBAAmB;UAAnB,oBAAmB;EACnB,sBAAyB;MAAzB,mBAAyB;UAAzB,0BAAyB;CAC1B","file":"CalendarHeader.scss","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n}\n\n.controls {\n  //float: right;\n  //width: 300px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"container": "src-components-Calendar-CalendarHeader__container--153-S",
	"controls": "src-components-Calendar-CalendarHeader__controls--1ahJ3"
};