import React from 'react';
import PropTypes from 'prop-types';
import { TextInput } from 'rb-toolset/lib/ui/FormControls';
import { Field } from 'redux-form';

import useStyles from './RoomOptionFormStyles';

const parseNumber = value => (value ? parseFloat(value, 10) : null);

const RoomOptionFormPriceField = ({ fields, meta, label }) => {
  const styles = useStyles();

  return (
    <div>
      <span
        className={meta.error && meta.dirty ? styles.labelDirty : styles.label}
      >
        {label}
      </span>
      {fields.map((name, index) =>
        <Field
          key={index}
          component={TextInput}
          name={name}
          className={styles.roomPrice}
          type="number"
          parse={parseNumber}
          step="any"
        />
      )}
    </div>
  );
};

RoomOptionFormPriceField.propTypes = {
  fields: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.string
};

export default RoomOptionFormPriceField;
