function addCancellation(cancellation, api) {
  return api().entity('policy-cancellation').post({
    name: cancellation.name,
    description: cancellation.description,
    daysPriorArrival: cancellation.daysPriorArrival,
    timePriorArrival: cancellation.timePriorArrival,
    calculationBases: cancellation.calculationBases,
    calculationAmounts: cancellation.calculationAmounts,
    policyCancellationGroupId: cancellation.cancellationGroupId
  });
}

function updateCancellation(cancellation, api) {
  return api().entity('policy-cancellation').patch({
    ...cancellation
  });
}

export function updateCancellationAction({
  input: { cancellationGroup: { cancellations, id } },
  api
}) {
  const cancellationGroupId = id;
  return Promise.all(
    (cancellations || []).map((cancellation, position) => {
      const patchCancellation = cancellationGroupId
        ? {
            ...cancellation,
            cancellationGroupId,
            position
          }
        : { ...cancellation, position };
      return cancellation.id
        ? updateCancellation(patchCancellation, api)
        : addCancellation(patchCancellation, api);
    })
  ).then(() => ({}));
  // For now we have to return empty object when calling Promise.all in function-tree
}
export function deleteCancellationAction({ input: { id }, api }) {
  return api().entity('policy-cancellation').delete({
    id
  });
}
