import React, { Component } from 'react';
import { green, red, blue, grey } from '@material-ui/core/colors';
import { ExitToApp, Replay, FiberManualRecord } from '@material-ui/icons';
import DayPicker, { DateUtils } from 'react-day-picker';
import moment from 'moment';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/de';
import { min } from 'lodash';
import PropTypes from 'prop-types';
import { Const } from 'rb-domain';
import { Unit, Column } from 'rb-toolset/lib/ui/Layout';
import injectStyle from 'react-jss';
import classNames from 'classnames';
import CalendarHeader from './CalendarHeader';
import CalendarDay from './CalendarDay';
// Import styles from DayPicker 3rd module
import './Calendar.scss';
import styles from './CalendarStyles';

const isDisabledDay = date => moment().hour(0).isAfter(date);

@injectStyle(styles)
export default class Calendar extends Component {
  static propTypes = {
    onMonthChanged: PropTypes.func.isRequired,
    selectedRange: PropTypes.object,
    availability: PropTypes.object.isRequired,
    isWeekend: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    roomOption: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
  };

  static defaultProps = {
    selectedRange: {
      from: null,
      to: null
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedRange: props.selectedRange,
      selecting: false,
      currentDate: new Date()
    };

    this.cancelSelection = this.cancelSelection.bind(this);
    this.renderDay = this.renderDay.bind(this);
    this.onMonthChange = this.onMonthChange.bind(this);
    this.onTodayClick = this.onTodayClick.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      selectedRange: nextProps.selectedRange
    });

    if (nextProps.roomOption !== this.roomOption) {
      this.setState({
        defaultMinimumPrice: min(nextProps.roomOption.prices),
        defaultMinimumPriceWeekend: min(nextProps.roomOption.pricesWeekend)
      });
    }
  }

  onDayHovered(day) {
    if (this.state.selecting && !isDisabledDay(day)) {
      const { selectedRange: { from, to } } = this.state;

      this.setState({
        selectedRange: DateUtils.addDayToRange(day, { from, to })
      });
    }
  }

  onDaySelectStart(day) {
    if (!isDisabledDay(day)) {
      this.setState({
        selectedRange: DateUtils.addDayToRange(day),
        selecting: true
      });
    }
  }

  onDaySelectEnd(day) {
    const { selectedRange: { from, to } } = this.state;

    if (!isDisabledDay(day)) {
      const fromTo = {
        from: day < to ? day : from,
        to: day < to ? to : day
      };

      this.setState({
        selectedRange: fromTo,
        selecting: false
      });

      this.handleDateRangeSelection(fromTo);
    } else {
      this.handleDateRangeSelection({
        from,
        to
      });
    }
  }

  onMonthChange(date) {
    this.setState({
      currentDate: date
    });

    this.props.onMonthChanged(date);
  }

  onTodayClick() {
    this.refs.daypicker.showMonth(new Date());
  }

  handleDateRangeSelection(fromTo) {
    const { change } = this.props;
    change('from', moment(fromTo.from).format(Const.DAY_PATTERN));
    change('to', moment(fromTo.to).format(Const.DAY_PATTERN));
  }

  cancelSelection() {
    this.setState({
      selecting: false
    });
  }

  renderDay(day) {
    const { availability, roomOption, isWeekend } = this.props;
    const { defaultMinimumPrice, defaultMinimumPriceWeekend } = this.state;
    const data = availability[moment(day).format(Const.DAY_PATTERN)] || {};

    const defaultPrice = isWeekend(moment(day)) && defaultMinimumPriceWeekend
      ? defaultMinimumPriceWeekend
      : defaultMinimumPrice;
    const minDayPrice = data.prices && data.prices[roomOption.id]
      ? min(data.prices[roomOption.id].prices)
      : defaultPrice;
    const isStandardPrice = data.prices && data.prices[roomOption.id]
      ? data.prices[roomOption.id].useStandardPrices
      : true;
    // const minDayPrice = data.prices && data.prices[roomOption.id] ? data.prices[roomOption.id].price1 : roomOption.price1;

    return (
      <CalendarDay
        day={day}
        onMouseDown={() => this.onDaySelectStart(day)}
        onMouseUp={() => this.onDaySelectEnd(day)}
        onMouseEnter={() => this.onDayHovered(day)}
        price={minDayPrice}
        bookingsCount={data.bookings}
        minimumDaysToStay={data.minimumDaysToStay}
        availableCount={data.availableCount}
        blocked={data.blocked}
        isStandardPrice={isStandardPrice}
        disabled={isDisabledDay(day)}
      />
    );
  }

  render() {
    const { selectedRange: { from, to } } = this.state;
    const { classes } = this.props;

    const Test = () => <span />;

    const fromDate = from ? moment(from).toDate() : null;
    const toDate = to ? moment(to).toDate() : null;

    return (
      <div onMouseLeave={this.cancelSelection} onMouseUp={this.cancelSelection}>
        <DayPicker
          enableOutsideDays
          selectedDays={day =>
            DateUtils.isDayInRange(day, { from: fromDate, to: toDate })}
          renderDay={this.renderDay}
          ref="daypicker"
          locale="de"
          disabledDays={isDisabledDay}
          localeUtils={MomentLocaleUtils}
          captionElement={<Test />}
          navbarElement={
            <CalendarHeader
              currentDate={this.state.currentDate}
              onTodayClick={this.onTodayClick}
            />
          }
          onMonthChange={this.onMonthChange}
        />
        <Unit className={classes.legend}>
          <Column width={1 / 3}>
            <div className={classes.legendElement}>
              <FiberManualRecord style={{ color: grey['300'] }} />...
              Standardpreis
            </div>
            <div className={classes.legendElement}>
              <ExitToApp style={{ color: green['200'] }} />... Anreise erlaubt
            </div>
            <div className={classes.legendElement}>
              <div className={classes.availabilityActive} />
              &nbsp;... Anzahl Zimmer
            </div>
          </Column>
          <Column width={1 / 3}>
            <div className={classes.legendElement}>
              <FiberManualRecord style={{ color: blue['300'] }} />...
              tagesabhängiger Preis
            </div>
            <div className={classes.legendElement}>
              <ExitToApp style={{ color: red['200'] }} /> ... Anreise nicht
              erlaubt
            </div>
            <div className={classes.legendElement}>
              <div
                className={classNames(
                  classes.availabilityActive,
                  classes.noAvailability
                )}
              />
              &nbsp;... keine Zimmer
            </div>
          </Column>
          <Column width={1 / 3}>
            <div className={classes.legendElement}>
              <Replay style={{ color: red['200'] }} />...
              Mindestnächtigungsdauer
            </div>
          </Column>
        </Unit>
      </div>
    );
  }
}
