import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormContainer } from 'rb-toolset/lib/ui/FormControls';
import Message from 'rb-toolset/lib/ui/Message/Message';
import Button from '@material-ui/core/Button';
import glamorous from 'glamorous';

const { Img, Div } = glamorous;

const stripeButtonImg = require('./assets/stripe_button.png');

const STRIPE_CLIENT_ID = process.env.RAZZLE_STRIPE_CLIENT_ID;

export default class StripeForm extends Component {
  static propTypes = {
    csrfToken: PropTypes.string,
    onDeAuth: PropTypes.func.isRequired,
    property: PropTypes.object.isRequired
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
  }

  renderConnect() {
    const { csrfToken } = this.props;

    return (
      <div>
        <p>Verknüpfen Sie ihren Stripe Account mit Ihrem Extranet Zugang:</p>
        <a
          href={`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${STRIPE_CLIENT_ID}&state=${csrfToken}`}
        >
          <Img width={150} src={stripeButtonImg} alt="Stripe Button" />
        </a>
      </div>
    );
  }

  renderConnected() {
    return (
      <div>
        <Message>
          <p>Ihr Extranet Account wurde bereits mit Stripe verknüpft. </p>
          <Div textAlign="center">
            <Button
              label="Verknüpfung aufheben"
              onClick={this.props.onDeAuth}
            />
          </Div>
        </Message>
      </div>
    );
  }

  render() {
    const { property } = this.props;

    const connected = property.stripeUserId;

    return (
      <FormContainer title="Stripe Verknüpfung" fullWidth={connected}>
        {!connected && this.renderConnect()}
        {connected && this.renderConnected()}
      </FormContainer>
    );
  }
}
