import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent, IconButton } from '@material-ui/core';

import { ExpandLess, ExpandMore } from '@material-ui/icons';

import CancellationForm from './CancellationForm/CancellationForm';

import useStyles from './CancellationGroupFormStyles';

const CancellationGroupFormItem = ({
  array,
  cancellations,
  fields,
  expandedOption,
  setExpandedOption,
  showConfirmation
}) => {
  const styles = useStyles();
  const onDeleteCancellationOption = React.useCallback(index =>
    showConfirmation('confirmation.cancellation', {
      cancellationId: cancellations[index].id,
      index
    })
  );

  return (
    <div>
      {fields.map((cancellation, index) => {
        const expanded = expandedOption === index;
        const bodyStyle = `${styles.optionBody} ${!expanded
          ? styles.hidden
          : ''}`;

        return (
          <Card className={styles.option} key={index}>
            <CardHeader
              action={
                <IconButton
                  onClick={() => setExpandedOption(expanded ? null : index)}
                  aria-expanded={expanded}
                >
                  {expanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              }
              title={
                cancellations[index].name || 'Geben Sie bitte einen Namen an'
              }
              subheader={
                <div
                  dangerouslySetInnerHTML={{
                    __html: cancellations[index].description
                  }}
                />
              }
              className={styles.optionHeader}
            />
            <CardContent className={bodyStyle}>
              <CancellationForm
                {...{ array, fields }}
                name={cancellation}
                onDeleteCancellationOption={() =>
                  onDeleteCancellationOption(index)}
                values={cancellations[index]}
              />
            </CardContent>
          </Card>
        );
      })}
    </div>
  );
};

CancellationGroupFormItem.propTypes = {
  array: PropTypes.object,
  cancellations: PropTypes.array,
  fields: PropTypes.object,
  expandedOption: PropTypes.number,
  setExpandedOption: PropTypes.func,
  showConfirmation: PropTypes.func
};

export default CancellationGroupFormItem;
