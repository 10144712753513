import React from 'react';
import { FormContainer, FormHeader } from 'rb-toolset/lib/ui/FormControls';
import { Radio, FormControlLabel } from '@material-ui/core';
import { Column } from 'rb-toolset/lib/ui/Layout';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import {
  AutoCompleteInput,
  TextInput,
  RadioButtonGroupInput,
  SelectInput
} from 'rb-toolset/lib/ui/FormControls';
import PropTypes from 'prop-types';
import { User } from 'rb-domain';
import { pick } from 'lodash';

import PaperContainer from 'components/PaperContainer/PaperContainer';

import useStyles from './ContactFormStyles';

const { GENDERS: { MALE, FEMALE }, TITLES, getTitleName } = User;

const titles = Object.keys(TITLES).map(title => {
  const key = TITLES[title];
  return {
    label: getTitleName(key),
    value: key
  };
});

let ContactForm = ({ handleSubmit, countries }) => {
  const styles = useStyles();

  return (
    <div>
      <PaperContainer>
        <FormContainer
          classes={{
            container: styles.container,
            formContainer: styles.formContainer
          }}
          title="Kontakt"
          handleSubmit={handleSubmit}
        >
          <FormHeader>Ansprechpartner/in</FormHeader>
          <div className={styles.firstRow}>
            <Column width={1 / 2}>
              <Field
                component={SelectInput}
                fullWidth
                options={titles}
                label="Titel"
                name="cpTitle"
              />
            </Column>
            <Column width={1 / 2}>
              <Field
                className={styles.genderWrapper}
                component={RadioButtonGroupInput}
                name="cpGender"
              >
                <FormControlLabel
                  value={FEMALE}
                  control={<Radio />}
                  label="Weiblich"
                />
                <FormControlLabel
                  value={MALE}
                  control={<Radio />}
                  label="Männlich"
                />
              </Field>
            </Column>
          </div>
          <Field component={TextInput} label="Vorname" name="cpFirstName" />
          <Field component={TextInput} label="Nachname" name="cpLastName" />
          <br />
          <Field component={TextInput} label="E-Mail" name="cpEmail" />
          <br />
          <Field component={TextInput} label="Telefon" name="cpPhone" />
          <FormHeader>
            Rechnungsadresse
          </FormHeader>
          <Field component={TextInput} label="Firmenname" name="iaName" />
          <Field component={TextInput} label="zuHanden" name="iaToHand" />
          <br />
          <Field
            component={TextInput}
            fullWidth
            label="Straße"
            name="iaStreet"
          />
          <br />
          <Field component={TextInput} label="PLZ" name="iaZip" />
          <Field component={TextInput} label="Ort" name="iaCity" /><br />
          <Field
            component={AutoCompleteInput}
            options={countries}
            name="iaCountryId"
            label="Land"
            fullWidth
          />
          <br />
          <Field component={TextInput} label="UID-Nr" name="iaUidNr" />
          <br />
        </FormContainer>
      </PaperContainer>
    </div>
  );
};

ContactForm.propTypes = {
  property: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

ContactForm = reduxForm({
  form: 'ContactForm'
})(ContactForm);

export default connect(state => ({
  countries: state.location.countries,
  initialValues: {
    ...pick(state.property, [
      'cpTitle',
      'cpGender',
      'cpFirstName',
      'cpLastName',
      'cpEmail',
      'cpPhone',
      'iaName',
      'iaToHand',
      'iaStreet',
      'iaZip',
      'iaCity',
      'iaCountryId',
      'iaUidNr'
    ])
  }
}))(ContactForm);
