import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Hotel } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import { TextInput } from 'rb-toolset/lib/ui/FormControls';

import useStyles from './AvailabilityPriceFormStyles';

const AvailabilityPriceFormField = ({
  fields,
  meta,
  onResetToDefaultPrices
}) => {
  const styles = useStyles();
  const errorStyle = meta.error && meta.dirty ? { color: '#F44336' } : {};
  const parseField = React.useCallback(value => value && parseFloat(value, 10));

  return (
    <div>
      <span>
        Standardpreise{' '}
        <button type="button" onClick={onResetToDefaultPrices}>
          verwenden
        </button>
      </span>
      {fields.map((price, i) =>
        <div className={styles.field} key={i}>
          <div className={styles.roomIcons}>
            <Button
              style={{ textAlign: 'left' }}
              hovercolor="transparent"
              disableRipple
            >
              {i + 1} x&nbsp;<Hotel />
            </Button>
          </div>
          <Field
            component={TextInput}
            name={`${price}`}
            className={styles.roomPrice}
            type="number"
            step="any"
            parse={parseField}
          />
        </div>
      )}
      <span style={errorStyle}>{meta.dirty && meta.error}</span>
    </div>
  );
};

AvailabilityPriceFormField.propTypes = {
  fields: PropTypes.object,
  meta: PropTypes.object,
  onResetToDefaultPrices: PropTypes.func
};

export default AvailabilityPriceFormField;
