import { createUseStyles } from 'react-jss';

export default createUseStyles({
  category: {
    height: '50px',
    '& .MuiFormControl-root, & .MuiInputBase-root': {
      margin: '0'
    },
    '& > div:nth-child(-n + 3)': {
      paddingRight: '16px'
    }
  },
  sliderWrapper: {
    margin: '60px 20px 40px 20px',
    textAlign: 'center',
    '& .rc-slider-dot': {
      display: 'none'
    },
    '& .rc-slider-handle': {
      marginLeft: '0 !important'
    },
    '& .rc-slider-track, & .rc-slider-step, & .rc-slider-handle': {
      top: '0'
    },
    '& .rc-slider-mark-text:nth-child(odd)': {
      bottom: '-20px'
    },
    '& .rc-slider-mark-text:nth-child(even)': {
      bottom: '20px'
    }
  }
});
