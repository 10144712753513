const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Bitte geben Sie einen Namen für Ihr Hotel ein.';
  }
  if (!values.telephone) {
    errors.telephone = 'Bitte geben Sie eine Telefonnummer ein.';
  }
  if (!values.email) {
    errors.email = 'Bitte geben Sie eine E-Mail Adresse ein.';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email =
      'Ihre eingegebene E-Mail Adresse weißt ein unbekanntes Format auf.';
  }
  return errors;
};

export default validate;
