import { Property } from 'rb-domain';

export const mapFeeType = code => {
  switch (code) {
    case Property.Policy.POLICY_FEE_TYPE.ROOM_CLEANUP:
      return 'Endreinungung';
    case Property.Policy.POLICY_FEE_TYPE.VISITOR_TAX:
      return 'Touristentaxe';
    default:
      return '';
  }
};

export const mapCalculationType = code => {
  switch (code) {
    case Property.Policy.POLICY_CALCULATION_TYPE.INCLUDED:
      return 'Inkludiert';
    case Property.Policy.POLICY_CALCULATION_TYPE.EXCLUDED:
      return 'Exkludiert';
    case Property.Policy.POLICY_CALCULATION_TYPE.ONSITE:
      return 'Vor-Ort';
    default:
      return '';
  }
};

export const mapRenderType = code => {
  switch (code) {
    case Property.Policy.POLICY_CALCULATION_RENDER_TYPE.ADD_ROW:
      return 'Sichtbar';
    case Property.Policy.POLICY_CALCULATION_RENDER_TYPE.HIDDEN:
      return 'Versteckt';
    default:
      return '';
  }
};

export const feeType = Object.keys(Property.Policy.POLICY_FEE_TYPE).map(key => {
  return {
    value: Property.Policy.POLICY_FEE_TYPE[key],
    label: mapFeeType(Property.Policy.POLICY_FEE_TYPE[key])
  };
});
export const calculationType = Object.keys(
  Property.Policy.POLICY_CALCULATION_TYPE
).map(key => {
  return {
    value: Property.Policy.POLICY_CALCULATION_TYPE[key],
    label: mapCalculationType(Property.Policy.POLICY_CALCULATION_TYPE[key])
  };
});

export const renderType = Object.keys(
  Property.Policy.POLICY_CALCULATION_RENDER_TYPE
).map(key => {
  return {
    value: Property.Policy.POLICY_CALCULATION_RENDER_TYPE[key],
    label: mapRenderType(Property.Policy.POLICY_CALCULATION_RENDER_TYPE[key])
  };
});
