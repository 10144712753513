import React from 'react';
import PropTypes from 'prop-types';
import { asyncConnect } from 'redux-connect';
import { connect } from 'react-redux';
import { pickBy, mapValues, mapKeys } from 'lodash';
import { loadCountries$ } from 'rb-toolset/lib/redux/geo/signals';
import GoogleServiceLoader from 'rb-toolset/lib/ui/GoogleServiceLoader/GoogleServiceLoader';
import { CircularProgress } from '@material-ui/core';

import { saveProperty as savePropertySignal } from 'signals/property';

import PaperContainer from 'components/PaperContainer/PaperContainer';

import LocationForm from './LocationForm/LocationForm';

let Location = ({ countries, propertyId, regionTouristics, saveProperty }) => {
  const [
    regionTouristicsDoneOperations,
    setRegionTouristicsDoneOperations
  ] = React.useState({});

  const onSubmit = React.useCallback(data => {
    const fixedData = {
      ...data,
      lngLat: data.lngLat.lng ? [data.lngLat.lng, data.lngLat.lat] : data.lngLat
    };

    const currentRegions = mapValues(
      mapKeys(regionTouristics, 'id'),
      () => true
    );

    const rChanges = pickBy(
      data.regionTouristics,
      (flag, id) => currentRegions[id] !== flag
    );

    const regionTouristicIds = Object.keys(rChanges).reduce((acc, key) => {
      return key in regionTouristicsDoneOperations &&
        regionTouristicsDoneOperations[key] === rChanges[key]
        ? acc
        : { ...acc, [key]: rChanges[key] };
    }, {});

    saveProperty({
      propertyId,
      data: fixedData,
      regionTouristicIds
    });

    setRegionTouristicsDoneOperations({
      ...regionTouristicsDoneOperations,
      ...rChanges
    });
  });

  return (
    <PaperContainer>
      <GoogleServiceLoader
        loadingElement={<CircularProgress />}
        googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.27&key=AIzaSyBalug0o0tpie22scvEzdwLH9MHAYZqr9E&libraries=places"
      >
        <LocationForm {...{ countries, onSubmit }} />
      </GoogleServiceLoader>
    </PaperContainer>
  );
};

Location.propTypes = {
  countries: PropTypes.array.isRequired,
  saveProperty: PropTypes.func.isRequired,
  propertyId: PropTypes.number.isRequired,
  regionTouristics: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  countries: state.geo.countries,
  regionTouristics: state.property.regionTouristics,
  propertyId: parseInt(state.property.id, 10)
});

const mapDispatchToProps = { saveProperty: savePropertySignal };

Location = connect(mapStateToProps, mapDispatchToProps)(Location);

export default asyncConnect([
  {
    key: 'test',
    promise: options => {
      const { store: { dispatch } } = options;
      const promises = [];
      promises.push(dispatch(loadCountries$()));

      return Promise.all(promises);
    }
  }
])(Location);
