import { Property } from 'rb-domain';

const mapPaymentType = paymentType => {
  switch (paymentType) {
    case Property.Policy.POLICY_PAYMENT_TYPE.NONE:
      return 'keine';
    case Property.Policy.POLICY_PAYMENT_TYPE.CREDIT_CARD_GUARANTEE:
      return 'Kreditkartenspeicherung';
    case Property.Policy.POLICY_PAYMENT_TYPE.CREDIT_CARD_PREPAYMENT:
      return 'Kreditkarten-Zahlung';
    case Property.Policy.POLICY_PAYMENT_TYPE.BANK_TRANSFER_PREPAYMENT:
      return 'Banküberweisung';
    default:
      return '';
  }
};

export const paymentType = Object.keys(
  Property.Policy.POLICY_PAYMENT_TYPE
).map(key => {
  return {
    value: Property.Policy.POLICY_PAYMENT_TYPE[key],
    label: mapPaymentType(Property.Policy.POLICY_PAYMENT_TYPE[key])
  };
});
