import React from 'react';
import { BackLink } from 'rb-toolset/lib/ui/FormControls';
import { connect } from 'react-redux';
import PaperContainer from 'components/PaperContainer/PaperContainer';
import HelpboxContainer from 'components/HelpboxContainer/HelpboxContainer';
import {
  updateGuarantee as updateGuaranteeSignal,
  deleteGuarantee as deleteGuaranteeSignal
} from 'signals/property-guarantee';
import { showConfirmation as showConfirmationAction } from 'actions/ui/ui';
import PropTypes from 'prop-types';
import ConfirmationDialog from 'rb-toolset/lib/ui/ConfirmationDialog';

import ReservationForm from './ReservationForm/ReservationForm';

const Reservation = ({
  deleteGuarantee,
  guarantee,
  propertyId,
  showConfirmation,
  updateGuarantee
}) => {
  const onSubmit = React.useCallback(formValues => {
    const values = {
      calculationAmounts: formValues.calculationAmounts,
      calculationBases: formValues.calculationBases,
      description: formValues.description,
      isDefault: formValues.isDefault,
      name: formValues.name,
      paymentType: formValues.paymentType,
      propertyId
    };

    updateGuarantee({
      guarantee: {
        ...values,
        id: guarantee.id
      },
      propertyId
    });
  });

  const onDeleteGuaranty = React.useCallback(() =>
    showConfirmation('reservation.delete')
  );

  const onDelete = React.useCallback(() => {
    deleteGuarantee({
      id: guarantee.id,
      propertyId
    });
  });

  return (
    <div>
      <PaperContainer
        helpBox={[
          <HelpboxContainer headline="Buchungsgarantien">
            <p>
              <strong>Beispiele</strong>
            </p>
            <ul>
              <li>Buchung nur mit Kreditkartengarantie möglich</li>
              <li>Keine Garantie erforderlich</li>
              <li>xy% Deposit bis xy Tage vor Anreise</li>
            </ul>
          </HelpboxContainer>
        ]}
      >
        <div>
          <BackLink to={`/${propertyId}/housing/policies`}>
            « zurück zur AGBs/Stornierung
          </BackLink>
          <ReservationForm
            initialValues={guarantee}
            {...{ onSubmit, onDeleteGuaranty }}
          />
          <ConfirmationDialog
            id="reservation.delete"
            confirmText="Ja"
            cancelText="Nein"
            title="Buchungsgarantie löschen"
            onConfirm={onDelete}
          >
            Wollen Sie diese Buchungsgarantie wirklich löschen?
          </ConfirmationDialog>
        </div>
      </PaperContainer>
    </div>
  );
};

Reservation.propTypes = {
  guarantee: PropTypes.object.isRequired,
  propertyId: PropTypes.number.isRequired,
  updateGuarantee: PropTypes.func.isRequired,
  deleteGuarantee: PropTypes.func.isRequired,
  showConfirmation: PropTypes.func.isRequired
};

const mapStateToProps = (state, options) => ({
  guarantee: options.match.params.id
    ? state.property.guarantees.filter(
        item => item.id === parseInt(options.match.params.id, 10)
      )[0]
    : {},
  propertyId: parseInt(state.property.id, 10)
});

const mapDispatchToProps = {
  deleteGuarantee: deleteGuaranteeSignal,
  updateGuarantee: updateGuaranteeSignal,
  showConfirmation: showConfirmationAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Reservation);
