import PropTypes from 'prop-types';
import React from 'react';
import PaperContainer from 'components/PaperContainer/PaperContainer';
import { connect } from 'react-redux';
import {
  List,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useHistory } from 'react-router';
import get from 'lodash/get';

import useStyles from '../Scaffold/ScaffoldStyles';

const PropertySelector = ({ availableProperties, propertiesLoaded }) => {
  const { push } = useHistory();
  const styles = useStyles();

  React.useEffect(
    () => {
      if (propertiesLoaded) {
        if (availableProperties.length === 1) {
          push(`/${availableProperties[0].id}/dashboard`);
        } else if (availableProperties.length === 0) {
          push(`/addProperty`);
        }
      }
    },
    [availableProperties]
  );

  return (
    availableProperties.length > 1 &&
    <PaperContainer center>
      <List disablePadding>
        <ListSubheader inset>Verfügbare Hotels</ListSubheader>
        {availableProperties.map(property =>
          <ListItem
            key={property.id}
            component="span"
            button
            onClick={() => {
              push(`/${property.id}/dashboard`);
            }}
          >
            <ListItemAvatar>
              <Avatar
                src={property.logoUrl || get(property, 'photos[0].url')}
              />
            </ListItemAvatar>
            <ListItemText
              primary={property.name}
              secondary={property.address}
            />
          </ListItem>
        )}
        <ListItem
          key="addProperty"
          component="span"
          button
          onClick={() => push(`/addProperty`)}
        >
          <ListItemAvatar>
            <div className={styles.addPropertyButton}>
              <Add />
            </div>
          </ListItemAvatar>
          <ListItemText primary="Objekt hinzufügen" />
        </ListItem>
      </List>
    </PaperContainer>
  );
};

PropertySelector.propTypes = {
  availableProperties: PropTypes.array.isRequired,
  propertiesLoaded: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  availableProperties: state.auth.availableProperties,
  propertiesLoaded: state.auth.propertiesLoaded
});

export default connect(mapStateToProps)(PropertySelector);
