import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import PaperContainer from 'components/PaperContainer/PaperContainer';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import DetailSidebar from './DetailSidebar';

const localizer = momentLocalizer(moment); // or globalizeLocalizer

export default class GuestsDetailsScreen extends Component {
  static propTypes = {
    bookings: PropTypes.array.isRequired,
    onCalendarRangeChange: PropTypes.func.isRequired
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      selectedReservation: null
    };
  }

  render() {
    const { selectedReservation } = this.state;
    const { bookings, onCalendarRangeChange } = this.props;

    return (
      <PaperContainer>
        <div style={{ height: '590px', padding: 10 }}>
          <Calendar
            onRangeChange={onCalendarRangeChange}
            localizer={localizer}
            events={bookings}
            startAccessor="start"
            endAccessor="end"
            views={['month']}
            onSelectEvent={event => {
              this.setState({ selectedReservation: event });
            }}
          />
        </div>
        {selectedReservation &&
          <DetailSidebar
            selectedReservation={selectedReservation}
            closeDetails={() => {
              this.setState({ selectedReservation: null });
            }}
          />}
      </PaperContainer>
    );
  }
}
