import * as actions from 'actions/actionTypes';

const initialState = {};

export default function availabilityReducer(state = initialState, action = {}) {
  switch (action.type) {
    case actions.API_PROPERTY_AVAILABILITY_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case actions.API_PROPERTY_AVAILABILITY_LOADED:
      return {
        ...state,
        currentAvailability: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
