// UI
export const UI_LOGGING_IN = 'UI_LOGGING_IN';
export const UI_LOGIN_SUCCESS = 'UI_LOGIN_SUCCESS';
export const UI_LOGIN_IMPERSONATE = 'UI_LOGIN_IMPERSONATE';
export const UI_LOGIN_ERROR = 'UI_LOGIN_ERROR';
export const UI_LOGIN_PROPERTY_MANAGER_ERROR =
  'UI_LOGIN_PROPERTY_MANAGER_ERROR';
export const UI_TOGGLE_MODAL_LOGIN = 'UI_TOGGLE_MODAL_LOGIN';
export const UI_TOGGLE_LOGOUT_WARNING = 'UI_TOGGLE_LOGOUT_WARNING';
export const UI_LOGOUT = 'UI_LOGOUT';
export const UI_TOGGLE_NOTIFICATION = 'UI_TOGGLE_NOTIFICATION';
export const UI_TOGGLE_UPLOADING_PROGRESS = 'UI_TOGGLE_UPLOADING_PROGRESS';
export const UI_TOGGLE_NO_HOTELS_ASSIGNED = 'UI_TOGGLE_NO_HOTELS_ASSIGNED';
export const UI_CONFIRMATION_SHOW = 'UI_CONFIRMATION_SHOW';
export const UI_CONFIRMATION_HIDE = 'UI_CONFIRMATION_HIDE';

export const API_BONUS_LIST = 'API_BONUS_LIST';

// Property
export const API_PROPERTY_LOADED = 'API_PROPERTY_LOADED';
export const API_PROPERTY_UPDATED = 'API_PROPERTY_UPDATED';
export const API_PROPERTIES_LOADED = 'API_PROPERTIES_LOADED';
export const API_PROPERTY_PROVIDER_ADDED = 'API_PROPERTY_PROVIDER_ADDED';
export const API_PROPERTY_PROVIDER_DELETED = 'API_PROPERTY_PROVIDER_DELETED';
export const API_PROPERTY_ACOMMODATION_ADDED =
  'API_PROPERTY_ACOMMODATION_ADDED';
export const API_PROPERTY_ACOMMODATION_DELETED =
  'API_PROPERTY_ACOMMODATION_DELETED';
export const API_PROPERTY_ROOMS_LOADED = 'API_PROPERTY_ROOMS_LOADED';
export const API_PROPERTY_ROOMS_ROOM_CREATED =
  'API_PROPERTY_ROOMS_ROOM_CREATED';
export const API_PROPERTY_ROOMS_ROOM_UPDATED =
  'API_PROPERTY_ROOMS_ROOM_UPDATED';
export const API_PROPERTY_ROOM_OPTION_DELETED =
  'API_PROPERTY_ROOM_OPTION_DELETED';
export const API_PROPERTY_AMENITY_ADDED = 'API_PROPERTY_AMENITY_ADDED';
export const API_PROPERTY_AMENITY_DELETED = 'API_PROPERTY_AMENITY_DELETED';
export const API_PROPERTY_AMENITIES_LOADED = 'API_PROPERTY_AMENITIES_LOADED';
export const API_PROPERTY_AVAILABILITY_LOADING =
  'API_PROPERTY_AVAILABILITY_LOADING';
export const API_PROPERTY_AVAILABILITY_LOADED =
  'API_PROPERTY_AVAILABILITY_LOADED';

export const API_PROPERTY_CHILDAGES_LOADED = 'API_PROPERTY_CHILDAGES_LOADED';
export const API_HOTEL_ADDED = 'API_HOTEL_ADDED';

// Location
export const API_LOCTION_COUNTRIES_LOADED = 'API_LOCTION_COUNTRIES_LOADED';
export const API_LOCTION_CITIES_LOADED = 'API_LOCTION_CITIES_LOADED';
export const API_LOCTION_REGION_LOADED = 'API_LOCTION_REGION_LOADED';
export const API_LOCTION_SUBREGIONS_LOADED = 'API_LOCTION_SUBREGIONS_LOADED';
export const API_LOCTION_TOURISTIC_REGIONS_LOADED =
  'API_LOCTION_TOURISTIC_REGIONS_LOADED';

// Booking
export const API_BOOKINGS_FOR_PROPERTY = 'API_BOOKINGS_FOR_PROPERTY';
export const API_BOOKINGS_FOR_PROPERTY_DETAIL =
  'API_BOOKINGS_FOR_PROPERTY_DETAIL';
export const API_BOOKINGS_SET_SEARCH_PARAMS = 'API_BOOKINGS_SET_SEARCH_PARAMS';

export const UI_DATA_CHANNELMANAGER = 'UI_DATA_CHANNELMANAGER';
export const UI_DATA_ACCOMMODATION_TYPES = 'UI_DATA_ACCOMMODATION_TYPES';
export const UI_DATA_TAGS = 'UI_DATA_TAGS';
export const UI_DATA_AMENITY_TYPES = 'UI_DATA_AMENITY_TYPES';
export const UI_DATA_PROVIDER_TYPES = 'UI_DATA_PROVIDER_TYPES';

// Property-Bonus
export const API_PROPERTY_BONUS_LOADED = 'API_PROPERTY_BONUS_LOADED';

// export const LATEST_BOOKINGS_LOADED = 'LATEST_BOOKINGS_LOADED';
// export const HOTELS_LOADED = 'HOTELS_LOADED';

// Hotel Actions
// export const API_HOTEL_INFORMATION_PATCHED = 'API_HOTEL_INFORMATION_PATCHED';

export const API_UPCOMING_BOOKINGS_LOADED = 'API_UPCOMING_BOOKINGS_LOADED';

// Policies Actions
// Cancellation Group
export const API_PROPERTY_CANCELLATION_GROUP_ADDED =
  'API_PROPERTY_CANCELLATION_GROUP_ADDED';
export const API_PROPERTY_CANCELLATION_GROUP_DELETED =
  'API_PROPERTY_CANCELLATION_GROUP_DELETED';
export const API_PROPERTY_CANCELLATION_GROUP_UPDATED =
  'API_PROPERTY_CANCELLATION_GROUP_UPDATED';
// Cancellation
export const API_PROPERTY_CANCELLATION_ADDED =
  'API_PROPERTY_CANCELLATION_ADDED';
export const API_PROPERTY_CANCELLATION_DELETED =
  'API_PROPERTY_CANCELLATION_DELETED';
export const API_PROPERTY_CANCELLATION_UPDATED =
  'API_PROPERTY_CANCELLATION_UPDATED';
// Reservation Guaranty
export const API_PROPERTY_GUARANTY_ADDED = 'API_PROPERTY_GUARANTY_ADDED';
export const API_PROPERTY_GUARANTY_DELETED = 'API_PROPERTY_GUARANTY_DELETED';
export const API_PROPERTY_GUARANTY_UPDATED = 'API_PROPERTY_GUARANTY_UPDATED';
// Tax
export const API_PROPERTY_TAX_ADDED = 'API_PROPERTY_TAX_ADDED';
export const API_PROPERTY_TAX_DELETED = 'API_PROPERTY_TAX_DELETED';
export const API_PROPERTY_TAX_UPDATED = 'API_PROPERTY_TAX_UPDATED';
