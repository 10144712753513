import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationDialog from 'rb-toolset/lib/ui/ConfirmationDialog';

const RoomFormDialog = ({ handleDelete, handleDeleteRoomOption }) =>
  <div>
    <ConfirmationDialog
      id="room.delete"
      confirmText="Ja"
      cancelText="Nein"
      title="Zimmerkategorie löschen"
      onConfirm={handleDelete}
    >
      Wollen Sie diese Zimmerkategorie wirklich löschen?
    </ConfirmationDialog>
    <ConfirmationDialog
      id="roomOption.delete"
      confirmText="Ja"
      cancelText="Nein"
      title="Zimmerrate löschen"
      onConfirm={handleDeleteRoomOption}
    >
      Wollen Sie diese Zimmerrate wirklich löschen?
    </ConfirmationDialog>
  </div>;

RoomFormDialog.propTypes = {
  handleDelete: PropTypes.func,
  handleDeleteRoomOption: PropTypes.func
};

export default RoomFormDialog;
