import { createUseStyles } from 'react-jss';

import Colors from 'consts/Colors';

export default createUseStyles({
  booked: {
    backgroundColor: Colors.green
  },
  waiting: {
    backgroundColor: Colors.yellow
  },
  canceled: {
    backgroundColor: Colors.grey
  },
  cell: {
    display: 'flex',
    alignItems: 'center'
  }
});
