import React from 'react';
import { FormContainer } from 'rb-toolset/lib/ui/FormControls';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import PaperContainer from 'components/PaperContainer/PaperContainer';
import { Link } from 'react-router-dom';
import { CheckboxInput, TextInput } from 'rb-toolset/lib/ui/FormControls';
import { Unit, Column } from 'rb-toolset/lib/ui/Layout';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Property } from 'rb-domain';
import Button from '@material-ui/core/Button';

const TRUSTED_REQUEST_STATUSES = Property.TRUSTED_REQUEST_STATUSES;
const selector = formValueSelector('Partnership');

let PartnershipForm = ({ handleSubmit, invalid, property }) => {
  const roomCountValid = property.roomsCount && property.roomsCount > 0;
  const emailValid = property.cpEmail && property.cpEmail.length > 0;
  const hotelValid = !!(roomCountValid && emailValid);

  return (
    <div>
      {!Property.isTrustedPartner(property) && (
        <PaperContainer>
          <FormContainer invalid={invalid} title="Trusted Partner">
            {!roomCountValid && (
              <p>
                Bitte geben Sie zuerst die Gesamtzimmeranzahl unter{' '}
                <Link to={`/${property.id}/housing`}>Allgemeine Daten</Link> an.
              </p>
            )}
            {!emailValid && (
              <p>
                Bitte geben Sie zumindest die E-Mail Adresse unter{' '}
                <Link to={`/${property.id}/profile/contact`}>Kontakt</Link> an.
              </p>
            )}
            {hotelValid &&
              property.trustedPartnerRequestStatus ===
                TRUSTED_REQUEST_STATUSES.NONE && (
                <div>
                  <br />
                  <Field
                    component={CheckboxInput}
                    name="confirmTrusted"
                    label="Ich möchte ein TRUSTED MEMBERSHIP beantragen. Dieser Upgrade kommt erst nach
              Prüfung Ihrer Daten von ROOMBONUS zustande. Wir geben Ihnen sofort nach unserer
              internen Datenüberprüfung Bescheid."
                  />
                  <Field
                    component={CheckboxInput}
                    name="confirmToc"
                    label="Ja, ich akzeptiere die AGBs von ROOMBONUS."
                  />
                  <Field
                    component={TextInput}
                    name="comment"
                    label="Optionale Nachricht an ROOMBONUS"
                    fullWidth
                    multiline
                    rows={3}
                  />
                  <br />
                  <br />
                  <p>
                    Bitte beachten Sie, dass die{' '}
                    <Link to={`/${property.id}/profile/contact`}>
                      Rechnungsdaten
                    </Link>{' '}
                    ausgefüllt sein müssen.
                  </p>

                  <p>
                    Bitte entnehmen Sie weitere Informationen aus unserem{' '}
                    <a
                      href="https://www.roombonus.com/InfopagerHotelier-Apr-2016.pdf"
                      target="_blank"
                    >
                      Factsheet
                    </a>{' '}
                    und den{' '}
                    <a
                      href="https://www.roombonus.com/hotels/partner-agb"
                      target="_blank"
                    >
                      Allgemeinen Geschäftsbedingungen
                    </a>
                    .
                  </p>
                  <br />
                  <Unit>
                    <Column width={1 / 2}>
                      Anzahl der Zimmer: {property.roomsCount}
                    </Column>
                    <Column width={1 / 2} align="right">
                      <strong>
                        Ihre monatlichen Kosten € {property.roomsCount} netto
                      </strong>
                      <br />
                      <small>
                        Die Verrechnung erfolgt quartalsweise im Vorhinein.
                      </small>
                    </Column>
                  </Unit>
                  <br />
                  <div style={{ textAlign: 'center' }}>
                    <Button
                      variant="contained"
                      label="Jetzt upgraden"
                      type="submit"
                      onClick={handleSubmit}
                      disabled={invalid}
                    >
                      JETZT UPGRADEN
                    </Button>
                  </div>
                  <br />
                </div>
              )}
            {hotelValid &&
              property.trustedPartnerRequestStatus ===
                TRUSTED_REQUEST_STATUSES.REQUESTED && (
                <div>
                  <br />
                  <p>Ihre Anfrage wurde an uns weitergeleitet.</p>
                  <br />
                </div>
              )}
          </FormContainer>
        </PaperContainer>
      )}
      <PaperContainer>
        <FormContainer title="Partnermodell">
          <br />
          <p>
            {`Bitte beachten Sie, dass eine Darstellung als Premium Partner
              (inklusive Badgesymbol) im
              Rahmen einer Trusted Partnerschaft den speziellen Auswahlkriterien
              (Hotelkategorie,
              Destination, Hotelbewertungen, Servicequalität, etc.) von
              ROOMBONUS unterliegt.`}
          </p>
          <br />
          <FormControlLabel
            label="Premium Partner"
            control={
              <Checkbox disabled defaultChecked={property.premiumPartner} />
            }
          />
          <br />
          {!property.premiumPartner && (
            <FormControlLabel
              label="Trusted Partner"
              control={
                <Checkbox
                  disabled
                  defaultChecked={Property.isTrustedPartner(property)}
                />
              }
            />
          )}
          <br />
          <FormControlLabel
            label="Aktiviert"
            control={<Checkbox disabled defaultChecked={property.enabled} />}
          />
          <br />
          <FormControlLabel
            label="Geschäftsbedigungen akzeptiert"
            control={
              <Checkbox disabled defaultChecked={property.tocAccepted} />
            }
          />
        </FormContainer>
      </PaperContainer>
    </div>
  );
};

PartnershipForm.propTypes = {
  property: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired
};

PartnershipForm = reduxForm({
  form: 'Partnership',
  validate: values => {
    const errors = {};

    if (!values.confirmTrusted) {
      errors.confirmTrusted = 'required';
    }

    if (!values.confirmToc) {
      errors.confirmToc = 'required';
    }

    return errors;
  }
})(PartnershipForm);

export default connect(state => ({
  formValues: selector(state, 'confirmToc', 'confirmTrusted') || {}
}))(PartnershipForm);
