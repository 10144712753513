/* eslint-disable camelcase */
const CSRF_TOKEN_COOKIE_NAME = 'csrfToken';

export function createCSRFToken({ cookies }) {
  const token = `cx-${new Date().getTime()}`;

  cookies.set(CSRF_TOKEN_COOKIE_NAME, token, { path: '/' });

  return { token };
}

export function resetCSRFToken({ cookies }) {
  cookies.remove(CSRF_TOKEN_COOKIE_NAME);
}

export function checkParamsAndToken({ path, input: { query }, cookies }) {
  const reduxToken = cookies.get(CSRF_TOKEN_COOKIE_NAME);

  const { state, error, error_description } = query;

  if (error && error_description) {
    return path.error({
      error: {
        error,
        description: error_description
      }
    });
  }

  if (state && state === reduxToken) {
    return path.success();
  }

  return path.xsrf({});
}

export function saveToken({ input: { query, propertyId }, api }) {
  return api().entity('property/connectStripe').post({
    propertyId,
    code: query.code
  });
}

export function resetToken({ input: { propertyId }, api }) {
  return api().entity('property/revokeStripe').post({
    propertyId
  });
}
