import { Property } from 'rb-domain';

const mapCaluclatBase = calculationBase => {
  switch (calculationBase) {
    case Property.Policy.POLICY_CALCULATION_BASE.FIXED:
      return 'Festbetrag';
    case Property.Policy.POLICY_CALCULATION_BASE.FIXED_PER_PERSON_PER_NIGHT:
      return 'Festbetrag pro Nacht/Person';
    case Property.Policy.POLICY_CALCULATION_BASE.PERCENT_OF_TOTAL_COST:
      return 'Prozent von Gesamtpreis';
    case Property.Policy.POLICY_CALCULATION_BASE.PERCENT_OF_TOTAL_NIGHTS:
      return 'Prozent von Gesamtnächten';
    default:
      return '';
  }
};

//only enabling percentage of total cost until extranet is rebuild
export const calculationBasesOptions = Object.keys(
  Property.Policy.POLICY_CALCULATION_BASE
)
  .filter(key => key === 'PERCENT_OF_TOTAL_COST')
  .map(key => {
    return {
      value: Property.Policy.POLICY_CALCULATION_BASE[key],
      label: mapCaluclatBase(Property.Policy.POLICY_CALCULATION_BASE[key])
    };
  });

export const getFieldNames = name => ({
  calculationAmountsName: name
    ? `${name}.calculationAmounts`
    : `calculationAmounts`,
  calculationBasesName: name ? `${name}.calculationBases` : 'calculationBases'
});
