import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog } from '@material-ui/core';

import Login from 'pages/Auth/Login/Login';
import LogoutWarning from 'components/LogoutWarning/LogoutWarning';

import useStyles from './ScaffoldStyles';

const ScaffoldDialogs = ({
  modalLogin,
  modalLogoutWarning,
  noHotelsAssigned
}) => {
  const styles = useStyles();

  return (
    <React.Fragment>
      <Dialog open={modalLogin}>
        <p>
          <strong>Sie wurden aus Sicherheitsgründen abgemeldet.</strong>
        </p>
        <Login asModal />
      </Dialog>
      <Dialog open={modalLogoutWarning}>
        <LogoutWarning seconds={60} />
      </Dialog>
      <Dialog open={noHotelsAssigned}>
        <p className={styles.center}>
          <strong>Ihrem Account wurden keine Hotels zugewiesen.</strong>
          <br />
          <br />
          {/*
            <Link to="/addProperty">
                <Button variant="contained" primary className={styles.addHotel}>
                  Hotel anlegen
                </Button>
            </Link>
            <Link to="/logout">
              <Button variant="contained" primary className={styles.menuButton}>
                Abmelden
              </Button>
            </Link>
            */}
        </p>
      </Dialog>
    </React.Fragment>
  );
};

ScaffoldDialogs.propTypes = {
  modalLogin: PropTypes.bool,
  modalLogoutWarning: PropTypes.bool,
  noHotelsAssigned: PropTypes.bool
};

export default connect(state => ({
  modalLogin: state.ui.modalLogin,
  modalLogoutWarning: state.ui.modalLogoutWarning,
  noHotelsAssigned: state.ui.noHotelsAssigned
}))(ScaffoldDialogs);
