import { createQuery } from 'utils/url';
import * as actions from '../actionTypes';
import { aggregatePrices } from './property-room-option';
import { keyBy } from 'lodash';
import { splitPrices } from './property-room-option';

export function getAvailability(params, occupancyMax) {
  return (dispatch, getState, { api }) => {
    dispatch({
      type: actions.API_PROPERTY_AVAILABILITY_LOADING
    });

    // return api().entity(`property/list?${createQuery(params)}`).get().then(result => {
    return api()
      .entity(`property-room-availability/list?${createQuery(params)}`)
      .get()
      .then(result => {
        const days = Object.values(result.days).reduce((acc, day) => {
          return {
            ...acc,
            [day.day]: {
              ...day,
              prices: keyBy(
                day.prices
                  ? day.prices.map(price => ({
                      ...price,
                      // Ignore values for rooms more than occupancyMax
                      prices: splitPrices(price).prices.slice(0, occupancyMax)
                    }))
                  : {},
                'propertyRoomOptionId'
              )
            }
          };
        }, {});

        dispatch({
          type: actions.API_PROPERTY_AVAILABILITY_LOADED,
          payload: days
        });
      });
  };
}

export function setAvailability(params) {
  return (dispatch, getState, { api }) => {
    return api().entity(`property-room-availability/set`).put({
      ...params,
      ...aggregatePrices(params).prices
    });
  };
}

export function resetToDefaultPrices(params) {
  return (dispatch, getState, { api }) => {
    return api()
      .entity(`property-room-availability/resetToDefaultPrices`)
      .post({
        ...params
      });
  };
}
