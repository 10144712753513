import * as actions from 'actions/actionTypes';
import u from 'updeep';

const initialState = {};

export default function roomsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case actions.API_PROPERTY_ROOMS_LOADED:
      return {
        ...state,
        availableRooms: action.payload
      };
    case actions.API_PROPERTY_ROOM_OPTION_DELETED:
      return u(
        {
          availableRooms: {
            [action.payload.roomId]: {
              options: u.omit(action.payload.roomOptionId)
            }
          }
        },
        state
      );
    default:
      return state;
  }
}
