import PropTypes from 'prop-types';
import React from 'react';
import { VictoryGroup, VictoryBar, VictoryChart } from 'victory';
import { pink, blue, amber, green } from '@material-ui/core/colors';
import clientRender from './ClientRender';

const colorScale = [pink['800'], blue['800'], amber['800'], green['800']];

const BarChart = ({ data }) => {
  return (
    <VictoryChart offset={15}>
      <VictoryGroup height={500} offset={20} colorScale={colorScale}>
        <VictoryBar
          data={data}
          animate={{
            duration: 1500
          }}
        />
      </VictoryGroup>
    </VictoryChart>
  );
};

BarChart.propTypes = {
  data: PropTypes.array.isRequired
};

export default clientRender()(BarChart);
