import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PaperContainer from 'components/PaperContainer/PaperContainer';
import HelpboxContainer from 'components/HelpboxContainer/HelpboxContainer';

import { createCSRFToken$, deAuthStripe$ } from './redux/signals';
import StripeForm from './StripeForm/StripeForm';

const Payment = ({
  csrfToken,
  createCSRFTokenSignal,
  deAuthStripeSignal,
  property
}) => {
  React.useEffect(() => {
    createCSRFTokenSignal();
  }, []);

  const onDeAuth = React.useCallback(() => {
    deAuthStripeSignal({
      propertyId: property.id,
      data: {
        stripeUserId: null
      },
      fieldsToInject: ['stripeUserId']
    });
  });

  return (
    <PaperContainer
      helpBox={[
        <HelpboxContainer>
          {`Bitte geben Sie uns die Bankdaten bekannt, an die wir im Falle eines
            "No-Shows" des Gastes die Gebühren überweisen können.`}
        </HelpboxContainer>,
        <HelpboxContainer>
          {`Im Falle eines "No-Shows" können Sie in den Buchungsdetails die
            Summe für die Buchung einfordern. Klicken Sie hierzu auf "Buchungen"
            und dann auf die jeweilige Buchung. Danach wählen Sie je nach
            Verfügbarkeit "Kreditkartendetails einblenden" oder "Überweisung
            anfordern".`}
        </HelpboxContainer>
      ]}
    >
      <StripeForm
        csrfToken={csrfToken}
        onDeAuth={onDeAuth}
        property={property}
      />
    </PaperContainer>
  );
};

Payment.propTypes = {
  createCSRFTokenSignal: PropTypes.func.isRequired,
  csrfToken: PropTypes.string,
  deAuthStripeSignal: PropTypes.func.isRequired,
  property: PropTypes.object.isRequired
};

export default connect(
  state => ({
    csrfToken: state.payment.csrfToken,
    property: state.property
  }),
  {
    createCSRFTokenSignal: createCSRFToken$,
    deAuthStripeSignal: deAuthStripe$
  }
)(Payment);
