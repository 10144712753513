import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import {
  TextInput,
  WYSIWYGEditor,
  TimePickerInput
} from 'rb-toolset/lib/ui/FormControls';
import { Unit, Column } from 'rb-toolset/lib/ui/Layout';
import { Delete } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

import Calculations from 'components/Calculations/Calculations';

import { calculationBasesOptions } from './CancellationFormUtils';

const validateName = value =>
  !value ? 'Bitte geben Sie einen Namen an' : null;
const validateDaysPriorArrival = value =>
  !value ? 'Bitte geben Sie einen Wert an' : null;

const parseDaysPriorArrival = value => value && parseInt(value, 10);

const CancellationForm = ({
  name,
  onDeleteCancellationOption,
  array,
  values
}) =>
  <div>
    <Unit>
      <Column width={1 / 2}>
        <Field
          component={TextInput}
          name={`${name}.name`}
          label="Name"
          validate={validateName}
        />
      </Column>
      <Column width={1 / 2} align="right">
        <IconButton onClick={onDeleteCancellationOption}>
          <Delete />
        </IconButton>
      </Column>
    </Unit>
    <Field
      component={TextInput}
      type="number"
      step="any"
      parse={parseDaysPriorArrival}
      name={`${name}.daysPriorArrival`}
      validate={validateDaysPriorArrival}
      label="Tage vor Ankunft"
    />
    {/* <Field
      component={TimePickerInput}
      name={`${name}.timePriorArrival`}
      label="Ankunftszeit"
    /> */}
    <br />
    <Calculations
      {...{ array, calculationBasesOptions, name }}
      formValues={values}
    />
    <p>
      <strong>Beschreibung (optional):</strong>
    </p>
    <Field
      component={WYSIWYGEditor}
      height={150}
      name={`${name}.description`}
      placeholder="Beschreiben Sie die Stornierungsbedingung Ihres Hotels"
    />
  </div>;

CancellationForm.propTypes = {
  array: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onDeleteCancellationOption: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired
};

export default CancellationForm;
