import React, { Component } from 'react';
import { connect } from 'react-redux';
import { asyncConnect } from 'redux-connect';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Const } from 'rb-domain';
import { pickBy } from 'lodash';
import { push } from 'connected-react-router';
import { ChannelManager } from 'rb-domain';
import { loadRoom } from 'signals/room';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';

import {
  getAvailability,
  setAvailability,
  resetToDefaultPrices
} from 'actions/property/property-room-availability';

import { saveProperty } from 'signals/property';

import AvailabilityScreen from './AvailabilityScreen/AvailabilityScreen';

@asyncConnect([
  {
    promise: options => {
      const { store: { dispatch } } = options;

      const propertyId = parseInt(options.match.params.propertyId, 10);
      const promises = [];

      // promises.push(dispatch(getAvailability()));
      promises.push(dispatch(loadRoom({ propertyId })));
      return Promise.all(promises);
    }
  }
])
@connect(
  state => ({
    rooms: pickBy(
      state.rooms.availableRooms,
      room => room.options && Object.keys(room.options).length > 0
    ),
    availability: state.availability.currentAvailability,
    loading: state.availability.loading,
    propertyId: parseInt(state.property.id, 10),
    property: state.property
  }),
  { getAvailability, setAvailability, saveProperty, push, resetToDefaultPrices }
)
export default class Availability extends Component {
  static propTypes = {
    rooms: PropTypes.object.isRequired,
    availability: PropTypes.object,
    getAvailability: PropTypes.func.isRequired,
    setAvailability: PropTypes.func.isRequired,
    resetToDefaultPrices: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    propertyId: PropTypes.number.isRequired,
    property: PropTypes.object.isRequired,
    saveProperty: PropTypes.func.isRequired
  };

  static defaultProps = {
    availability: {}
    // rooms: {}
  };

  constructor(props) {
    super(props);
    const isScreenLocked = Object.keys(props.rooms).length === 0;
    const defaultRoom = !isScreenLocked ? Object.values(props.rooms)[0] : {};
    const defaultRoomOption = !isScreenLocked
      ? Object.values(defaultRoom.options)[0]
      : {};

    this.state = {
      isScreenLocked,
      // dateRange: {
      //   from: null,
      //   to: null
      // },
      selectedRoom: defaultRoom,
      selectedRoomOption: defaultRoomOption,
      searchParams: {
        propertyRoomId: defaultRoom.id,
        ...this.getCalendarBoundaries(moment())
      }
    };

    this.triggerSearch = this.triggerSearch.bind(this);
  }

  onMonthChanged = (date, selectedRoom) => {
    const momentDate = moment(date);
    this.triggerSearch(
      {
        ...this.getCalendarBoundaries(momentDate)
      },
      selectedRoom
    );
  };

  onSubmitAvailability = formData => {
    const { from, to, availableCount, selectedRoomId } = formData;
    const { rooms } = this.props;

    this.props
      .setAvailability({
        availableCount,
        from: moment(from).format(Const.DAY_PATTERN),
        to: moment(to).format(Const.DAY_PATTERN),
        propertyRoomId: selectedRoomId
      })
      .then(() => {
        this.triggerSearch({}, rooms[selectedRoomId]);
      });

    this.checkChannelManager();
  };

  onSubmitPrices = formData => {
    const {
      from,
      to,
      blocked,
      prices,
      minimumDaysToStay,
      selectedRoomId,
      selectedRoomOptionId
    } = formData;

    const { rooms } = this.props;

    this.props
      .setAvailability({
        blocked,
        prices,
        minimumDaysToStay,
        from: moment(from).format(Const.DAY_PATTERN),
        to: moment(to).format(Const.DAY_PATTERN),
        propertyRoomId: selectedRoomId,
        propertyRoomOptionId: selectedRoomOptionId
      })
      .then(() => {
        this.triggerSearch({}, rooms[selectedRoomId]);
      });

    this.checkChannelManager();
  };

  onResetToDefaultPrices = formData => {
    const { from, to, selectedRoomOptionId, selectedRoomId } = formData;
    const { rooms } = this.props;

    return this.props
      .resetToDefaultPrices({
        from: moment(from).format(Const.DAY_PATTERN),
        to: moment(to).format(Const.DAY_PATTERN),
        propertyRoomOptionId: selectedRoomOptionId
      })
      .then(() => {
        this.triggerSearch({}, rooms[selectedRoomId]);
      });
  };

  getCalendarBoundaries = momentDate => {
    return {
      from: moment(momentDate)
        .startOf('month')
        .startOf('week')
        .format(Const.DAY_PATTERN),
      to: moment(momentDate)
        .endOf('month')
        .endOf('week')
        .format(Const.DAY_PATTERN)
    };
  };

  checkChannelManager = () => {
    const { property, propertyId } = this.props;

    // Automatically set channel manager to ROOMBONUS if non have been set
    if (property.channelManagerCode === null) {
      this.props.saveProperty({
        propertyId,
        data: {
          channelManagerCode: ChannelManager.CODES.ROOMBONUS
        },
        fieldsToInject: ['channelManagerCode']
      });
    }
  };

  triggerSearch = (params, selectedRoom) => {
    const { searchParams } = this.state;
    const newSearchParams = {
      ...searchParams,
      ...params
    };

    this.setState({
      searchParams: newSearchParams
    });

    this.props.getAvailability(newSearchParams, selectedRoom.occupancyMax);
  };

  render() {
    const { rooms, availability, loading, property } = this.props;
    const { selectedRoom, selectedRoomOption, isScreenLocked } = this.state;

    if (isScreenLocked) {
      return (
        <Dialog open>
          <DialogTitle>Hinweis</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ihr Hotel hat derzeit noch keine Zimmerkategorien und/oder keine
              Ratenpläne.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              label="Zimmer jetzt konfigurieren"
              primary
              onClick={() =>
                this.props.push(`/${this.props.propertyId}/housing/rooms`)}
            >
              Zimmer jetzt konfigurieren
            </Button>
          </DialogActions>
        </Dialog>
      );
    }

    return (
      <AvailabilityScreen
        availability={availability}
        loading={loading}
        rooms={rooms}
        weekendDefinition={property.weekendDefinition}
        triggerSearch={this.triggerSearch}
        onSubmitAvailability={this.onSubmitAvailability}
        onSubmitPrices={this.onSubmitPrices}
        onResetToDefaultPrices={this.onResetToDefaultPrices}
        onMonthChanged={this.onMonthChanged}
        initialValues={{
          selectedRoomId: selectedRoom.id,
          selectedRoomOptionId: selectedRoomOption.id
        }}
      />
    );
  }
}
