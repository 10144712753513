import * as actions from '../actionTypes';

export function getAllCountries() {
  return (dispatch, getState, { api }) => {
    return api().entity(`country`).get('list').then(result => {
      dispatch({
        type: actions.API_LOCTION_COUNTRIES_LOADED,
        payload: result.items
      });
    });
  };
}

export function getRegions(countryId) {
  return (dispatch, getState, { api }) => {
    return api()
      .entity(`regionpolitical/list?countryId=${countryId}`)
      .get()
      .then(result => {
        dispatch({
          type: actions.API_LOCTION_REGION_LOADED,
          payload: result.items
        });
      });
  };
}

export function getCities(countryId, regionPoliticalId) {
  return (dispatch, getState, { api }) => {
    return api()
      .entity(
        `city/list?countryId=${countryId}&regionPoliticalId=${regionPoliticalId}`
      )
      .get()
      .then(result => {
        dispatch({
          type: actions.API_LOCTION_CITIES_LOADED,
          payload: result.items
        });
      });
  };
}

export function getSubRegions(regionPoliticalId) {
  return (dispatch, getState, { api }) => {
    return api()
      .entity(`regionsub/list?regionPoliticalId=${regionPoliticalId}`)
      .get()
      .then(result => {
        dispatch({
          type: actions.API_LOCTION_SUBREGIONS_LOADED,
          payload: result.items
        });
      });
  };
}

export function getTouristicRegions(regionPoliticalId) {
  return (dispatch, getState, { api }) => {
    return api()
      .entity(`regiontouristic/list?regionPoliticalId=${regionPoliticalId}`)
      .get()
      .then(result => {
        dispatch({
          type: actions.API_LOCTION_TOURISTIC_REGIONS_LOADED,
          payload: result.items
        });
      });
  };
}
