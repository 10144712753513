import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PaperContainer from 'components/PaperContainer/PaperContainer';
import HelpboxContainer from 'components/HelpboxContainer/HelpboxContainer';

import CancellationGroups from './PoliciesCancellationGroups';
import Taxes from './PoliciesTaxes';
import Reservations from './PoliciesReservations';

const Policies = ({ propertyId }) =>
  <PaperContainer
    helpBox={[
      <HelpboxContainer headline="Geschäftsbedingungen">
        <p>
          In diesem Menü können Sie Ihre Stornierungsrichtlinien,
          Buchungsgarantien, zusätzliche Gebühren
          wie Endreinigung oder anfallende Taxen festlegen. Eine kleine
          Vorauswahl wird Ihnen je Menü
          bereits angezeigt.
        </p>
        <p>
          <strong>WICHTIG:</strong>
          <br />
          Verknüpfen Sie individuell je nach Ratentyp Ihre eingegebenen Daten
          aus diesem Menü mit einem
          Raten-/Zimmertyp aus dem Menü <strong>„Zimmerkategorien“</strong>.
        </p>
        <p>
          <strong>Als Beispiel</strong> – Sie verfügen über eine nicht
          erstattbare Frühbucher Rate: Unter
          Stornierungsrichtlinien legen Sie eine Richtlinie mit „Nicht
          erstattbar – 90%
          Stornierungskosten“ an, unter Buchungsgarantien eine Garantie mit
          Kreditkarte oder 100% Deposit
          und unter dem Punkt Endreinigung/Taxen fügen Sie noch Ihre Kurtaxe
          ein, da es sich bei Ihrem
          Haus um ein Hotel in einem Kurort handelt. Danach verknüpfen Sie
          einfach Ihren Raten-/Zimmertyp
          unter dem Menü „Zimmerkategorien“ mit den einzelnen
          Richtlinien/Garantien.
        </p>
      </HelpboxContainer>
    ]}
  >
    <CancellationGroups {...{ propertyId }} />
    <Reservations {...{ propertyId }} />
    <Taxes {...{ propertyId }} />
  </PaperContainer>;

Policies.propTypes = {
  propertyId: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
  propertyId: parseInt(state.property.id, 10)
});

export default connect(mapStateToProps)(Policies);
