import { stringify, parse } from 'query-string';
import { reduce, isObject } from 'lodash';

export function createQuery(params) {
  return Object.keys(params || {})
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&');
}

export function encodeQueryParams(params) {
  return `?${stringify(
    reduce(
      params,
      (acc, value, key) => ({
        ...acc,
        // Handle nested objects
        [key]: isObject(value) ? JSON.stringify(value) : value
      }),
      {}
    )
  )}`;
}

export function decodeQueryParams(queryString) {
  return reduce(
    parse(queryString),
    (acc, value, key) => {
      let parsedValue = value;

      try {
        parsedValue = JSON.parse(value);
      } catch (e) {
        console.log(e);
      }

      return {
        ...acc,
        [key]: parsedValue
      };
    },
    {}
  );
}
