import { signal } from 'rb-toolset/lib/signal';
import {
  dispatchInput,
  dispatch,
  notify,
  goTo
} from 'rb-toolset/lib/signal/factories';

import { NOTIFICATION_TYPES } from 'rb-toolset/lib/redux/notification/actions';

import {
  createCSRFToken,
  checkParamsAndToken,
  resetCSRFToken,
  saveToken,
  resetToken
} from './actions';

import {
  APP_PAYMENT_SET_XSRF_TOKEN,
  APP_PAYMENT_AUTHENTICATING,
  APP_PAYMENT_AUTHENTICATING_INIT,
  APP_PAYMENT_AUTHENTICATING_ERROR,
  APP_PAYMENT_AUTHENTICATING_SUCCESS
} from './types';

export const createCSRFToken$ = signal([
  createCSRFToken,
  dispatchInput(APP_PAYMENT_SET_XSRF_TOKEN, 'token')
]);

export const authStripe$ = signal([
  dispatch(APP_PAYMENT_AUTHENTICATING_INIT, true),
  checkParamsAndToken,
  {
    success: [
      saveToken,
      dispatch(APP_PAYMENT_AUTHENTICATING_SUCCESS, true),
      notify('Account wurde verknüpft', NOTIFICATION_TYPES.INFO)
    ],
    error: [
      dispatch(APP_PAYMENT_AUTHENTICATING_SUCCESS, false),
      dispatchInput(APP_PAYMENT_AUTHENTICATING_ERROR, 'error')
    ],
    xsrf: [dispatch(APP_PAYMENT_AUTHENTICATING_SUCCESS, false)]
  },
  resetCSRFToken,
  dispatch(APP_PAYMENT_AUTHENTICATING, false)
]);

export const deAuthStripe$ = signal([
  resetToken,
  notify('Die Verknüpfung wurde aufgehoben', NOTIFICATION_TYPES.INFO),
  goTo(({ propertyId }) => `${propertyId}/payment`)
]);
