import { createUseStyles } from 'react-jss';

export default createUseStyles({
  categorySelect: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: '24px',
    top: '50%',
    transform: 'translateY(-50%)',
    '& > div': { margin: 0 }
  }
});
