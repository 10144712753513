import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { mapValues, mapKeys } from 'lodash';
import { FormContainer, MultiList } from 'rb-toolset/lib/ui/FormControls';

import useStyles from './AmenitiesFormStyles';

let AmenitiesForm = ({ handleSubmit, amenities }) => {
  const styles = useStyles();

  return (
    <FormContainer
      classes={{
        container: styles.container,
        formContainer: styles.formContainer
      }}
      title="Ausstattung"
      {...{ handleSubmit }}
    >
      {amenities.map(amenitySlice => {
        const amTypes = amenitySlice.items.map(type => ({
          value: type.id,
          label: type.name
        }));
        return (
          <div key={amenitySlice.id}>
            <Field
              component={MultiList}
              itemsPerRow={2}
              items={amTypes}
              heading={amenitySlice.name}
              name="amenities"
            />
          </div>
        );
      })}
    </FormContainer>
  );
};

AmenitiesForm.propTypes = {
  amenities: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

AmenitiesForm = reduxForm({
  form: 'Amenities'
})(AmenitiesForm);

const mapStateToProps = state => ({
  amenities: state.ui.amenityTypes,
  initialValues: {
    amenities: mapValues(
      mapKeys(state.property.amenities, 'amenityId'),
      () => true
    )
  }
});

export default connect(mapStateToProps)(AmenitiesForm);
