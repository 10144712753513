export function aggregatePrices(option) {
  const prices = option.prices
    ? option.prices.reduce((acc, price, priceIndex) => {
        return {
          ...acc,
          [`price${priceIndex + 1}`]: price
        };
      }, {})
    : {};

  const pricesWeekend = option.pricesWeekend
    ? option.pricesWeekend.reduce((acc, price, priceIndex) => {
        return {
          ...acc,
          [`price${priceIndex + 1}Weekend`]: price
        };
      }, {})
    : {};

  return { prices, pricesWeekend };
}

export function splitPrices(option) {
  const prices = [];
  let cnt = 1;
  while (option[`price${cnt}`]) {
    prices.push(option[`price${cnt}`]);
    cnt++;
  }

  const pricesWeekend = [];
  cnt = 1;
  while (option[`price${cnt}Weekend`]) {
    pricesWeekend.push(option[`price${cnt}Weekend`]);
    cnt++;
  }

  return {
    prices,
    pricesWeekend
  };
}

export function updateRoomOption(option, api) {
  const { prices, pricesWeekend } = aggregatePrices(option);

  const patchedOption = {
    ...option,
    ...prices,
    ...pricesWeekend
  };

  if (option.id) {
    return api().entity('property-room-option').patch(patchedOption);
  }

  return api().entity('property-room-option').post(patchedOption);
}

export function updateRoomOptionsAction({ input: { roomData, options }, api }) {
  return Promise.all(
    options.map((option, index) => {
      return updateRoomOption(
        {
          propertyRoomId: roomData.propertyRoomId,
          position: index,
          pricesWeekend: option.weekend
            ? option.pricesWeekend
            : option.pricesWeekend.fill(null),
          ...option
        },
        api
      );
    })
  ).then(() => ({}));
  // For now we have to return empty object when calling Promise.all in function-tree
}

export function deleteRoomOptionAction({ input: { roomOptionId }, api }) {
  return api()
    .entity('property-room-option')
    .delete({
      id: roomOptionId
    })
    .then(room => ({ room }));
}
