import { createUseStyles } from 'react-jss';

export default createUseStyles({
  container: {
    width: '500px',
    margin: '20% auto'
  },
  toolbar: {
    textAlign: 'right'
  },
  textField: {
    margin: '0 !important',
    '& input': {
      height: '48px',
      padding: '0 20px',
      fontSize: '16px'
    },
    '& .MuiFormHelperText-root': {
      padding: '0 20px',
      marginBottom: 8
    }
  },
  autocompleteInput: {
    height: '48px',
    padding: '0 20px',
    fontSize: '16px'
  }
});
