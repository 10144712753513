import { getPropertyById } from 'actions/property/property';
import { asyncConnect } from 'redux-connect';
import { renderRoutes } from 'react-router-config';

export default asyncConnect([
  {
    promise: ({ store: { dispatch }, match: { params } }) => {
      return dispatch(getPropertyById(params.propertyId));
    }
  }
])(({ route }) => renderRoutes(route.routes));
