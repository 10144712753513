function addGuaranty(
  {
    name,
    description,
    paymentType,
    calculationAmounts,
    calculationBases,
    propertyId,
    isDefault
  },
  api
) {
  return api().entity('policy-guarantee').post({
    name,
    description,
    paymentType: paymentType,
    calculationAmounts: calculationAmounts,
    calculationBases: calculationBases,
    propertyId,
    isDefault
  });
}

function updateGuaranty(data, api) {
  return api().entity(`policy-guarantee`).patch(data);
}
export function deleteGuarantyAction({ input: { id }, api }) {
  return api().entity('policy-guarantee').delete({
    id
  });
}

export function updateGuaranteeAction({ input: { guarantee }, api }) {
  return guarantee.id
    ? updateGuaranty(guarantee, api)
    : addGuaranty(guarantee, api);
}
