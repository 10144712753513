import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { saveProperty } from 'signals/property';
import PaperContainer from 'components/PaperContainer/PaperContainer';

import BonusSettingsForm from './BonusSettingsForm';

const BonusSettings = ({ propertyId = 0, savePropertySignal }) => {
  const onSubmit = React.useCallback(data =>
    savePropertySignal({
      propertyId,
      data
    })
  );

  return (
    <PaperContainer>
      <BonusSettingsForm {...{ onSubmit }} />
    </PaperContainer>
  );
};

BonusSettings.propTypes = {
  propertyId: PropTypes.number.isRequired,
  savePropertySignal: PropTypes.func.isRequired
};

const mapStateToProps = (state, { match: { params: { propertyId } } }) => ({
  propertyId: parseInt(propertyId, 10)
});

const mapDispatchToProps = { savePropertySignal: saveProperty };

export default connect(mapStateToProps, mapDispatchToProps)(BonusSettings);
