import { signal } from 'rb-toolset/lib/signal';

import { notify, goTo } from 'rb-toolset/lib/signal/factories';

import {
  updateGuaranteeAction,
  deleteGuarantyAction
} from 'actions/property/property-guaranty';

export const updateGuarantee = signal([
  updateGuaranteeAction,
  notify('Buchungsgarantie wurde gespeichert'),
  goTo(({ propertyId }) => `/${propertyId}/housing/policies`)
]);

export const deleteGuarantee = signal([
  deleteGuarantyAction,
  notify('Buchungsgarantie wurde gelöscht'),
  goTo(({ propertyId }) => `/${propertyId}/housing/policies`)
]);
