import { createUseStyles } from 'react-jss';

import Colors from 'consts/Colors';

export default createUseStyles({
  optionContainer: {
    marginTop: '25px',
    position: 'relative'
  },
  option: {
    boxShadow: 'none !important',
    borderTop: `1px solid ${Colors.grey2}`,
    borderRadius: '0 !important',

    '& .MuiCardHeader-action': {
      margin: '0 !important'
    }
  },
  optionHeader: {
    backgroundColor: `${Colors.green2} !important`
  },
  optionBody: {
    paddingTop: '0 !important'
  },
  formContent: {
    padding: '5px 20px'
  },
  optionAdd: {
    position: 'absolute',
    right: '10px',
    top: 0,
    zIndex: 2
  },
  hidden: {
    display: 'none'
  }
});
