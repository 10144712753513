import { signal } from 'rb-toolset/lib/signal';

import { dispatchInput, notify } from 'rb-toolset/lib/signal/factories';

import {
  updateRoomAction,
  getAllRoomsForPropertyAction,
  deleteRoomAction
} from 'actions/property/property-room';
import {
  updateRoomOptionsAction,
  deleteRoomOptionAction
} from 'actions/property/property-room-option';
import * as actions from 'actions/actionTypes';

const loadRoomSignal = [
  getAllRoomsForPropertyAction,
  dispatchInput(actions.API_PROPERTY_ROOMS_LOADED, 'rooms')
];

export const loadRoom = signal([...loadRoomSignal]);

export const updateRoom = signal([
  updateRoomAction,
  updateRoomOptionsAction,
  notify('Zimmer wurde gespeichert'),
  ...loadRoomSignal
]);

export const deleteRoomOption = signal([
  deleteRoomOptionAction,
  dispatchInput(actions.API_PROPERTY_ROOM_OPTION_DELETED, 'room'),
  notify('Raumrate wurde gelöscht')
]);

export const deleteRoom = signal([
  deleteRoomAction,
  notify('Raumkategorie wurde gelöscht')
]);
