import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { FlashOn } from '@material-ui/icons';
import yellow from '@material-ui/core/colors/yellow';
import { Unit, Column } from 'rb-toolset/lib/ui/Layout';
import { User } from 'rb-domain';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const DetailSidebar = ({ selectedReservation, closeDetails }) => {
  const userData = selectedReservation.booking.bookingUserDataFinal;
  // const backLink = `/${propertyId}/bookings`;
  const booking = selectedReservation.booking;
  const directBooking = booking.bookingOffers.reverse()[0];
  const departureMoment = moment(directBooking.departure);
  const arrivalMoment = moment(directBooking.arrival);
  const rate =
    (directBooking.offerRooms[0] && directBooking.offerRooms[0].rateText) || '';
  const nights = moment(departureMoment).diff(arrivalMoment, 'days');
  const tax = (booking.fees[0] && booking.fees[0].amount) || null;

  return (
    <Card>
      <CardHeader
        title={
          <span>
            Buchung: {booking.bookingNumber}
            <FlashOn style={{ color: yellow.A700 }} />
          </span>
        }
        subheader={
          <div>
            {`${userData.gender === 'MALE' ? 'Herr' : 'Frau'}${userData.title
              ? ` ${User.getTitleName(userData.title)}`
              : ''} ${userData.firstName} ${userData.lastName} - ${userData.email}`}
            <br />
            {userData.street &&
              <div>
                {userData.street}, {userData.zip} {userData.city},{' '}
                {userData.country && userData.country.name}
                <br />
                Tel: {userData.telephone}
                <br />
              </div>}
          </div>
        }
        action={
          <IconButton onClick={() => closeDetails()} aria-label="settings">
            <CloseIcon />
          </IconButton>
        }
      />
      <CardContent>
        <Unit>
          <Column width={1 / 4}>Anreisedatum:</Column>
          <Column width={3 / 4}>
            {arrivalMoment.format('dddd, DD.MM.YYYY')}
          </Column>
        </Unit>
        <Unit>
          <Column width={1 / 4}>Abreisedatum:</Column>
          <Column width={3 / 4}>
            {departureMoment.format('dddd, DD.MM.YYYY')}
          </Column>
        </Unit>
        <Unit>
          <Column width={1 / 4}>Reisedauer:</Column>
          <Column width={3 / 4}>
            {departureMoment.diff(arrivalMoment, 'days')} Nächte
          </Column>
        </Unit>
        <Unit>
          <Column width={1 / 4}>Gäste:</Column>
          <Column width={3 / 4}>
            {directBooking.adults} Erwachsene <br />
            {directBooking.children &&
              <span>
                {directBooking.children} Kinder (Alter:{' '}
                {directBooking.children.map(child => child.age).join(', ')}
                Jahre)
              </span>}
          </Column>
        </Unit>
        <br />
        <Unit>
          <Column width={1 / 4}>
            <strong>
              {`${rate} Rate, ${nights} ${nights === 1 ? 'Night' : 'Nights'}`}:

            </strong>
          </Column>
          <Column width={3 / 4} align="right">
            <strong>
              {booking.netPrice ? `€ ${booking.netPrice}` : ''}
            </strong>

          </Column>
        </Unit>
        <Unit>

          <Column width={1 / 4}>
            <strong>

              Ortstaxe

            </strong>
          </Column>
          <Column align="right" width={3 / 4}>
            <strong>

              {tax ? `€ ${tax}` : ''}
            </strong>
          </Column>
        </Unit>
        <Unit>

          <Column width={1 / 4}>
            <strong>

              Total
            </strong>
          </Column>
          <Column align="right" width={3 / 4}>
            <strong>

              {`€ ${directBooking.price}`}
            </strong>
          </Column>
        </Unit>
        <Unit>
          <Column width={1 / 4}>Deposit is:</Column>
          <Column width={3 / 4}>
            <strong> {booking.isDepositPaid ? 'PAID' : 'NOT PAID'}</strong>
          </Column>
        </Unit>
        <Unit>
          <Column width={1 / 4}>Full Amount is :</Column>
          <Column width={3 / 4}>
            <strong> {booking.isFullyPaid ? 'PAID' : 'NOT PAID'}</strong>
          </Column>
        </Unit>
        <br />
        {directBooking.bonuses.length > 0 &&
          <Unit>
            <Column width={1 / 4}>Gewünschte Boni:</Column>
            <Column width={3 / 4}>
              {directBooking.bonuses.map((bonus, i) =>
                <div key={i}>
                  <a
                    href={`https://www.roombonus.com/bonus/${bonus.code}`}
                    target="_blank"
                  >
                    {i + 1}. {bonus.title}
                  </a>
                </div>
              )}
            </Column>
          </Unit>}
        {directBooking.comment &&
          <Unit>
            <Column width={1 / 4}>Kommentar:</Column>
            <Column width={3 / 4}>{directBooking.comment}</Column>
          </Unit>}
      </CardContent>
    </Card>
  );
};

export default DetailSidebar;

DetailSidebar.propTypes = {
  selectedReservation: PropTypes.shape(),
  closeDetails: PropTypes.func
};
