import * as actions from 'actions/actionTypes';

const initialState = {
  bookings: [],
  searchParams: {
    page: 1,
    limit: 15,
    offset: 0,
    orderBy: {
      sortField: 'createdAt',
      sortOrder: 'desc'
    }
  },
  booking: {}
};

export default function bookingReducer(state = initialState, action = {}) {
  switch (action.type) {
    case actions.API_BOOKINGS_FOR_PROPERTY:
      return {
        ...state,
        bookings: action.payload.items,
        total: action.payload.count
      };
    case actions.API_BOOKINGS_FOR_PROPERTY_DETAIL:
      return {
        ...state,
        bookingDetail: action.payload
      };
    case actions.API_BOOKINGS_SET_SEARCH_PARAMS:
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          ...action.payload
        }
      };
    default:
      return state;
  }
}
