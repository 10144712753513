import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { mapValues, mapKeys, pick } from 'lodash';
import {
  AutoCompleteInput,
  TextInput,
  MapInput,
  FormHeader
} from 'rb-toolset/lib/ui/FormControls';
import { FormContainer } from 'rb-toolset/lib/ui/FormControls';
import {
  countryChanged$ as countryChangedSignal,
  regionChanged$ as regionChangedSignal,
  loadBulk$ as loadBulkSignal
} from 'rb-toolset/lib/redux/geo/signals';
// import AutoCompleteInput from 'components/FormControls/AutoCompleteInput/AutoCompleteInput';

import LocationLatLngInput from './LocationLatLngInput';
import useStyles from './LocationFormStyles';
// import LocationFormLocationSearch from './LocationFormLocationSearch'
import { matchFilterValue } from './LocationFormUtils';

const selector = formValueSelector('Location');

let LocationForm = ({
  change,
  cities = [],
  // countries = [],
  // countryChanged,
  formValues: { countryId, place, regionPoliticalId, regionTouristics },
  handleSubmit,
  loadBulk,
  propertyLoaded,
  regionChanged,
  regions = [],
  subRegions = [],
  touristicRegions = []
}) => {
  const styles = useStyles();
  const [centerChangeable, setCenterChangeable] = React.useState(false);
  // const [countryCode, setCountryCode] = React.useState(null);
  // const [_, setStepIndex] = React.useState(street ? 1 : 0);

  // const onPlaceSelected = React.useCallback(() => setStepIndex(2));

  const handleRegionChange = React.useCallback((_, value) => {
    change('cityId', null);
    change('regionSubId', null);
    if (regionTouristics) {
      change(
        'regionTouristics',
        Object.keys(regionTouristics).reduce(
          (acc, key) => ({ ...acc, [key]: false }),
          {}
        )
      );
    }

    if (value) {
      regionChanged({
        countryId,
        regionPoliticalId: value
      });
    }
  });

  React.useEffect(
    () => {
      if (!place) return;
      const addressComponents = place.addressComponents;
      change('zip', addressComponents.postal_code);
      change('street', place.address);

      const region =
        matchFilterValue(
          regions,
          addressComponents.administrative_area_level_1
        ) || {};

      change('regionPoliticalId', region.id);
      handleRegionChange(null, region.id);

      const city = matchFilterValue(cities, addressComponents.locality) || {};
      change('cityId', city.id);

      const subRegion =
        matchFilterValue(
          subRegions,
          addressComponents.administrative_area_level_2
        ) || {};

      change('regionSubId', subRegion.id);
      change('lngLat', place.location);
    },
    [place]
  );

  React.useEffect(
    () => {
      if (propertyLoaded) {
        loadBulk({
          countryId,
          regionPoliticalId
        });
      }
    },
    [propertyLoaded, regionPoliticalId]
  );

  return (
    <FormContainer
      {...{ handleSubmit }}
      title="Adresse Ihres Hotels"
      classes={{
        container: styles.container,
        formContainer: styles.formContainer
      }}
    >
      <FormHeader>Detailierte Adresseingabe</FormHeader>
      {/* No location search for now */}
      {/* <LocationFormLocationSearch {...{ countries, countryCode, onPlaceSelected, setCountryCode }} /> */}
      <div>
        {place &&
          <p>
            <strong>Straße:</strong> {place.addressComponents.route}
            <br />
            <strong>Hausnummer:</strong>{' '}
            {place.addressComponents.street_number}
            <br />
            <strong>PLZ:</strong> {place.addressComponents.postal_code}
            <br />
            <strong>Ort:</strong> {place.addressComponents.locality}
            <br />
            <strong>Bezirk:</strong>{' '}
            {place.addressComponents.sublocality_level_1}
            <br />
            <br />
            <strong>Bundesland:</strong>{' '}
            {place.addressComponents.administrative_area_level_1}
            <br />
            <strong>Bezirk:</strong>{' '}
            {place.addressComponents.administrative_area_level_2}
            <br />
          </p>}
        <Field component={TextInput} name="street" label="Straße" fullWidth />
        <Field component={TextInput} name="zip" label="PLZ" fullWidth />
        <Field
          component={AutoCompleteInput}
          options={regions}
          name="regionPoliticalId"
          label="Region"
          onChange={handleRegionChange}
          fullWidth
        />
        <br />
        {regionPoliticalId &&
          <Field
            component={AutoCompleteInput}
            options={cities}
            name="cityId"
            label="Stadt"
            fullWidth
          />}
        {regionPoliticalId &&
          <Field
            component={AutoCompleteInput}
            options={subRegions}
            name="regionSubId"
            label="Sub Region"
            fullWidth
          />}
        <div className={styles.helperLink}>
          <div
            onClick={event => {
              event.preventDefault();
              setCenterChangeable(true);
            }}
          >
            Adresse mit Marker präzisieren
          </div>
          {centerChangeable &&
            <span>
              Bewegen Sie den Marker oder verschieben Sie die Map.
            </span>}
        </div>
        <Field
          component={MapInput}
          name="lngLat"
          withCenterMarker
          centerChangeable={centerChangeable}
          containerClassName={styles.mapContainer}
          // onPlaceChanged={onPlaceSelected}
        />
        <Field component={LocationLatLngInput} name="lngLat" />
      </div>
      <FormHeader>Weitere Angaben</FormHeader>
      <p>Können Sie weitere Angaben über den Ort machen?</p>
      {regionPoliticalId &&
        <Field
          component={AutoCompleteInput}
          options={touristicRegions}
          name="regionTouristics"
          label="Touristische Regionen"
          multiSelection
          fullWidth
        />}
    </FormContainer>
  );
};

LocationForm.propTypes = {
  change: PropTypes.func.isRequired,
  cities: PropTypes.array,
  countries: PropTypes.array.isRequired,
  countryChanged: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loadBulk: PropTypes.func.isRequired,
  propertyLoaded: PropTypes.bool,
  regionChanged: PropTypes.func.isRequired,
  regionPoliticalId: PropTypes.number,
  regions: PropTypes.array,
  subRegions: PropTypes.array,
  touristicRegions: PropTypes.array
};

LocationForm = connect(
  state => ({
    cities: state.geo.cities,
    countries: state.geo.countries,
    formValues: selector(
      state,
      'countryId',
      'regionPoliticalId',
      'regionTouristics',
      'street',
      'stepIndex'
    ),
    regions: state.geo.regions,
    subRegions: state.geo.subRegions,
    touristicRegions: state.geo.touristicRegions,
    propertyLoaded: state.property.loaded
  }),
  {
    countryChanged: countryChangedSignal,
    loadBulk: loadBulkSignal,
    regionChanged: regionChangedSignal
  }
)(LocationForm);

LocationForm = reduxForm({
  form: 'Location'
})(LocationForm);

export default connect(state => ({
  initialValues: {
    ...pick(state.property, [
      'countryId',
      'street',
      'zip',
      'cityId',
      'regionSubId',
      'lngLat'
    ]),
    lngLat: {
      lng: state.property.lngLat ? state.property.lngLat[0] : 0,
      lat: state.property.lngLat ? state.property.lngLat[1] : 0
    },
    regionPoliticalId: state.property.city
      ? state.property.city.regionPoliticalId
      : null,
    regionTouristics: mapValues(
      mapKeys(state.property.regionTouristics, 'id'),
      () => true
    )
  }
}))(LocationForm);
