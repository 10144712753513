export function addBonus({ input: { code, propertyId }, api }) {
  return api().entity('property-bonus').post({
    code,
    propertyId
  });
}

export function removeBonus({ input: { code, propertyId }, api }) {
  return api().entity('property-bonus').delete({
    code: code,
    propertyId
  });
}

export function getCustomBonus({ input: { code, propertyId }, api }) {
  return api()
    .entity('property-bonus')
    .get({
      propertyId,
      code
    })
    .then(bonus => ({ bonus }));
}

export function saveCustomBonus({ input: { bonus, code, propertyId }, api }) {
  return api().entity(`property-bonus`).patch({
    ...bonus,
    propertyId,
    code
  });
}
