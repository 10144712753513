import { toggleNotification } from 'actions/ui/ui';

export function changePassword(oldPassword, newPassword) {
  return (dispatch, getState, { api }) => {
    return api()
      .entity('user/userPasswordChange')
      .post({
        oldPassword,
        newPassword
      })
      .then(() => {
        return dispatch(toggleNotification('Passwort wurde geändert'));
      });
  };
}
