import PropTypes from 'prop-types';
import React from 'react';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';

import * as authSignals from 'signals/auth';

import LoginForm from './LoginForm/LoginForm';

import useStyles from './LoginStyles';

const Login = ({
  asModal,
  auth,
  impersonateUsers,
  impersonate,
  login,
  loadUsers
}) => {
  const styles = useStyles();

  const onSubmit = React.useCallback(
    ({ userId, email, password }, _, formProps) => {
      if (userId) {
        impersonate({ userId });
        formProps.reset();
      } else if (!impersonateUsers) {
        login({ email, password });
      }
    }
  );

  return (
    <div className={asModal ? styles.containerModal : styles.container}>
      {!auth.user &&
        <Paper>
          <LoginForm {...{ onSubmit }} onLoadUsers={loadUsers} />
        </Paper>}
      <div className={styles.errorBox}>
        {auth.error &&
          'Ups, da ist was schief gelaufen. Bitte korrigieren Sie den Benutzernamen/Passwort.'}
        {auth.errorPropertyManager &&
          'Property Manager nicht vorhanden oder bereits gelöscht.'}
      </div>
    </div>
  );
};

Login.propTypes = {
  asModal: PropTypes.bool,
  auth: PropTypes.object,
  impersonate: PropTypes.func.isRequired,
  impersonateUsers: PropTypes.array,
  loadUsers: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  impersonateUsers: state.auth.impersonateUsers
});

const mapDispatchToProps = {
  login: authSignals.login,
  impersonate: authSignals.impersonate,
  loadUsers: authSignals.loadUsers
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
