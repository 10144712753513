import React, { Component } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

import PropTypes from 'prop-types';
import { Unit, Column } from 'rb-toolset/lib/ui/Layout';
import { connect } from 'react-redux';
import { tokenRefresher } from 'signals/auth';
import { push } from 'connected-react-router';
import Button from '@material-ui/core/Button';

@connect(null, { tokenRefresher, push })
export default class LogoutWarning extends Component {
  static propTypes = {
    seconds: PropTypes.number.isRequired,
    tokenRefresher: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  };

  static defaultProps = {};

  constructor(props) {
    super(props);

    this.onLogout = this.onLogout.bind(this);

    this.state = {
      remainingSeconds: props.seconds
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      const { remainingSeconds } = this.state;
      if (remainingSeconds > 0) {
        this.setState({
          remainingSeconds: remainingSeconds - 1
        });
      } else {
        this.clearInterval();
        this.onLogout();
      }
    }, 1000);
  }

  componentWillUnmount() {
    this.clearInterval();
  }

  onLogout() {
    this.props.push('/logout');
  }

  clearInterval() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  render() {
    const { remainingSeconds } = this.state;
    const { seconds } = this.props;
    return (
      <div>
        <p>Sie werden in {remainingSeconds} Sekunden abgemeldet</p>
        <LinearProgress
          mode="determinate"
          value={remainingSeconds}
          max={seconds}
        />
        <br />
        <Unit>
          <Column width={1 / 2}>
            <Button
              variant="contained"
              onClick={() => this.props.tokenRefresher()}
            >
              Weiter arbeiten
            </Button>
          </Column>
          <Column width={1 / 2} align="right">
            <Button variant="contained" onClick={this.onLogout}>
              Abmelden
            </Button>
          </Column>
        </Unit>
      </div>
    );
  }
}
