import { Property } from 'rb-domain';

export const translateAgeGroup = code => {
  if (code === Property.CHILD_AGE_GROUPS.BABY) {
    return 'Kinderkat-1';
  }
  if (code === Property.CHILD_AGE_GROUPS.CHILD) {
    return 'Kinderkat-2';
  }
  return 'Kinderkat-3';
};

export const translateDiscountType = code => {
  if (code === Property.DISCOUNT_TYPE.PERCENT) {
    return 'Prozent';
  }
  return 'Fixbetrag';
};

export const discountTypes = Object.keys(Property.DISCOUNT_TYPE).map(code => {
  return {
    value: code,
    label: translateDiscountType(code)
  };
});

export const CHILD_AGE = 18;
export const ORDERED_GROUP_TYPES = [
  Property.CHILD_AGE_GROUPS.BABY,
  Property.CHILD_AGE_GROUPS.CHILD,
  Property.CHILD_AGE_GROUPS.YOUTH
];
