// import api from 'utils/api';
import { ChannelManager } from 'rb-domain';

import * as actions from '../actionTypes';

export function getAllChannelManagers() {
  return dispatch => {
    dispatch({
      type: actions.UI_DATA_CHANNELMANAGER,
      payload: ChannelManager.LIST.filter(
        item => item.code !== ChannelManager.CODES.INTERALP
      )
    });
  };
}

export function toggleNotification(message, error) {
  return {
    type: actions.UI_TOGGLE_NOTIFICATION,
    payload: {
      message,
      error
    }
  };
}

export function toggleUploading(isUploading) {
  return {
    type: actions.UI_TOGGLE_UPLOADING_PROGRESS,
    payload: isUploading
  };
}

function toggleModalLogin(show) {
  return {
    type: actions.UI_TOGGLE_MODAL_LOGIN,
    payload: show
  };
}

export function hideModalLogin({ dispatch }) {
  dispatch(toggleModalLogin(false));
}

export function showLogoutWarning() {
  return {
    type: actions.UI_TOGGLE_LOGOUT_WARNING,
    payload: true
  };
}

export function showConfirmation(id, data) {
  return {
    type: actions.UI_CONFIRMATION_SHOW,
    payload: {
      id,
      data
    }
  };
}

export function hideConfirmations() {
  return {
    type: actions.UI_CONFIRMATION_HIDE
  };
}
