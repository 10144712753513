import PropTypes from 'prop-types';
import React from 'react';
import { green, red, blue, grey } from '@material-ui/core/colors';
import { ExitToApp, Replay, FiberManualRecord } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import injectStyle from 'react-jss';
import classNames from 'classnames';

import styles from './CalendarStyles';

const renderMinimumDaysToStay = classes => {
  return (
    <IconButton className={classes.minimumIcon}>
      <Replay style={{ color: red['200'] }} />
    </IconButton>
  );
};

const renderBlocked = (blocked, classes) => {
  return (
    <IconButton className={classes.blockedIcon}>
      <ExitToApp style={{ color: blocked ? red['200'] : green['200'] }} />
    </IconButton>
  );
};

const CalendarDay = ({
  availableCount,
  bookingsCount,
  classes,
  minimumDaysToStay,
  day,
  onMouseDown,
  onMouseUp,
  onMouseEnter,
  price,
  blocked,
  disabled,
  isStandardPrice
}) => {
  const date = day.getDate();

  const roomsLeft = availableCount - bookingsCount;

  return (
    <div
      onMouseDown={() => onMouseDown(day)}
      onMouseUp={() => onMouseUp(day)}
      onMouseEnter={() => onMouseEnter(day)}
      className={classes.day}
    >
      {renderBlocked(blocked, classes)}
      {minimumDaysToStay > 1 && renderMinimumDaysToStay(classes)}
      {date}
      <div className={classes.priceContainer}>
        ab<br />
        <span className={classes.price}>{`€${price || 0}`}</span>
        {!disabled &&
          <FiberManualRecord
            className={classes.priceIcon}
            style={{ color: isStandardPrice ? grey['300'] : blue['300'] }}
          />}
      </div>
      <div
        className={classNames({
          [classes.availability]: true,
          [classes.availabilityActive]: !disabled,
          [classes.noAvailability]: roomsLeft === 0 && !disabled
        })}
      >
        {roomsLeft >= 0 ? roomsLeft : 0}
      </div>
    </div>
  );
};

CalendarDay.propTypes = {
  day: PropTypes.object.isRequired,
  bookingsCount: PropTypes.number,
  availableCount: PropTypes.number,
  minimumDaysToStay: PropTypes.number,
  price: PropTypes.number,
  onMouseDown: PropTypes.func.isRequired,
  onMouseUp: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  blocked: PropTypes.bool,
  isStandardPrice: PropTypes.bool,
  classes: PropTypes.object.isRequired
};

CalendarDay.defaultProps = {
  availableCount: 0,
  bookingsCount: 0,
  minimumDaysToStay: 1
};

export default injectStyle(styles)(CalendarDay);
