import React from 'react';
import PropTypes from 'prop-types';
import { Fab } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import PhotoGrid from 'rb-toolset/lib/ui/PhotoGrid/PhotoGrid';
import { FormContainer } from 'rb-toolset/lib/ui/FormControls';
import PhotoUpload from 'rb-toolset/lib/ui/PhotoUpload/PhotoUpload';
import ConfirmationDialog from 'rb-toolset/lib/ui/ConfirmationDialog';

import { showConfirmation } from 'actions/ui/ui';
import { uploadPhotoRaw } from 'actions/photos/photos';

import { connect } from 'react-redux';

import useStyles from './PhotosFormStyles';

const PhotosForm = ({
  isUploading = false,
  onChange,
  onDelete,
  onUpload,
  photos,
  showConfirmationAction,
  title,
  uploadPhotoRawAction
}) => {
  const styles = useStyles();

  const onPositionChanged = React.useCallback((photo, position) =>
    onChange({
      url: photo.url,
      index: position
    })
  );

  const handleConfirmDelete = React.useCallback(({ photo }) => onDelete(photo));

  return (
    <FormContainer
      isUploading={isUploading}
      title={title}
      hideFooter
      fullWidth
      newComponent={
        <PhotoUpload
          withCropper={false}
          onPhotoChanged={onUpload}
          uploadPhoto={uploadPhotoRawAction}
        >
          <Fab size="small" color="primary">
            <Add />
          </Fab>
        </PhotoUpload>
      }
    >
      <ConfirmationDialog
        id="delete.confirm"
        title="Bild löschen"
        confirmText="Ja"
        cancelText="Nein"
        onConfirm={handleConfirmDelete}
      >
        Wollen Sie das Foto wirklich löschen?
      </ConfirmationDialog>
      <div className={styles.gridList}>
        <PhotoGrid
          items={photos}
          sortable
          onPositionChanged={onPositionChanged}
          onDelete={photo =>
            showConfirmationAction('delete.confirm', {
              photo
            })}
        />
      </div>
    </FormContainer>
  );
};

PhotosForm.propTypes = {
  isUploading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  photos: PropTypes.array.isRequired,
  showConfirmationAction: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  uploadPhotoRawAction: PropTypes.func.isRequired
};

export default connect(null, {
  showConfirmationAction: showConfirmation,
  uploadPhotoRawAction: uploadPhotoRaw
})(PhotosForm);
