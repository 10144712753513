import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { asyncConnect } from 'redux-connect';

import { FormContainer } from 'rb-toolset/lib/ui/FormControls';
import { SelectInput } from 'rb-toolset/lib/ui/FormControls';
import { range } from 'lodash';
import moment from 'moment';
import { Const } from 'rb-domain';
import { Button } from '@material-ui/core';

import { getUpcomingBookings } from 'actions/booking/booking';

import PaperContainer from 'components/PaperContainer/PaperContainer';
import PieChart from 'components/Chart/PieChart';
import BarChart from 'components/Chart/BarChart';

import DashboardGuests from './DashboardGuests';
import useStyles from './DashboardStyles';

const years = [
  {
    value: 2016,
    label: '2016'
  },
  {
    value: 2015,
    label: '2015'
  }
];

const months = ['Sep', 'Okt', 'Nov'];

const optionBonus = [
  {
    x: 'Late-Checkout',
    y: 50
  },
  {
    x: 'Dinner',
    y: 25
  },
  {
    x: 'Golf',
    y: 15
  },
  {
    x: 'Taxi',
    y: 5
  }
];

const Disabled = () => {
  const styles = useStyles();
  return (
    <div className={styles.disabled}>
      Derzeit steht Ihnen dieses Fenster nicht zur Verfügung.
    </div>
  );
};

let Dashboard = ({ propertyId, upcomingBookings }) => {
  const [profitData, setProfitData] = React.useState();

  const styles = useStyles();

  const generateProfit = React.useCallback(() => {
    setProfitData(
      range(1, 31).map(day => ({
        x: day,
        y: Math.random(5, day + 10) * 300
      }))
    );
  });

  React.useEffect(() => {
    generateProfit();
  }, []);

  const hasGuests = upcomingBookings.length === 0;

  return (
    <React.Fragment>
      <PaperContainer>
        <FormContainer title="In Kürze anreisende Gäste" fullWidth>
          {hasGuests &&
            <DashboardGuests {...{ upcomingBookings, propertyId }} />}
          {!hasGuests &&
            <div className={styles.notification}>Derzeit keine Gäste.</div>}
        </FormContainer>
      </PaperContainer>
      <PaperContainer>
        <FormContainer
          title="Umsatzbericht:&nbsp;"
          toolbar={
            <div className={styles.alignCenter}>
              <SelectInput
                asMenu
                input={{ onChange: () => {} }}
                options={years}
                value={years[0]}
              />
            </div>
          }
        >
          {false &&
            <div>
              {months.map((month, index) =>
                <Button label={month} key={index} onClick={generateProfit} />
              )}
              <BarChart data={profitData} />
            </div>}
          <Disabled />
        </FormContainer>
      </PaperContainer>
      <PaperContainer>
        <FormContainer title="Angefragte Boni">
          {false && <PieChart data={optionBonus} />}
          <Disabled />
        </FormContainer>
      </PaperContainer>
      <PaperContainer>
        <FormContainer title="Ihr Profil findet Beachtung">
          <Disabled />
        </FormContainer>
      </PaperContainer>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  upcomingBookings: PropTypes.array.isRequired,
  propertyId: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
  propertyId: parseInt(state.property.id, 10),
  upcomingBookings: state.dashboard.upcomingBookings
});

Dashboard = connect(mapStateToProps)(Dashboard);

export default asyncConnect([
  {
    key: 'upcomingBookings',
    promise: options => {
      const { store: { dispatch } } = options;

      const propertyId = parseInt(options.match.params.propertyId, 10);
      const promises = [];
      promises.push(
        dispatch(
          getUpcomingBookings(propertyId, {
            arrivalFrom: moment().format(Const.DAY_PATTERN),
            arrivalTo: moment().add(7, 'days').format(Const.DAY_PATTERN)
          })
        )
      );
      return Promise.all(promises);
    }
  }
])(Dashboard);
