import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { requestTrustedMembership } from 'actions/property/property';

import PartnershipForm from './PartnershipForm';

const Partnership = ({
  property,
  propertyId,
  requestTrustedMembershipAction
}) => {
  const onSubmit = React.useCallback(formData =>
    requestTrustedMembershipAction({
      comment: formData.comment,
      propertyId
    })
  );

  return <PartnershipForm {...{ property, onSubmit }} />;
};

Partnership.propTypes = {
  property: PropTypes.object.isRequired,
  propertyId: PropTypes.number.isRequired,
  requestTrustedMembershipAction: PropTypes.func.isRequired
};

export default connect(
  state => ({
    property: state.property,
    propertyId: parseInt(state.property.id, 10)
  }),
  { requestTrustedMembershipAction: requestTrustedMembership }
)(Partnership);
