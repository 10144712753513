import { signal } from 'rb-toolset/lib/signal';

import { notify } from 'rb-toolset/lib/signal/factories';

import {
  savePropertyAction,
  injectPropertyFieldsAction
} from 'actions/property/property';
import {
  toggleAccommodationTypesAction,
  toggleAccommodationTypesRedux
} from 'actions/property/property-accomodation';
import {
  toggleProvidersAction,
  toggleProvidersRedux
} from 'actions/property/property-provider';
import { toggleTouristicRegionsAction } from 'actions/property/property-regiontouristic';

// [this.props.toggleTouristicRegions(propertyId, rChanges)]

export const saveProperty = signal([
  toggleAccommodationTypesAction,
  toggleAccommodationTypesRedux,
  toggleProvidersAction,
  toggleProvidersRedux,
  toggleTouristicRegionsAction,
  savePropertyAction,
  {
    inject: [injectPropertyFieldsAction],
    nothingToSave: []
  },
  notify('Daten gespeichert')
]);
