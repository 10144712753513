import * as actions from '../actionTypes';

export function getAllTags() {
  return (dispatch, getState, { api }) => {
    return api().entity('property/tagsList?locale=de').get().then(result =>
      dispatch({
        type: actions.UI_DATA_TAGS,
        payload: result.items
      })
    );
  };
}
