import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormContainer } from 'rb-toolset/lib/ui/FormControls';
import { Const } from 'rb-domain';
import {
  reduxForm,
  propTypes,
  getFormValues,
  change as remoteChange
} from 'redux-form';
import { isEmpty, isEqual, concat, take } from 'lodash';
import { connect } from 'react-redux';

import PaperContainer from 'components/PaperContainer/PaperContainer';
import HelpboxContainer from 'components/HelpboxContainer/HelpboxContainer';
import Calendar from 'components/Calendar/Calendar';

import AvailabilityScreenHeader from './AvailabilityScreenHeader';
import AvailabilityScreenSidebars from './AvailabilityScreenSidebars';

// const selector = getFormValues('Availability');

@connect(
  state => ({
    formValues: getFormValues('Availability')(state) || {}
  }),
  {
    remoteChange
  }
)
@reduxForm({
  form: 'Availability',
  validate: values => {
    const errors = {};

    if (
      values.prices &&
      values.prices.filter(price => price && price > 0).length !==
        values.prices.length
    ) {
      errors.prices = {
        _error: 'Bitte alle Preise eingeben'
      };
    }

    return errors;
  }
})
export default class AvailabilityScreen extends Component {
  static propTypes = {
    ...propTypes,
    availability: PropTypes.object,
    formValues: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    onMonthChanged: PropTypes.func.isRequired,
    onResetToDefaultPrices: PropTypes.func.isRequired,
    onSubmitAvailability: PropTypes.func.isRequired,
    onSubmitPrices: PropTypes.func.isRequired,
    remoteChange: PropTypes.func.isRequired,
    rooms: PropTypes.object.isRequired,
    triggerSearch: PropTypes.func.isRequired,
    weekendDefinition: PropTypes.object.isRequired
  };

  static defaultProps = {
    availability: {}
  };

  componentDidMount() {
    this.props.triggerSearch({}, this.getSelectedRoom());
  }

  componentWillReceiveProps(nextProps) {
    const { rooms, change, formValues, triggerSearch } = this.props;

    if (formValues.selectedRoomId !== nextProps.formValues.selectedRoomId) {
      const newRoom = rooms[nextProps.formValues.selectedRoomId];
      const newOption = Object.values(newRoom.options)[0];

      this.resetDateRange();

      change('selectedRoomOptionId', newOption.id);

      triggerSearch(
        {
          propertyRoomId: newRoom.id
        },
        newRoom
      );
    }

    if (
      formValues.selectedRoomOptionId !==
      nextProps.formValues.selectedRoomOptionId
    ) {
      triggerSearch({}, this.getSelectedRoom());
      this.resetDateRange();
    }

    if (
      (formValues.from !== nextProps.formValues.from ||
        formValues.to !== nextProps.formValues.to) &&
      nextProps.formValues.to &&
      nextProps.formValues.from
    ) {
      this.onDateRangeSelected(nextProps);
    }

    if (this.props.availability !== nextProps.availability) {
      this.onDateRangeSelected(nextProps);
    }
  }

  onDateRangeSelected = ({ availability, formValues: { from, to } }) => {
    const { change, array } = this.props;

    const defaultValues = this.getDefaultValuesForDateRange(
      { from, to },
      availability
    );

    if (!defaultValues) {
      return;
    }

    this.props.remoteChange(
      'AvailabilityCtn',
      'availableCount',
      defaultValues.availableCount
    );
    change('blocked', defaultValues.blocked);
    change('minimumDaysToStay', defaultValues.minimumDaysToStay);

    array.removeAll('prices');

    const selectedRoom = this.getSelectedRoom();

    take(defaultValues.prices, selectedRoom.occupancyMax).forEach(price =>
      array.push('prices', price)
    );
  };

  getDefaultValuesForDateRange(dateRange, availability) {
    // Avoid endless while-loop
    if (!dateRange.from || !dateRange.to) {
      console.warn(
        'dateRange from/to is null - endless while-loop/possible freeze!',
        dateRange
      );
      return;
    }

    const momentFrom = dateRange.from ? moment(dateRange.from) : moment();
    const momentTo = dateRange.to ? moment(dateRange.to) : moment();

    const selectedRoom = this.getSelectedRoom();
    const selectedRoomOption = this.getSelectedRoomOption();

    let availableCount = Infinity;
    let blocked = true;
    let minimumDaysToStay = 1;
    let prices = null;
    let pricesEquality = true;

    while (momentFrom.isSameOrBefore(momentTo)) {
      const dayAvailability =
        availability[momentFrom.format(Const.DAY_PATTERN)];
      if (dayAvailability) {
        const minAvailability = Math.min(
          availableCount,
          dayAvailability.availableCount - dayAvailability.bookings
        );
        availableCount = dayAvailability.availableCount
          ? minAvailability
          : availableCount;
        minimumDaysToStay = dayAvailability.minimumDaysToStay
          ? Math.max(minimumDaysToStay, dayAvailability.minimumDaysToStay)
          : minimumDaysToStay;

        blocked = blocked && dayAvailability.blocked;

        // Take default values if no data available
        const defaultPrices = this.isWeekend(momentFrom) &&
          selectedRoomOption.pricesWeekend.length > 0
          ? selectedRoomOption.pricesWeekend
          : selectedRoomOption.prices;

        const dayPrices = dayAvailability.prices[selectedRoomOption.id]
          ? dayAvailability.prices[selectedRoomOption.id].prices
          : defaultPrices;

        if (pricesEquality) {
          pricesEquality = prices === null || isEqual(prices, dayPrices);

          if (pricesEquality) {
            prices = dayPrices;
          }
        } else {
          pricesEquality = false;
        }
      }
      momentFrom.add(1, 'day');
    }

    if (!prices) {
      prices = [];
    }

    if (pricesEquality) {
      const lenDiff = selectedRoom.occupancyMax - prices.length;
      prices = lenDiff > 0
        ? concat(prices, new Array(lenDiff).fill(null))
        : prices;
    } else {
      prices = new Array(selectedRoom.occupancyMax).fill(null);
    }

    return {
      availableCount: availableCount !== Infinity ? availableCount : 0,
      minimumDaysToStay,
      blocked: !!blocked,
      prices
    };
  }

  getSelectedRoom() {
    const formValues = this.props.formValues;
    if (isEmpty(formValues)) {
      return this.props.rooms[this.props.initialValues.selectedRoomId];
    }
    const { rooms, formValues: { selectedRoomId } } = this.props;

    // let selectedRId = selectedRoomId === undefined ? rooms[Object.keys(rooms)[0]].id : selectedRoomId
    return rooms[selectedRoomId];
  }

  getSelectedRoomOption() {
    const { formValues } = this.props;
    const selectedRoomOptionId = formValues.selectedRoomOptionId;
    return this.getSelectedRoom().options[selectedRoomOptionId] === undefined
      ? this.getSelectedRoom().options[
          Object.keys(this.getSelectedRoom().options)[0]
        ]
      : this.getSelectedRoom().options[selectedRoomOptionId];
  }

  isWeekend = momentDay => {
    const { weekendDefinition } = this.props;
    return weekendDefinition[momentDay.isoWeekday()].isWeekend;
  };

  resetDateRange = () => {
    const { change } = this.props;

    change('from', moment().format(Const.DAY_PATTERN));
    change('to', moment().format(Const.DAY_PATTERN));
  };

  render() {
    const {
      availability,
      change,
      formValues,
      handleSubmit,
      loading,
      onResetToDefaultPrices,
      onSubmitAvailability,
      onSubmitPrices,
      rooms
    } = this.props;
    const { from, to } = formValues;

    if (isEmpty(formValues)) {
      return <div />;
    }

    const selectedRoom = this.getSelectedRoom();
    const selectedRoomOption = this.getSelectedRoomOption();

    return (
      <PaperContainer
        helpBox={{
          column1: (
            <AvailabilityScreenSidebars
              {...{
                formValues,
                handleSubmit,
                onResetToDefaultPrices,
                onSubmitAvailability,
                onSubmitPrices,
                selectedRoom,
                selectedRoomOption
              }}
            />
          ),
          column2: [
            <HelpboxContainer headline="Verfügbarkeiten">
              <p>
                {`Haben Sie bereits alle Zimmerkategorien und Ratenpläne unter dem
                Punkt
                "Zimmerkategorien" angelegt? So können Sie nun direkt in diesem
                Menü mit Ihrer
                Verfügbarkeitspflege fortfahren. Bitte wählen Sie zuerst im
                oberen Bereich dieses
                Menüs Ihre Zimmerkategorie sowie Ratentyp. Danach können Sie
                entweder mit der
                Datumseingabe rechts oben "Von-Bis" oder aber auch mit
                gedrückter Maustaste direkt
                auf dem Kalender den Gültigkeitsbereich Ihrer Rate auswählen.`}
              </p>
              <p>
                {`Selbstverständlich können Sie die Verfügbarkeit/Anzahl der
                freien in dieser
                Zimmerkategorie je Tag konfigurieren. Des Weiteren können Sie
                noch individuell die
                Zimmerraten auch in diesem Menü anpassen und Restriktionen für
                versch. Termine (z.B.
                Messen, Hochsaison, etc.) setzen. Diese beinhalten die
                Einstellungen "keine Anreise möglich/close to arrival" oder
                "Minimale Anzahl an Nächten/minimum length of stay".
                Möchten Sie diese Restriktionen aktivieren so setzen Sie einfach
                ein Häkchen oder
                geben die Mindestanzahl an Nächten an dem gewünschten Anreisetag
                ein.`}
              </p>
            </HelpboxContainer>
          ]
        }}
      >
        <FormContainer title="Verfügbarkeiten" isRefreshing={loading}>
          <div>
            {selectedRoomOption &&
              <AvailabilityScreenHeader {...{ rooms, selectedRoom }} />}
          </div>
          {selectedRoomOption &&
            <Calendar
              selectedRange={{
                from,
                to
              }}
              change={change}
              availability={availability}
              isWeekend={this.isWeekend}
              roomOption={selectedRoomOption}
              onMonthChanged={date =>
                this.props.onMonthChanged(date, this.getSelectedRoom())}
            />}
        </FormContainer>
      </PaperContainer>
    );
  }
}
