import React from 'react';
import PropTypes from 'prop-types';
import { FormContainer } from 'rb-toolset/lib/ui/FormControls';
import {
  TextInput,
  ToggleInput,
  WYSIWYGEditor
} from 'rb-toolset/lib/ui/FormControls';
import { Fab, ListSubheader } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import {
  reduxForm,
  Field,
  FieldArray,
  formValueSelector,
  propTypes
} from 'redux-form';
import { connect } from 'react-redux';
import { showConfirmation as showConfirmationAction } from 'actions/ui/ui';

import CancellationGroupFormDialog from './CancellationGroupFormDialog';
import CancellationGroupFormItem from './CancellationGroupFormItem';

import useStyles from './CancellationGroupFormStyles';

const selector = formValueSelector('CancellationGroup');

let CancellationGroupForm = ({
  handleSubmit,
  invalid,
  anyTouched,
  onDelete,
  onDeleteCancellation,
  array,
  formValues: { cancellations },
  showConfirmation,
  cancellationGroup: { id }
}) => {
  const styles = useStyles();
  const [expandedOption, setExpandedOption] = React.useState(null);

  const handleDelete = React.useCallback(
    () =>
      id &&
      showConfirmation('confirmation.group', {
        cancellationGroupId: id
      })
  );

  const handleCancellationDelete = React.useCallback(
    ({ cancellationId, index }) => {
      if (cancellationId) {
        onDeleteCancellation(cancellationId);
      }

      return array.remove('cancellations', index);
    }
  );

  const handleCancellationGroupDelete = React.useCallback(
    ({ cancellationGroupId }) => onDelete(cancellationGroupId)
  );

  const addNewOption = React.useCallback(() => {
    setExpandedOption(cancellations.length);

    array.push('cancellations', {
      cancellationGroupId: id,
      name: '',
      daysPriorArrival: 0,
      timePriorArrival: '',
      calculationBases: [''],
      calculationAmounts: [{}]
    });
  });

  return (
    <div>
      <CancellationGroupFormDialog
        {...{
          handleCancellationGroupDelete,
          handleCancellationDelete
        }}
      />
      <FormContainer
        title="Stornierungsrichtlinie"
        {...{ handleDelete, handleSubmit }}
        invalid={invalid && anyTouched}
        fullWidth
        deleteText="Stornierungsrichtlinie löschen"
      >
        <div className={styles.formContent}>
          <Field component={TextInput} name="name" label="Name" />
          <br />
          <br />
          <Field
            component={ToggleInput}
            label="Stornierungsrichtlinie als Standard verwenden"
            name="isDefault"
          />
          <br />
          <p>Beschreibung (optional):</p>
          <Field component={WYSIWYGEditor} height={150} name="description" />
          <br />
        </div>

        {id &&
          <div className={styles.optionContainer}>
            <ListSubheader>Stornierungsbedingungen</ListSubheader>
            <div className={styles.optionAdd}>
              <Fab size="small" color="primary" onClick={addNewOption}>
                <Add />
              </Fab>
            </div>
            <FieldArray
              name="cancellations"
              props={{
                array,
                cancellations,
                expandedOption,
                setExpandedOption,
                showConfirmation
              }}
              component={CancellationGroupFormItem}
            />
          </div>}
      </FormContainer>
    </div>
  );
};

CancellationGroupForm.propTypes = {
  ...propTypes,
  formValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  showConfirmation: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  anyTouched: PropTypes.bool.isRequired,
  onDelete: PropTypes.func,
  onDeleteCancellation: PropTypes.func,
  cancellationGroup: PropTypes.object
};

const mapStateToProps = state => ({
  formValues: selector(state, 'name', 'description', 'cancellations') || {}
});

const mapDispatchToProps = {
  showConfirmation: showConfirmationAction
};

CancellationGroupForm = connect(mapStateToProps, mapDispatchToProps)(
  CancellationGroupForm
);

export default reduxForm({
  form: 'CancellationGroup',
  enableReinitialize: true,
  validate: (values = {}) => {
    const errors = {};

    if (values.name === undefined) {
      errors.name = 'Bitte geben Sie einen Namen an';
    }

    return errors;
  }
})(CancellationGroupForm);
