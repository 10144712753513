import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { renderRoutes } from 'react-router-config';
import { Paper } from '@material-ui/core';
import Notification from 'rb-toolset/lib/ui/Notification/Notification';
import { BackLink } from 'rb-toolset/lib/ui/FormControls';

import { addProperty as addPropertyAction } from 'actions/property/property';

import { tokenRefresher as tokenRefresherSignal } from 'signals/auth';
import { saveProperty as savePropertySignal } from 'signals/property';
import { reAuthenticate as reAuthenticateSignal } from 'signals/auth';

import AddPropertyForm from './AddPropertyForm/AddPropertyForm';

import useStyles from './AddPropertyStyles';

const AddProperty = ({
  addProperty,
  availableProperties,
  reAuthenticate,
  route,
  saveProperty,
  tokenRefresher,
  user
}) => {
  const styles = useStyles();
  const { push } = useHistory();

  React.useEffect(() => {
    tokenRefresher();
  }, []);

  const onSubmit = React.useCallback(formData => {
    const newValues = {
      ...formData,
      ownerId: user.id
    };

    addProperty(newValues, id => {
      saveProperty({
        propertyId: id,
        data: newValues
      }).then(() => {
        reAuthenticate().then(() => {
          push(`/${id}/housing`);
        });
      });
    });
  });

  return (
    <div className={styles.container}>
      <Paper className={styles.content}>
        {availableProperties.length > 0 &&
          <BackLink to="/">
            « zurück
          </BackLink>}
        <AddPropertyForm onSubmit={onSubmit} />
      </Paper>
      <Notification />
      {renderRoutes(route.routes)}
    </div>
  );
};

AddProperty.propTypes = {
  addProperty: PropTypes.func,
  auth: PropTypes.object,
  availableProperties: PropTypes.array,
  reAuthenticate: PropTypes.func.isRequired,
  route: PropTypes.object.isRequired,
  saveProperty: PropTypes.func.isRequired,
  tokenRefresher: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  user: state.auth.user,
  availableProperties: state.auth.availableProperties
});

const mapDispatchToProps = {
  addProperty: addPropertyAction,
  reAuthenticate: reAuthenticateSignal,
  saveProperty: savePropertySignal,
  tokenRefresher: tokenRefresherSignal
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProperty);
