import { createUseStyles } from 'react-jss';

export default createUseStyles({
  optionContainer: {
    marginTop: '25px',
    position: 'relative'
  },
  option: {
    boxShadow: 'none !important',
    borderTop: '1px solid #ddd',
    borderRadius: '0 !important'
  },
  optionHeader: {
    backgroundColor: '#efe !important'
  },
  optionBody: {
    paddingTop: '0 !important'
  },
  formContent: {
    padding: '5px 16px'
  },
  optionAdd: {
    position: 'absolute',
    right: '10px',
    top: '0',
    zIndex: '2'
  },
  hidden: {
    display: 'none'
  }
});
