exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".src-components-HelpboxContainer-HelpboxContainer__help--2-PBG {\n  padding: 0 15px 15px 15px;\n}\n\n.src-components-HelpboxContainer-HelpboxContainer__helpIcon--1bRKS {\n  text-align: center;\n  margin-bottom: 15px;\n}\n\n.src-components-HelpboxContainer-HelpboxContainer__helpIcon--1bRKS svg {\n  margin-top: -18px;\n}\n", "", {"version":3,"sources":["/usr/src/extranet-web/src/components/HelpboxContainer/HelpboxContainer.scss"],"names":[],"mappings":"AAAA;EAEE,0BAAyB;CAC1B;;AAED;EACE,mBAAkB;EAClB,oBAAmB;CAKpB;;AAPD;EAKI,kBAAiB;CAClB","file":"HelpboxContainer.scss","sourcesContent":[".help {\n  //margin: 30px 18px 6px 2px;\n  padding: 0 15px 15px 15px;\n}\n\n.helpIcon {\n  text-align: center;\n  margin-bottom: 15px;\n\n  svg {\n    margin-top: -18px;\n  }\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"help": "src-components-HelpboxContainer-HelpboxContainer__help--2-PBG",
	"helpIcon": "src-components-HelpboxContainer-HelpboxContainer__helpIcon--1bRKS"
};