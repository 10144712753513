import { createUseStyles } from 'react-jss';

export default createUseStyles({
  roomPrice: {
    marginRight: '10px',
    width: '100px !important'
  },
  field: {
    display: 'flex',
    alignContent: 'center'
  },
  roomIcons: {
    marginRight: '10px',
    paddingTop: '10px',
    maxWidth: '100px',
    width: '100px'
  },
  roomIconsRow: {
    height: '55px',
    display: 'flex',
    alignItems: 'center'
  },
  label: {
    width: '100px',
    display: 'inline-block',
    marginRight: '10px'
  },
  labelDirty: {
    color: '#F44336',
    width: '100px',
    display: 'inline-block',
    marginRight: '10px'
  }
});
