export default {
  dark: '#000000de',
  green: '#66BB6A',
  green2: '#efe',
  grey: '#efefef',
  grey2: '#dbdbdb',
  red: 'red',
  roombonus: '#a5245f',
  white: '#ffffff',
  yellow: '#ffa726'
};
