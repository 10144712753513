/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from 'prop-types';
import { TextInput } from 'rb-toolset/lib/ui/FormControls';
import { Column, Unit } from 'rb-toolset/lib/ui/Layout';

const LocationLatLngInput = ({ input }) => {
  const [lngLat, setLngLat] = React.useState({
    lat: input.value.lat,
    lng: input.value.lng
  });

  React.useEffect(
    () => {
      setLngLat({
        lat: input.value.lat,
        lng: input.value.lng
      });
    },
    [input.value]
  );

  const onBlur = () => {
    input.onChange({
      lng: isNaN(lngLat.lng) ? input.value.lng : parseFloat(lngLat.lng),
      lat: isNaN(lngLat.lat) ? input.value.lat : parseFloat(lngLat.lat)
    });

    if (isNaN(lngLat.lng) || isNaN(lngLat.lat)) {
      setLngLat({
        lat: input.value.lat,
        lng: input.value.lng
      });
    }
  };

  return (
    <Unit>
      <Column width={1 / 2}>
        <TextInput
          fullWidth
          type="text"
          label="Longitude"
          input={{
            value: lngLat.lng,
            onChange: ev => {
              setLngLat({
                lat: input.value.lat,
                lng: ev.target.value
              });
            },
            onBlur
          }}
          meta={{}}
        />
      </Column>
      <Column width={1 / 2}>
        <TextInput
          fullWidth
          type="text"
          label="Latitude"
          input={{
            value: lngLat.lat,
            onChange: ev => {
              setLngLat({
                lng: input.value.lng,
                lat: ev.target.value
              });
            },
            onBlur
          }}
          meta={{}}
        />
      </Column>
    </Unit>
  );
};

LocationLatLngInput.propTypes = {
  input: PropTypes.object
};

export default LocationLatLngInput;
