import React from 'react';
import { reduxForm, Field } from 'redux-form';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';
import { AutoCompleteInput, TextInput } from 'rb-toolset/lib/ui/FormControls';
import { connect } from 'react-redux';
import { FormContainer } from 'rb-toolset/lib/ui/FormControls';
import { debounce } from 'lodash';
import classNames from 'classnames';

import useStyles from './LoginFormStyles';

const InputProps = {
  disableUnderline: true
};

const autoCompleteCustomStyle = {
  height: '48px',
  padding: '0 20px',
  fontSize: '16px'
};

let LoginForm = ({
  impersonateUsers,
  handleSubmit,
  invalid,
  anyTouched,
  className,
  ...props
}) => {
  let debouncedLoadUsers = debounce(props.onLoadUsers, 200);
  debouncedLoadUsers = React.useCallback(event =>
    debouncedLoadUsers(event.target.value)
  );

  const styles = useStyles();

  return (
    <FormContainer
      handleSubmit={handleSubmit}
      invalid={invalid && anyTouched}
      fullWidth
      submitButtonText="Anmelden"
      title="Herzlich Willkommen"
    >
      {impersonateUsers
        ? <React.Fragment>
            <Field
              {...{ InputProps }}
              component={AutoCompleteInput}
              customStyle={autoCompleteCustomStyle}
              fullWidth
              labelKey="email"
              name="userId"
              noMargin
              onKeyUp={event => debouncedLoadUsers(event.target.value)}
              options={impersonateUsers}
              placeholder="Anmelden als ..."
            />
          </React.Fragment>
        : <div>
            <Field
              {...{ InputProps }}
              className={classNames(styles.textField, className)}
              component={TextInput}
              fullWidth
              name="email"
              placeholder="Benutzername"
              type="email"
            />
            <Divider />
            <Field
              {...{ InputProps }}
              className={classNames(styles.textField, className)}
              component={TextInput}
              fullWidth
              name="password"
              placeholder="Passwort"
              type="password"
            />
            <Divider />
          </div>}
    </FormContainer>
  );
};

LoginForm.propTypes = {
  anyTouched: PropTypes.bool.isRequired,
  classes: PropTypes.object,
  className: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  impersonateUsers: PropTypes.array,
  invalid: PropTypes.bool.isRequired,
  onLoadUsers: PropTypes.func.isRequired
};

LoginForm = reduxForm({
  form: 'Login',
  validate: values => {
    const errors = {};

    if (!values.email) {
      errors.email = 'Bitte geben Sie eine E-Mail Adresse ein.';
    }

    if (!values.password) {
      errors.password = 'Bitte geben Sie ein Passwort ein.';
    }

    return errors;
  }
})(LoginForm);

export default connect(state => ({
  impersonateUsers: state.auth.impersonateUsers
}))(LoginForm);
