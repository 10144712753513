import { create } from 'rb-toolset/lib/redux';
import { middleware } from 'rb-toolset/lib/redux';
import thunk from 'redux-thunk';
import Cookies from 'universal-cookie';
import { apiCreator } from 'utils/api';
import getReducers from './modules';
import { applyMiddleware, compose } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";

export default function createStore({
  history,
  reduxData,
  serverCookies,
  store,
  reduxAsyncConnect
}) {
  const cookies = serverCookies || new Cookies();

  const api = apiCreator({
    cookies
  });

  const reduxAsyncConn = reduxAsyncConnect;

  const reducer = getReducers(history);
  return create(
    reducer,
    reduxData,
    [
      thunk.withExtraArgument({
        api,
        // On client we create new cookies instance
        cookies
      }),
      middleware.functionTree({ api, cookies, reduxAsyncConn }),
    ],
    history,
    store
  );
}
