function addFee(data, api) {
  return api().entity('policy-fee').post(data);
}

function updateFee(data, api) {
  return api().entity(`policy-fee`).patch(data);
}

export function deleteFeeAction({ input: { id }, api }) {
  return api().entity('policy-fee').delete({
    id
  });
}

export function updateFeeAction({ input: { fee }, api }) {
  return fee.id ? updateFee(fee, api) : addFee(fee, api);
}
