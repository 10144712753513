import React, { Component } from 'react';
import { CheckboxInput } from 'rb-toolset/lib/ui/FormControls';
import { Favorite, FavoriteBorder } from '@material-ui/icons';
import { Field } from 'redux-form';
import moment from 'moment';
import range from 'lodash/range';

export default class WeekendSelector extends Component {
  static propTypes = {};

  static defaultProps = {};

  render() {
    return range(7).map((_, index) =>
      <div key={index}>
        <Field
          component={CheckboxInput}
          checkedIcon={<Favorite />}
          uncheckedIcon={<FavoriteBorder />}
          name={`${index + 1}.isWeekend`}
          label={moment().isoWeekday(index + 1).format('dddd')}
        />
      </div>
    );
  }
}
