import React from 'react';
import PropTypes from 'prop-types';
import { asyncConnect } from 'redux-connect';
import { connect } from 'react-redux';
import { ChannelManager as CM } from 'rb-domain';
import { pick } from 'lodash';

import { getAllChannelManagers } from 'actions/ui/ui';

import { saveProperty } from 'signals/property';

import PaperContainer from 'components/PaperContainer/PaperContainer';

import ChannelManagerForm from './ChannelManagerForm';

const { ROOMBONUS } = CM.CODES;

let ChannelManager = (propertyId, savePropertySignal) => {
  const onSubmit = React.useCallback(formData => {
    const data = pick(formData, [
      'channelManagerPropertyId',
      'channelManagerCode'
    ]);

    // If extranet is used we do not need any API keys
    if (data.channelManagerCode === ROOMBONUS) {
      data.channelManagerApiKey = null;
      data.channelManagerPropertyId = null;
    }

    if (formData.channelManagerApiKeyNew) {
      data.channelManagerApiKey = formData.channelManagerApiKeyNew;
    }

    savePropertySignal({
      propertyId,
      data,
      fieldsToInject: Object.keys(data)
    });
  });

  return (
    <PaperContainer>
      <ChannelManagerForm {...{ onSubmit }} />
    </PaperContainer>
  );
};

ChannelManager.propTypes = {
  propertyId: PropTypes.number.isRequired,
  savePropertySignal: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  propertyId: parseInt(state.property.id, 10)
});

const mapDispatchToProps = { savePropertySignal: saveProperty };

ChannelManager = connect(mapStateToProps, mapDispatchToProps)(ChannelManager);

export default asyncConnect([
  {
    promise: options => {
      const {
        store: { dispatch }
      } = options;

      const promises = [];
      promises.push(dispatch(getAllChannelManagers()));
      return Promise.all(promises);
    }
  }
])(ChannelManager);
