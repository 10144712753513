import React from 'react';
import moment from 'moment';
import { FlashOn } from '@material-ui/icons';
import TablePagination from '@material-ui/core/TablePagination';
import yellow from '@material-ui/core/colors/yellow';
import PropTypes from 'prop-types';
import { Booking } from 'rb-domain';
import DataTable from 'rb-toolset/lib/ui/DataTable/DataTable';

import useStyles from './BookingListStyles';

const {
  SEND_TO_HOTEL,
  HOTEL_ACCEPTED,
  HOTEL_COUNTEROFFER,
  HOTEL_CANCELED,
  USER_TIMEOUT,
  HOTEL_TIMEOUT,
  USER_ACCEPTED,
  USER_CANCELED
} = Booking.STATUSES;

const BookingList = ({
  bookings,
  onSelect,
  onFilter,
  searchParams,
  totalBookings
}) => {
  const onColumnHeaderClick = React.useCallback(
    orderBy => {
      onFilter({ ...searchParams, orderBy });
    },
    [searchParams]
  );

  const onChangePage = React.useCallback(
    (event, page) => {
      onFilter({ ...searchParams, page: page + 1 });
    },
    [searchParams]
  );

  const onChangeRowsPerPage = React.useCallback(
    event => {
      onFilter({
        ...searchParams,
        limit: parseInt(event.target.value, 10),
        page: 1
      });
    },
    [searchParams]
  );

  const styles = useStyles();

  return (
    <React.Fragment>
      <DataTable
        columns={[
          {
            key: 'createdAt',
            label: 'Buchungsdatum',
            width: 40,
            customRender: booking =>
              <div className={styles.cell}>
                <FlashOn
                  style={{
                    color: Booking.isDirect(booking)
                      ? yellow.A700
                      : 'transparent'
                  }}
                />
                {moment(booking.createdAt).format('DD.MM.YYYY')}
              </div>
          },
          { key: 'bookingNumber', label: 'ID', width: 40 },
          {
            key: 'userName',
            label: 'Name',
            width: 40,
            customRender: booking =>
              <div>
                {booking.bookingUserData.firstName}{' '}
                {booking.bookingUserData.lastName}
              </div>
          },
          {
            key: 'userEmail',
            label: 'E-Mail',
            width: 40,
            customRender: booking =>
              <div>{booking.bookingUserData.bookingUser.email}</div>
          }
        ]}
        handleColumnHeaderClick={onColumnHeaderClick}
        headerSortable
        items={bookings}
        onRowClicked={onSelect}
        orderBy={searchParams.orderBy}
        rowCss={booking => {
          if (booking.status === USER_ACCEPTED) {
            return styles.booked;
          }
          if (
            [HOTEL_COUNTEROFFER, HOTEL_ACCEPTED, SEND_TO_HOTEL].indexOf(
              booking.status
            ) > -1
          ) {
            return styles.waiting;
          }
          if (
            [
              HOTEL_CANCELED,
              USER_CANCELED,
              HOTEL_TIMEOUT,
              USER_TIMEOUT
            ].indexOf(booking.status) > -1
          ) {
            return styles.canceled;
          }
        }}
      />
      <TablePagination
        {...{ onChangePage, onChangeRowsPerPage }}
        component="div"
        count={totalBookings}
        labelRowsPerPage=""
        page={searchParams.page - 1}
        rowsPerPage={searchParams.limit}
        rowsPerPageOptions={[15, 25, 50]}
        labelDisplayedRows={({ from, to, count }) =>
          `Buchungen: ${from} - ${to === -1 ? count : to} von ${count}`}
      />
    </React.Fragment>
  );
};

BookingList.propTypes = {
  bookings: PropTypes.array.isRequired,
  onFilter: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  searchParams: PropTypes.object.isRequired,
  totalBookings: PropTypes.number.isRequired
};

export default BookingList;
