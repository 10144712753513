import React from 'react';
import { reduxForm, Field, getFormValues, propTypes } from 'redux-form';
import {
  FormContainer,
  TextInput,
  SelectInput,
  ToggleInput,
  WYSIWYGEditor
} from 'rb-toolset/lib/ui/FormControls';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Calculations from 'components/Calculations/Calculations';

import { calculationType, feeType, renderType } from './TaxFormUtils';

const formContainerStyle = { display: 'flex', flexDirection: 'column' };

let TaxForm = ({ array, formValues, handleSubmit, onDeleteFee }) => {
  return (
    <FormContainer
      title="Besteuerungsgruppe"
      deleteText="Besteuerungsgruppe löschen"
      handleSubmit={handleSubmit}
      handleDelete={onDeleteFee}
    >
      <div style={formContainerStyle}>
        <Field component={TextInput} name="name" label="Name" />
        <Field component={ToggleInput} label="Is Default" name="isDefault" />
        <br />
        <Field
          component={SelectInput}
          options={feeType}
          name="type"
          label="Besteuerungstyp"
        />
        <br />
        <Field
          component={SelectInput}
          options={calculationType}
          name="calculationType"
          label="Berechnungsart"
        />
        <br />
        <Field
          component={SelectInput}
          options={renderType}
          name="calculationRenderType"
          label="Darstellungsoption"
        />
        <br />
        <Calculations {...{ array, formValues }} />
        <p>Beschreibung (optional):</p>
        <Field
          component={WYSIWYGEditor}
          height={150}
          name="description"
          placeholder="Beschreiben Sie die Besteuerungsgruppe"
        />
      </div>
    </FormContainer>
  );
};

TaxForm.propTypes = {
  ...propTypes,
  formValues: PropTypes.object.isRequired,
  tax: PropTypes.object,
  onDeleteFee: PropTypes.func.isRequired
};

TaxForm = connect(state => ({ formValues: getFormValues('Tax')(state) || {} }))(
  TaxForm
);

export default reduxForm({
  form: 'Tax',
  enableReinitialize: true,
  validate: (values = {}) => {
    const errors = {};

    if (values.name === undefined) {
      errors.name = 'Bitte geben Sie einen Namen an';
    }
    if (values.feeType === undefined) {
      errors.feeType = 'Bitte geben Sie einen Besteuerungstyp an';
    }
    if (values.calculationType === undefined) {
      errors.calculationType = 'Bitte geben Sie eine Berechnungsart an';
    }
    if (values.renderType === undefined) {
      errors.renderType = 'Bitte geben Sie eine Darstellungsoption an';
    }
    return errors;
  }
})(TaxForm);
