import React from 'react';
import PropTypes from 'prop-types';
import { asyncConnect } from 'redux-connect';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Property } from 'rb-domain';
import { find } from 'lodash';

import { BackLink } from 'rb-toolset/lib/ui/FormControls';

import {
  deleteRoom as deleteRoomSignal,
  deleteRoomOption as deleteRoomOptionSignal,
  loadRoom as loadRoomSignal,
  updateRoom as updateRoomSignal
} from 'signals/room';

import {
  deleteRoomPhoto as deleteRoomPhotoAction,
  saveRoomPhotoPosition as saveRoomPhotoPositionAction,
  uploadRoomPhoto as uploadRoomPhotoAction
} from 'actions/photos/photos';

import PaperContainer from 'components/PaperContainer/PaperContainer';
import HelpboxContainer from 'components/HelpboxContainer/HelpboxContainer';
import { PhotosForm } from 'components/Forms';

import RoomForm from './RoomForm/RoomForm';

let Room = ({
  deleteRoom,
  deleteRoomOption,
  deleteRoomPhoto,
  isUploading,
  loadRoom,
  photos,
  property,
  propertyId,
  room,
  saveRoomPhotoPosition,
  updateRoom,
  uploadRoomPhoto
}) => {
  const { push } = useHistory();
  const onSubmit = React.useCallback(formData => {
    const {
      name,
      occupancyMax,
      occupancyMin,
      occupancyStd,
      id,
      options,
      description
    } = formData;

    updateRoom({
      propertyId,
      roomData: {
        name,
        description,
        occupancyMax,
        occupancyMin,
        occupancyStd,
        propertyId,
        propertyRoomId: id
      },
      options
    });
  });

  const onDelete = React.useCallback(({ roomId }) =>
    deleteRoom({
      propertyRoomId: roomId,
      propertyId
    }).then(() => {
      push(`/${propertyId}/housing/rooms`);
    })
  );

  const onDeleteOption = React.useCallback(
    ({ roomId, roomOptionId }) =>
      roomOptionId && deleteRoomOption({ roomId, roomOptionId })
  );

  const onUploadImage = React.useCallback(uuid =>
    uploadRoomPhoto(uuid, {
      propertyId,
      propertyRoomId: room.id
    }).then(() => {
      loadRoom({ propertyId });
    })
  );

  const onChangeImage = React.useCallback(photo =>
    saveRoomPhotoPosition(photo.url, photo.index)
  );

  const onDeleteImage = React.useCallback(photo =>
    deleteRoomPhoto(photo.url, propertyId).then(() => {
      loadRoom({ propertyId });
    })
  );

  return (
    <div>
      <PaperContainer
        helpBox={[
          <HelpboxContainer headline="Zimmerkategorie">
            {`Bitte tragen Sie den Namen für Ihre Zimmerkategorie ein.
              Zusätzlich haben Sie die Möglichkeit eine Zimmerbeschreibung (max.
              200 Zeichen) für die gewählte Kategorie einzupflegen. Wählen Sie
              aus den drei Pickboxen die Min. / Max. / Standardbelegung je
              Gästezimmerkategorie.`}
          </HelpboxContainer>,
          <HelpboxContainer headline="Ratenpläne">
            {`Erstellen Sie nun die passenden Ratenpläne zu Ihrer
              Zimmerkategorie und fügen ein Foto mit dem Plus Symbol unter
              "Zimmerfoto" hinzu.`}
            <br />
            <br />
            {`Bitte verknüpfen Sie Ihren jeweiligen Raten-/Zimmertyp mit den
              entsprechenden bereits angelegten Stornierungsrichtlinien,
              Buchungsgarantien sowie sonstigen zusätzlichen Besteuerungen.`}
            <br />
            <br />
            <strong>Als Beispiel</strong>
            {`: Sie möchten die Kategorie "Classic
              Doppelzimmer inkl. Frühstück" erstellen. Vorab haben Sie die
              Gästezimmerkategorie Classic Doppelzimmer mit einer
              Minimalbelegung von 1, Maximalbelegung von 3 (z.B. mit einem
              Extrabett) und einer Standardbelegung von 2 angelegt. Nun
              erstellen Sie den Ratenplan für Ihr Classic DZ inkl. Frühstück.
              Erstellen Sie einen kurzen Beschreibungstext (max. 200 Zeichen)
              und geben Sie die einzelnen Zimmerraten je nach Belegung (s.
              Bettensymbole links) ein. Des Weiteren haben Sie die Möglichkeit
              eine Wochenendrate (Übernachtungen jeweils Freitag bis Montag) bei
              Ihrem Rateplan anzulegen.`}
          </HelpboxContainer>
        ]}
      >
        <div>
          <BackLink to={`/${propertyId}/housing/rooms`}>
            « zurück zur Zimmer-Übersicht
          </BackLink>
          <RoomForm
            onSubmit={onSubmit}
            initialValues={{
              ...room,
              options: room.options
                ? Object.values(room.options).map(option => ({
                    ...option,
                    // We only support one fee of each type:
                    // [0] => tourist tax fee
                    // [1] => cleanup fee
                    policyFeesIds: [
                      find(option.policyFees, {
                        type: Property.Policy.POLICY_FEE_TYPE.VISITOR_TAX
                      }),
                      find(option.policyFees, {
                        type: Property.Policy.POLICY_FEE_TYPE.ROOM_CLEANUP
                      })
                    ],
                    weekend: option.pricesWeekend.length > 0
                  }))
                : []
            }}
            cancellationTerms={property.cancellationGroups.map(group => ({
              value: group.id,
              label: group.name,
              isDefault: group.isDefault
            }))}
            guarantees={property.guarantees.map(guarantee => ({
              value: guarantee.id,
              label: guarantee.name,
              isDefault: guarantee.isDefault
            }))}
            cleanupFees={property.fees
              .filter(
                fee => fee.type === Property.Policy.POLICY_FEE_TYPE.ROOM_CLEANUP
              )
              .map(fee => ({
                value: fee.id,
                label: fee.name,
                isDefault: fee.isDefault
              }))}
            vistorFees={property.fees
              .filter(
                fee => fee.type === Property.Policy.POLICY_FEE_TYPE.VISITOR_TAX
              )
              .map(fee => ({
                value: fee.id,
                label: fee.name,
                isDefault: fee.isDefault
              }))}
            onDelete={onDelete}
            onDeleteOption={onDeleteOption}
          />
        </div>
        {room.id &&
          <PhotosForm
            title={`Fotos für das Zimmer ${room.name}`}
            onUpload={onUploadImage}
            onChange={onChangeImage}
            onDelete={onDeleteImage}
            isUploading={isUploading}
            photos={photos}
          />}
      </PaperContainer>
    </div>
  );
};

Room.propTypes = {
  deleteRoom: PropTypes.func.isRequired,
  deleteRoomOption: PropTypes.func.isRequired,
  deleteRoomPhoto: PropTypes.func.isRequired,
  isUploading: PropTypes.bool,
  loadRoom: PropTypes.func.isRequired,
  photos: PropTypes.array.isRequired,
  property: PropTypes.object.isRequired,
  propertyId: PropTypes.number.isRequired,
  room: PropTypes.object.isRequired,
  saveRoomPhotoPosition: PropTypes.func.isRequired,
  updateRoom: PropTypes.func.isRequired,
  uploadRoomPhoto: PropTypes.func.isRequired
};

const mapStateToProps = (state, options) => ({
  photos: options.match.params.id && state.rooms.availableRooms
    ? state.rooms.availableRooms[options.match.params.id].photos
    : [],
  isUploading: state.ui.isUploading,
  room: options.match.params.id
    ? state.rooms.availableRooms[options.match.params.id]
    : {},
  property: state.property,
  propertyId: state.property.id
});

const mapDispatchToProps = {
  deleteRoom: deleteRoomSignal,
  deleteRoomOption: deleteRoomOptionSignal,
  deleteRoomPhoto: deleteRoomPhotoAction,
  loadRoom: loadRoomSignal,
  saveRoomPhotoPosition: saveRoomPhotoPositionAction,
  updateRoom: updateRoomSignal,
  uploadRoomPhoto: uploadRoomPhotoAction
};

Room = connect(mapStateToProps, mapDispatchToProps)(Room);

export default asyncConnect([
  {
    promise: options => {
      const { store: { dispatch } } = options;

      const propertyId = parseInt(options.match.params.propertyId, 10);

      const promises = [];
      promises.push(dispatch(loadRoomSignal({ propertyId })));
      return Promise.all(promises);
    }
  }
])(Room);
