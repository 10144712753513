import React from 'react';
import { FormContainer } from 'rb-toolset/lib/ui/FormControls';
import Message from 'rb-toolset/lib/ui/Message/Message';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PaperContainer from 'components/PaperContainer/PaperContainer';

import { authStripe$ } from './redux/signals';

const StripeAuth = ({
  authenticating,
  authSuccess,
  authStripeSignal,
  error,
  location,
  propertyId
}) => {
  React.useEffect(() =>
    authStripeSignal({
      query: location.query,
      propertyId
    })
  );

  return (
    <PaperContainer>
      <FormContainer
        fullWidth
        title="Stripe Verknüpfung"
        isRefreshing={authenticating}
      >
        {!authenticating &&
          <div>
            {error &&
              <Message
                title="Probleme bei der Verknüpfung mit Stripe"
                type={Message.TYPES.WARNING}
              >
                <div>
                  Fehler: {error.error}<br />
                  Beschreibung: {error.description}
                </div>
              </Message>}
            {!authSuccess &&
              !error &&
              <Message type={Message.TYPES.ERROR}>
                Ein Fehler ist aufgetreten (möglicher CSRF).
              </Message>}
            {authSuccess &&
              <Message>
                Ihr Account wurde erfolgreich verknüpft.
              </Message>}
          </div>}
      </FormContainer>
    </PaperContainer>
  );
};

StripeAuth.propTypes = {
  authenticating: PropTypes.bool.isRequired,
  authStripeSignal: PropTypes.func.isRequired,
  authSuccess: PropTypes.bool.isRequired,
  error: PropTypes.object,
  location: PropTypes.object,
  propertyId: PropTypes.number.isRequired
};

export default connect(
  state => ({
    authenticating: state.payment.authenticating,
    error: state.payment.error,
    authSuccess: state.payment.authSuccess,
    propertyId: state.property.id
  }),
  { authStripeSignal: authStripe$ }
)(StripeAuth);
