// import * as actions from '../actionTypes';
import { toggleNotification } from 'actions/ui/ui';
import { getPropertyById } from 'actions/property/property';

// ========== Internal functions ========== //

export function uploadPhotoRaw(file) {
  return (dispatch, getState, { api }) => {
    const data = new FormData();
    data.append('file', file);

    return api().entity('file/upload').post(data).then(result => result.uuid);
  };
}

// ========== Custom Bonus Photos ========== //

export function saveCustomBonusPhoto({
  input: { bonus: { propertyId, code, uuid } },
  api
}) {
  return api().entity(`property-bonus-photo/`).post({
    uuid,
    propertyId,
    code,
    position: 0
  });
}

export function deleteCustomBonusPhoto({ input: { url }, api }) {
  return api().entity('property-bonus-photo').delete({
    url
  });
}

// ========== Property Photos ========== //
function createPhotoActions(endpoint) {
  const actions = {
    savePhotoPosition: (url, position) => {
      return (dispatch, getState, { api }) => {
        return api()
          .entity(endpoint)
          .patch({
            url,
            position
          })
          .then(() =>
            dispatch(toggleNotification('Fotos wurden neu angeordnet'))
          );
      };
    },
    savePhoto: (photoId, params) => {
      return (dispatch, getState, { api }) => {
        return api()
          .entity(endpoint)
          .post({
            ...params,
            uuid: photoId
          })
          .then(() => dispatch(toggleNotification('Foto wurde hinzugefügt')));
      };
    },
    uploadPhoto: (uuid, params) => {
      return dispatch =>
        Promise.resolve(dispatch(actions.savePhoto(uuid, params)));
    },
    deletePhoto: (url, propertyId) => {
      return (dispatch, getState, { api }) => {
        return api()
          .entity(endpoint)
          .delete({
            url
          })
          .then(() => {
            dispatch(getPropertyById(propertyId));
            dispatch(toggleNotification('Foto wurde gelöscht'));
          });
      };
    }
  };

  return actions;
}

export const savePropertyPhotoPosition = createPhotoActions('property-photo')
  .savePhotoPosition;
export const uploadPropertyPhoto = createPhotoActions('property-photo')
  .uploadPhoto;
export const deletePropertyPhoto = createPhotoActions('property-photo')
  .deletePhoto;

export const saveRoomPhotoPosition = createPhotoActions('property-room-photo')
  .savePhotoPosition;
export const uploadRoomPhoto = createPhotoActions('property-room-photo')
  .uploadPhoto;
export const deleteRoomPhoto = createPhotoActions('property-room-photo')
  .deletePhoto;
