import { isEmpty, isNull } from 'lodash';
import jwtDecode from 'jwt-decode';
import { User } from 'rb-domain';
import { RbError } from 'rb-domain';
import moment from 'moment';

import { createQuery } from 'utils/url';

import * as actions from '../actionTypes';

const { PROPERTY_MANAGER } = User.ROLES;

const REFRESH_INTERVAL = 60 * 2 * 1000;
const WARNING_INTERVAL = 60 * 50 * 1000;
const COOKIE_NAME = 'x-auth-token';
let refreshIntervalRef;
let warningIntervalRef;

function readToken(cookies) {
  const token = cookies.get(COOKIE_NAME);

  return isNull(token) || isEmpty(token) ? null : token;
}

function readJwt(cookies) {
  const token = readToken(cookies);

  try {
    return jwtDecode(token);
  } catch (e) {
    return null;
  }
}

export function loadUsersToImpersonateAction({
  input: { impersonateEmail },
  api
}) {
  return api()
    .entity(
      `user/list?${createQuery({
        role: PROPERTY_MANAGER,
        limit: 150,
        searchEmail: impersonateEmail
      })}`
    )
    .get()
    .then(response => ({ users: response.items }));
}

export function saveTokenToCookie({ input: { token }, cookies }) {
  // console.info('Writing new token to cookie');
  cookies.set(COOKIE_NAME, token, { path: '/' });
}

export function loginSuccessAction({ input: { token }, path }) {
  const user = jwtDecode(token);

  if (user.role === PROPERTY_MANAGER) {
    return path.propertyManager({ user });
  }

  return path.admin();
}

export function impersonateAsAction({ input: { userId }, path, api }) {
  return api()
    .entity('login/impersonate')
    .post({
      userId
    })
    .then(({ token }) => path.success({ token }))
    .catch(path.error);
}

export function refreshTokenAction({ path, api, cookies }) {
  const user = readJwt(cookies);

  if (!user) {
    return path.error();
  }

  if (moment().add(10, 'minutes').isAfter(moment.unix(user.exp))) {
    return api()
      .entity('login/refresh')
      .get()
      .then(({ token }) => path.success({ token }))
      .catch(path.error);
  }

  // console.info('No need to refresh token ...');
  return path.success({
    token: readToken(cookies)
  });
}

function cancelTimeouts() {
  if (refreshIntervalRef) {
    // console.info('Cancelling intervals');
    clearInterval(refreshIntervalRef);
    clearInterval(warningIntervalRef);
  }
}

export function tokenRefresherAction({ dispatch, execution }) {
  cancelTimeouts();

  // console.info('Starting token refresher ....');

  refreshIntervalRef = setInterval(() => {
    // console.info('Refreshing token ....');
    execution.retry();
  }, REFRESH_INTERVAL);

  warningIntervalRef = setInterval(() => {
    // console.info('In 1 Minute logging out');
    dispatch({
      type: actions.UI_TOGGLE_LOGOUT_WARNING,
      payload: true
    });
  }, WARNING_INTERVAL);
}

export function reAuthenticateAction({ path, cookies }) {
  const token = cookies.get(COOKIE_NAME);

  // console.log(token);

  if (!isEmpty(token) && !isNull(token)) {
    return path.hasToken();
  }

  return path.noToken();
}

export function loginAction({
  input: { email, password },
  path,
  api,
  cookies
}) {
  cookies.set(COOKIE_NAME, null, { path: '/' });

  return api()
    .entity('login')
    .post({
      email,
      password
    })
    .then(({ token }) => {
      return path.success({ token });
    })
    .catch(RbError.User.InvalidCredentialsError, path.error);
}

export function logout({ cookies }) {
  // console.log('Logging out');
  if (cookies.get(COOKIE_NAME)) {
    cookies.remove(COOKIE_NAME, { path: '/' });
  }

  cancelTimeouts();
}
