import {
  AddProperty,
  Amenities,
  App,
  Availability,
  BonusCustomize,
  Bonuses,
  BonusSettings,
  BookingDetail,
  Bookings,
  CancellationGroup,
  ChannelManager,
  Children,
  Contact,
  Dashboard,
  Description,
  GuestsDetails,
  Housing,
  Location,
  Login,
  Logout,
  Partnership,
  Photos,
  Policies,
  Profile,
  Property,
  PropertySelector,
  Reservation,
  Room,
  Rooms,
  Scaffold,
  Tax,
  Terms
} from 'pages';

import { Payment, StripeAuth } from 'features';

import { connectedReduxRedirect } from 'redux-auth-wrapper/history4/redirect';
import { routerActions } from 'connected-react-router';

const userIsAuthenticated = connectedReduxRedirect({
  redirectPath: '/login',
  authenticatedSelector: state => {
    return state.auth && state.auth.user && state.auth.loggedIn;
  },
  redirectAction: routerActions.replace, // the redux action to dispatch for redirect
  wrapperDisplayName: 'UserIsAuthenticated' // a nice name for this auth check
});

const userIsNotAuthenticated = connectedReduxRedirect({
  redirectPath: '/',
  authenticatedSelector: state => {
    return (
      state.auth === undefined ||
      state.auth.user === undefined ||
      !state.auth.loggedIn
    );
  }, // how to get the user state
  redirectAction: routerActions.replace, // the redux action to dispatch for redirect
  wrapperDisplayName: 'UserIsNotAuthenticated', // a nice name for this auth check
  allowRedirectBack: false
});

export const getRoutes = () =>
  Array.from([
    {
      path: '/',
      component: App,
      routes: [
        {
          path: '/login',
          component: userIsNotAuthenticated(Login)
        },
        {
          path: '/logout',
          component: Logout
        },
        {
          component: userIsAuthenticated(AddProperty),
          exact: true,
          path: '/addProperty'
        },
        {
          component: userIsAuthenticated(PropertySelector),
          exact: true,
          path: '/'
        },
        {
          component: userIsAuthenticated(StripeAuth),
          exact: true,
          path: '/stripe_auth'
        },
        {
          path: '/',
          component: userIsAuthenticated(Scaffold),
          routes: [
            {
              path: '/:propertyId',
              component: Property,
              routes: [
                {
                  path: '/:propertyId',
                  component: Dashboard,
                  exact: true
                },
                {
                  path: '/:propertyId/dashboard',
                  component: Dashboard
                },
                {
                  path: '/:propertyId/availability',
                  component: Availability
                },
                {
                  path: '/:propertyId/guestsDetails',
                  component: GuestsDetails
                },
                {
                  path: '/:propertyId/bookings',
                  component: Bookings,
                  exact: true
                },
                {
                  path: '/:propertyId/bookings/:id',
                  component: BookingDetail
                },
                {
                  path: '/:propertyId/bonuses',
                  component: Bonuses,
                  exact: true
                },
                {
                  path: '/:propertyId/bonuses/:code/customize',
                  component: BonusCustomize
                },
                {
                  path: '/:propertyId/bonuses/:code/settings',
                  component: BonusSettings
                },
                {
                  path: '/:propertyId/channel-manager',
                  component: ChannelManager
                },
                {
                  path: '/:propertyId/partnership',
                  component: Partnership
                },
                {
                  path: '/:propertyId/profile',
                  component: Profile,
                  exact: true
                },
                {
                  path: '/:propertyId/profile/contact',
                  component: Contact
                },
                {
                  path: '/:propertyId/payment',
                  component: Payment
                },
                {
                  path: '/:propertyId/housing',
                  component: Housing,
                  exact: true
                },
                {
                  path: '/:propertyId/housing/description',
                  component: Description,
                  exact: true
                },
                {
                  path: '/:propertyId/housing/photos',
                  component: Photos,
                  exact: true
                },
                {
                  path: '/:propertyId/housing/policies',
                  component: Policies,
                  exact: true
                },
                {
                  path:
                    '/:propertyId/housing/policies/cancellationGroup/edit/:id',
                  component: CancellationGroup,
                  exact: true
                },
                {
                  path: '/:propertyId/housing/policies/cancellationGroup/new',
                  component: CancellationGroup,
                  exact: true
                },
                {
                  path: '/:propertyId/housing/policies/reservation/edit/:id',
                  component: Reservation,
                  exact: true
                },
                {
                  path: '/:propertyId/housing/policies/reservation/new',
                  component: Reservation,
                  exact: true
                },
                {
                  path: '/:propertyId/housing/terms',
                  component: Terms,
                  exact: true
                },
                {
                  path: '/:propertyId/housing/policies/tax/edit/:id',
                  component: Tax,
                  exact: true
                },
                {
                  path: '/:propertyId/housing/policies/tax/new',
                  component: Tax,
                  exact: true
                },
                {
                  path: '/:propertyId/housing/location',
                  component: Location,
                  exact: true
                },
                {
                  path: '/:propertyId/housing/amenities',
                  component: Amenities,
                  exact: true
                },
                {
                  path: '/:propertyId/housing/children',
                  component: Children,
                  exact: true
                },
                {
                  path: '/:propertyId/housing/rooms',
                  component: Rooms,
                  exact: true
                },
                {
                  path: '/:propertyId/housing/rooms/edit/:id',
                  component: Room,
                  exact: true
                },
                {
                  path: '/:propertyId/housing/rooms/new',
                  component: Room,
                  exact: true
                }
              ]
            }
          ]
        }
      ]
    }
  ]);

// let test = store.getState();
// console.log(test)
// return (
//       <Switch>
//         <Route exact path="/" component={userIsNotAuthenticated(Login)} />
//         <Route path="/login" component={userIsNotAuthenticated(Login)} />
//         <Route path="/logout" component={Logout} />
//         <Route path="/addProperty" component={AddProperty} />
//         {/*<AuthRoute exact path="/scaffold" component={Scaffold}>*/}
//             {/*<AuthRoute exact path="/scaffold" component={PropertySelector} />*/}
//             {/*<AuthRoute path="/stripe_auth" component={StripeAuth} />*/}
//             {/*<AuthRoute path="/:propertyId" component={Property}>*/}
//               {/*<AuthRoute path="/dashboard" component={Dashboard} />*/}
//               {/*<AuthRoute path="/availability" component={Availability} />*/}
//               {/*<AuthRoute path="/housing">*/}
//                 {/*<AuthRoute exact path="/" component={Housing} />*/}
//                 {/*<AuthRoute path="/location" component={Location} />*/}
//                 {/*<AuthRoute path="/description" component={Description} />*/}
//                 {/*<AuthRoute path="/amenities" component={Amenities} />*/}
//                 {/*<AuthRoute path="/photos" component={Photos} />*/}
//                 {/*<AuthRoute path="/terms" component={Terms} />*/}
//                 {/*<AuthRoute exact path="/rooms">*/}
//                   {/*<AuthRoute exact path="/" component={Rooms} />*/}
//                   {/*<AuthRoute path="/edit/:id" component={Room} />*/}
//                   {/*<AuthRoute path="/new" component={Room} />*/}
//                 {/*</AuthRoute>*/}
//                 {/*<AuthRoute path="/children" component={Children} />*/}
//                 {/*<AuthRoute exact path="/policies" component={Policies}>*/}
//                   {/*<AuthRoute exact path="/" component={Policies} />*/}
//                   {/*<AuthRoute path="/cancellationGroup">*/}
//                     {/*<AuthRoute path="/edit/:id" component={CancellationGroup} />*/}
//                     {/*<AuthRoute path="/new" component={CancellationGroup} />*/}
//                   {/*</AuthRoute>*/}
//                   {/*<AuthRoute path="/reservation">*/}
//                     {/*<AuthRoute path="/edit/:id" component={Reservation} />*/}
//                     {/*<AuthRoute path="/new" component={Reservation} />*/}
//                   {/*</AuthRoute>*/}
//                   {/*<AuthRoute path="/tax">*/}
//                     {/*<AuthRoute path="/edit/:id" component={Tax} />*/}
//                     {/*<AuthRoute path="/new" component={Tax} />*/}
//                   {/*</AuthRoute>*/}
//                 {/*</AuthRoute>*/}
//               {/*</AuthRoute>*/}
//               {/*/!*<AuthorizedRoute path="/bookings">*!/*/}
//               {/*/!**!/*/}
//               {/*/!*<AuthorizedRoute exact path="/" component={Bookings} />*!/*/}
//               {/*/!*<AuthorizedRoute path="/:id" component={BookingDetail} />*!/*/}
//               {/*/!**!/*/}
//               {/*/!*</AuthorizedRoute>*!/*/}
//               {/*<AuthRoute path="/bonuses">*/}
//                 {/*<AuthRoute exact path="/" component={Bonuses} />*/}
//                 {/*<AuthRoute path="/:code/customize" component={BonusCustomize} />*/}
//                 {/*<AuthRoute path="/settings" component={BonusSettings} />*/}
//               {/*</AuthRoute>*/}
//               {/*<AuthRoute path="/channel-manager" component={ChannelManager} />*/}
//               {/*<AuthRoute path="/profile">*/}
//                 {/*<AuthRoute exact path="/" component={Profile} />*/}
//                 {/*<AuthRoute path="/contact" component={Contact} />*/}
//               {/*</AuthRoute>*/}
//               {/*<AuthRoute path="/partnership" component={Partnership} />*/}
//               {/*<AuthRoute path="/payment" component={Payment} />*/}
//             {/*</AuthRoute>*/}
//         {/*</AuthRoute>*/}
//       </Switch>
// );
