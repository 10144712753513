import React from 'react';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { FormContainer } from 'rb-toolset/lib/ui/FormControls';
import { connect } from 'react-redux';
import { TextInput, PhotoUploadInput } from 'rb-toolset/lib/ui/FormControls';
import PropTypes from 'prop-types';
import { Property, Bonus } from 'rb-domain';
import { pick } from 'lodash';
import { uploadPhotoRaw } from 'actions/photos/photos';

import useStyles from './BonusCustomizeFormStyles';

let BonusCustomizeForm = ({
  bonus,
  boniMode,
  formValues = {},
  handleSubmit,
  invalid,
  onUpload,
  onPhotoReset,
  uploadPhoto
}) => {
  const isCustomPhoto = bonus.customPhotos || formValues.uuid;
  const isDayMode = boniMode === Property.BONUS_MODES.DAY;

  const styles = useStyles();

  return (
    <FormContainer
      invalid={invalid}
      title={`Customizing von Bonus: ${bonus.title}`}
      {...{ handleSubmit }}
    >
      <div className={styles.container}>
        <div className={styles.formContainer}>
          <Field
            component={TextInput}
            fullWidth
            name="title"
            label="Bonusname"
          />

          {!isDayMode &&
            <Field
              component={TextInput}
              fullWidth
              type="number"
              name="highValue"
              label="Bonuswert in €"
            />}
          {/* <Field component={TextInput} fullWidth name="lowValue" label="Low value"/> */}

          {isDayMode &&
            <Field
              component={TextInput}
              fullWidth
              name="minimumNumberOfNights"
              label="Mindestanzahl an Nächten"
            />}
        </div>
        <div className={styles.imageContainer}>
          <div className={styles.tile}>
            <Field
              {...{ uploadPhoto }}
              component={PhotoUploadInput}
              initialPhotoUrl={Bonus.getBonusPhotoUrl(bonus)}
              onChange={(event, value) => {
                if (value) {
                  onUpload(value);
                } else if (isCustomPhoto && value === null) {
                  onPhotoReset(bonus.photos[0].url);
                }
              }}
              withPreview
              withCropper
              title={isCustomPhoto ? 'Eigenes Bild' : 'Standardbild'}
              name="uuid"
            />
          </div>
        </div>
      </div>
      <Field
        component={TextInput}
        fullWidth
        name="teaserDescription"
        rows={2}
        multiline
        label="Kurzbeschreibung"
      />
      <Field
        component={TextInput}
        fullWidth
        name="description"
        rows={3}
        multiline
        label="Beschreibung"
      />
    </FormContainer>
  );
};

BonusCustomizeForm.propTypes = {
  boniMode: PropTypes.string.isRequired,
  bonus: PropTypes.object.isRequired,
  formValues: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  onPhotoReset: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  uploadPhoto: PropTypes.func.isRequired
};

BonusCustomizeForm = connect(
  state => ({
    formValues: getFormValues('BonusCustomization')(state)
  }),
  { uploadPhoto: uploadPhotoRaw }
)(BonusCustomizeForm);

BonusCustomizeForm = reduxForm({
  form: 'BonusCustomization',
  validate: values => {
    const errors = {};

    if ((!values.highValue && values.highValue !== 0) || values.highValue < 0) {
      errors.highValue = 'Bitte geben Sie einen Bonuswert an.';
    }

    return errors;
  }
})(BonusCustomizeForm);

export default connect(state => ({
  bonus: state.bonus.customizeBonus,
  boniMode: state.property.boniMode,
  initialValues: pick(state.bonus.customizeBonus, [
    'code',
    'propertyId',
    'lowValue',
    'highValue',
    'minimumNumberOfNights',
    'teaserDescription',
    'description',
    'title'
  ])
}))(BonusCustomizeForm);
