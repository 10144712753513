import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { AutoCompleteInput } from 'rb-toolset/lib/ui/FormControls';

// const STREET_NUMER = 'street_number';
// const ROUTE = 'route';
// const LOCALITY = 'locality';
// const ADMINISTRATIVE_AREA_LEVEL_1 = 'administrative_area_level_1';
// const ADMINISTRATIVE_AREA_LEVEL_2 = 'administrative_area_level_2';
// const COUNTRY = 'country';
// const POSTAL_CODE = 'postal_code';

export default class PlacesAutocomplete extends Component {
  static propTypes = {
    input: PropTypes.object,
    label: PropTypes.string,
    countryCode: PropTypes.string,
    onPlaceSelected: PropTypes.func.isRequired
  };

  static defaultProps = {};

  constructor() {
    super();
    this.state = {
      predictions: [],
      suggestions: []
    };

    this.handleUpdateInput = this.handleUpdateInput.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount() {
    this.autocompleteService = new window.google.maps.places
      .AutocompleteService();
    this.placesService = new window.google.maps.places.PlacesService(
      document.createElement('div')
    );
  }

  extractAddressComponents(components) {
    return components.reduce((acc, component) => {
      return {
        ...acc,
        [component.types[0]]: component.long_name
      };
    }, {});
  }

  handleSelect(item) {
    const { input, onPlaceSelected } = this.props;

    this.placesService.getDetails(
      {
        placeId: item.place_id
      },
      place => {
        const addressComponents = this.extractAddressComponents(
          place.address_components
        );
        input.onChange({
          address:
            addressComponents.route +
              ' ' +
              (addressComponents.street_number || ''),
          location: place.geometry.location.toJSON(),
          addressComponents
        });
        onPlaceSelected();
      }
    );
  }

  handleUpdateInput(value) {
    setTimeout(() => {
      if (value) {
        const componentRestrictions = this.props.countryCode
          ? {
              country: this.props.countryCode
            }
          : {};

        this.autocompleteService.getPlacePredictions(
          {
            input: value,
            types: ['address'],
            componentRestrictions
          },
          (predictions, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              this.setState({
                predictions: predictions,
                suggestions: predictions
              });
            } else {
              this.setState({
                suggestions: []
              });
            }
          }
        );
      } else {
        this.setState({
          suggestions: []
        });
      }
    });
  }

  render() {
    const { suggestions } = this.state;
    const { input, label } = this.props;

    return (
      <div>
        <AutoCompleteInput
          label={label}
          dataSourceConfig={{ text: 'description', value: 'place_id' }}
          searchText={input.value.address}
          dataSource={suggestions}
          onUpdateInput={this.handleUpdateInput}
          onNewRequest={this.handleSelect}
          fullWidth
        />
      </div>
    );
  }
}
