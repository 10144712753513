import * as actions from '../actionTypes';
// import {toggleNotification} from 'actions/ui/ui';
import { toggleNotification } from 'actions/ui/ui';

export function getChildageGroups(propertyId) {
  return (dispatch, getState, { api }) => {
    return api()
      .entity(`property-child-age-group/list?propertyId=${propertyId}`)
      .get()
      .then(result =>
        dispatch({
          type: actions.API_PROPERTY_CHILDAGES_LOADED,
          payload: result.items
        })
      );
  };
}

export function saveChildageGroups(propertyId, groups) {
  return (dispatch, getState, { api }) => {
    return api()
      .entity('property-child-age-group')
      .put({ propertyId, groups })
      .then(() => {
        return dispatch(toggleNotification('Altersgruppen wurden gespeichert'));
      });
  };
}

export function deleteChildageGroup(propertyId, childAgeGroupCode) {
  return (dispatch, getState, { api }) => {
    return api()
      .entity('property-child-age-group')
      .delete({ propertyId, childAgeGroupCode });
  };
}
