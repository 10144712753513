import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { asyncConnect } from 'redux-connect';
import { pickBy, mapValues, mapKeys } from 'lodash';

import {
  getAllAmenityTypes,
  getAmenitiesForProperty,
  toggleAmenities as toggleAmenitiesAction
} from 'actions/property/property-amenities';

import PaperContainer from 'components/PaperContainer/PaperContainer';
import HelpboxContainer from 'components/HelpboxContainer/HelpboxContainer';

import AmenitiesForm from './AmenitiesForm/AmenitiesForm';

let Amenities = ({ amenities, propertyId, toggleAmenities }) => {
  const onSubmit = React.useCallback(formData => {
    const currentAmenities = mapValues(mapKeys(amenities, 'id'), () => true);
    const aChanges = pickBy(formData.amenities, (flag, id) => {
      return currentAmenities[id] !== flag;
    });

    toggleAmenities(propertyId, aChanges);
  });

  return (
    <PaperContainer
      helpBox={
        <HelpboxContainer>
          Um Ihr Hotel bestmöglich auf unserer Website darzustellen sowie für
          unsere zukünftige Detailsuche,
          benötigen wir jegliche Angaben zu Ihrer Hotelausstattung. Klicken Sie
          einfach die passenden Felder
          an. Eine Mehrfacheingabe ist möglich.
        </HelpboxContainer>
      }
    >
      <AmenitiesForm {...{ onSubmit }} />
    </PaperContainer>
  );
};

Amenities.propTypes = {
  toggleAmenities: PropTypes.func.isRequired,
  propertyId: PropTypes.number.isRequired,
  amenities: PropTypes.array.isRequired
};

Amenities = connect(
  state => ({
    // propertyId: parseInt(state.property.id, 10),
    amenities: state.reduxAsyncConnect.amenities[0].payload
  }),
  { toggleAmenities: toggleAmenitiesAction }
)(Amenities);

export default asyncConnect([
  {
    key: 'amenityTypes',
    promise: options => {
      const { store: { dispatch } } = options;
      const promises = [];
      promises.push(dispatch(getAllAmenityTypes()));
      return Promise.all(promises);
    }
  },
  {
    key: 'amenities',
    promise: options => {
      const { store: { dispatch } } = options;
      const promises = [];
      promises.push(
        dispatch(
          getAmenitiesForProperty(parseInt(options.match.params.propertyId, 10))
        )
      );
      return Promise.all(promises);
    }
  },
  {
    key: 'propertyId',
    promise: options => {
      return Promise.resolve(parseInt(options.match.params.propertyId, 10));
    }
  }
])(Amenities);
