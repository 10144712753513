export const matchFilterValue = (list, value) => {
  if (!value) {
    return null;
  }

  const filteredList = list.filter(item => {
    const normalizedName = item.name.toLowerCase().replace(/[- ]/g, '');
    const normalizedValue = value.toLowerCase().replace(/[- ]/g, '');

    return normalizedName === normalizedValue;
  });

  return filteredList.length > 0 ? filteredList[0] : null;
};
