import { createUseStyles } from 'react-jss';

export default createUseStyles({
  alignCenter: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      margin: 0,
      marginLeft: '8px'
    }
  },
  disabled: { height: 40, textAlign: 'center', marginTop: 20 },
  notification: {
    textAlign: 'center',
    padding: 30
  }
});
