import { signal } from 'rb-toolset/lib/signal';

import { notify, dispatchInput } from 'rb-toolset/lib/signal/factories';

import {
  saveCustomBonusPhoto,
  deleteCustomBonusPhoto
} from 'actions/photos/photos';

import { getPropertyByIdAction } from 'actions/property/property';
import {
  API_PROPERTY_LOADED,
  API_PROPERTY_BONUS_LOADED
} from 'actions/actionTypes';

import {
  addBonus,
  removeBonus,
  saveCustomBonus,
  getCustomBonus
} from 'actions/property/property-bonus';

export const addBonus$ = signal([
  addBonus,
  getPropertyByIdAction,
  dispatchInput(API_PROPERTY_LOADED, 'property'),
  notify('Bonus wurde aktiviert')
]);

export const removeBonus$ = signal([
  removeBonus,
  getPropertyByIdAction,
  dispatchInput(API_PROPERTY_LOADED, 'property'),
  notify('Bonus wurde deaktiviert')
]);

export const getCustomBonus$ = signal([
  getCustomBonus,
  dispatchInput(API_PROPERTY_BONUS_LOADED, 'bonus')
]);

export const saveCustomBonus$ = signal([
  saveCustomBonus,
  getCustomBonus,
  notify('Bonus wurde gespeichert')
]);

export const saveCustomBonusPhoto$ = signal([
  saveCustomBonusPhoto,
  notify('Foto wurde gespeichert')
]);

export const deleteCustomBonusPhoto$ = signal([
  deleteCustomBonusPhoto,
  getCustomBonus,
  notify('Foto wurde zurückgesetzt')
]);
