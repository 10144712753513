import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { SelectInput, TextInput } from 'rb-toolset/lib/ui/FormControls';
import { FormContainer } from 'rb-toolset/lib/ui/FormControls';
import { ChannelManager } from 'rb-domain';

const { SEEKDA, HOTELPARTNER } = ChannelManager.CODES;

let ChannelManagerForm = ({
  change,
  formValues: { changeApiKey, channelManagerCode, channelManagerApiKey } = {},
  handleSubmit,
  invalid,
  managers
}) => {
  React.useEffect(() => {
    change('changeApiKey', true);
    change('channelManagerPropertyId', null);
    change('channelManagerApiKeyNew', null);
  }, [channelManagerCode]);

  const mappedManagers = managers.map(manager => {
    return {
      value: manager.code,
      label: manager.name
    };
  });

  return (
    <FormContainer
      invalid={invalid}
      title="API Key für Channelmanager"
      handleSubmit={handleSubmit}
    >
      <Field
        autoWidth
        component={SelectInput}
        options={mappedManagers}
        name="channelManagerCode"
        label="Channel Manager"
      />
      {[SEEKDA, HOTELPARTNER].indexOf(channelManagerCode) > -1 && (
        <React.Fragment>
          <Field
            component={TextInput}
            label="Property ID"
            name="channelManagerPropertyId"
            fullWidth
          />
          {!changeApiKey && (
            <Field
              name="changeApiKey"
              component={({ input }) => (
                <p>
                  <br />
                  <span>Ihr API Key: {channelManagerApiKey}</span>{' '}
                  <button
                    type="button"
                    onClick={event => {
                      event.preventDefault();
                      input.onChange(true);
                    }}
                  >
                    (ändern)
                  </button>
                </p>
              )}
            />
          )}
          {changeApiKey && (
            <Field
              component={TextInput}
              label="API Key"
              name="channelManagerApiKeyNew"
              fullWidth
            />
          )}
        </React.Fragment>
      )}
    </FormContainer>
  );
};

ChannelManagerForm.propTypes = {
  formValues: PropTypes.object,
  managers: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired
};

ChannelManagerForm = connect(state => ({
  formValues: getFormValues('ChannelManager')(state)
}))(ChannelManagerForm);

ChannelManagerForm = reduxForm({
  form: 'ChannelManager',
  validate: values => {
    const errors = {};

    if (!values.channelManagerApiKeyNew) {
      errors.channelManagerApiKeyNew = 'Bitte geben Sie einen API Key ein.';
    }

    return errors;
  }
})(ChannelManagerForm);

export default connect(state => ({
  initialValues: {
    ...state.property,
    changeApiKey: !state.property.channelManagerApiKey
  },
  managers: state.ui.channelManagers
}))(ChannelManagerForm);
