import React from 'react';
import PropTypes from 'prop-types';
import { BackLink } from 'rb-toolset/lib/ui/FormControls';
import { connect } from 'react-redux';
import { asyncConnect } from 'redux-connect';

import {
  getCustomBonus$,
  saveCustomBonus$,
  saveCustomBonusPhoto$,
  deleteCustomBonusPhoto$
} from 'signals/bonus';

import PaperContainer from 'components/PaperContainer/PaperContainer';
import HelpboxContainer from 'components/HelpboxContainer/HelpboxContainer';

import BonusCustomizeForm from './BonusCustomizeForm/BonusCustomizeForm';

let BonusCustomize = ({
  bonusCode,
  deleteCustomBonusPhoto,
  propertyId,
  saveCustomBonus,
  saveCustomBonusPhoto
}) => {
  const onSubmit = React.useCallback(formData =>
    saveCustomBonus({
      code: bonusCode,
      propertyId,
      bonus: formData
    })
  );

  const onPhotoReset = React.useCallback(url =>
    deleteCustomBonusPhoto({
      url,
      code: bonusCode,
      propertyId
    })
  );

  const onUpload = React.useCallback(uuid =>
    saveCustomBonusPhoto({
      bonus: {
        propertyId,
        code: bonusCode,
        uuid
      }
    })
  );

  return (
    <PaperContainer
      helpBox={[
        <HelpboxContainer headline="Der Bonuswert und die ROOMBONUS Bonusmatrix">
          {`Wir empfehlen allen Hotelpartnern einen Zwischenwert zwischen
          Einkaufspreis und Verkaufspreis bei hoteleigenen Bonusleistungen
          anzugeben, tendenziell näher zum Einkaufspreis gelegen.`}
          <p>
            <strong>Als Beispiel:</strong>{' '}
            {`Sie haben die Zusatzleistung "Flasche Champagner" für Ihr
            Haus ausgewählt und als Bonuswert EUR 40,- definiert. Der Gast
            bucht zwei Übernachtungen
            in Ihrem Haus zu EUR 450,-. Dementsprechend wird dieser Bonus dem
            Gast beim
            Buchungsprozess zur Auswahl angezeigt. Liegt der Aufenthaltswert
            z.B. nur bei 250,- EUR
            wird diese Zusatzleistung dem Gast nicht angezeigt, da die oben
            genannte 10% Regelung
            nicht zur Geltung kommt."`}
          </p>
        </HelpboxContainer>
      ]}
    >
      <div>
        <BackLink to={`/${propertyId}/bonuses`}>
          « zurück zur Bonus-Übersicht
        </BackLink>
        <BonusCustomizeForm {...{ onSubmit, onUpload, onPhotoReset }} />
      </div>
    </PaperContainer>
  );
};

BonusCustomize.propTypes = {
  bonusCode: PropTypes.string.isRequired,
  deleteCustomBonusPhoto: PropTypes.func.isRequired,
  propertyId: PropTypes.number.isRequired,
  saveCustomBonus: PropTypes.func.isRequired,
  saveCustomBonusPhoto: PropTypes.func.isRequired
};

const mapStateToProps = (state, options) => ({
  propertyId: parseInt(state.property.id, 10),
  bonusCode: options.match.params.code
});

const mapDispatchToProps = {
  deleteCustomBonusPhoto: deleteCustomBonusPhoto$,
  saveCustomBonus: saveCustomBonus$,
  saveCustomBonusPhoto: saveCustomBonusPhoto$
};

BonusCustomize = connect(mapStateToProps, mapDispatchToProps)(BonusCustomize);

export default asyncConnect([
  {
    promise: options => {
      const { store: { dispatch } } = options;
      const promises = [];
      promises.push(
        dispatch(
          getCustomBonus$({
            code: options.match.params.code,
            propertyId: options.match.params.propertyId
          })
        )
      );
      return Promise.all(promises);
    }
  }
])(BonusCustomize);
