import { createUseStyles } from 'react-jss';

export default createUseStyles({
  container: {
    '& .MuiListSubheader-root:first-child': {
      marginTop: '0 !important'
    }
  },
  formContainer: {
    paddingTop: '0 !important'
  }
});
