import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import Notification from 'rb-toolset/lib/ui/Notification/Notification';
import { Loader } from 'rb-toolset/lib/ui/Loader';

import Colors from 'consts/Colors';

import * as authSignals from 'signals/auth';

import ScaffoldDialogs from './ScaffoldDialogs';
import ScaffoldAppBar from './ScaffoldAppBar';
import Navigation from './Navigation/Navigation';

import useStyles from './ScaffoldStyles';

const Scaffold = ({ loaded, noHotelsAssigned, route, tokenRefresher }) => {
  const styles = useStyles();

  React.useEffect(() => {
    tokenRefresher();
  }, []);

  return (
    <div className={styles.container}>
      <ScaffoldDialogs />
      {!noHotelsAssigned &&
        <div>
          <ScaffoldAppBar />
          {loaded
            ? renderRoutes(route.routes)
            : <Loader color={Colors.roombonus} />}
          <Navigation />
          <Notification />
        </div>}
    </div>
  );
};

Scaffold.propTypes = {
  loaded: PropTypes.bool.isRequired,
  noHotelsAssigned: PropTypes.bool.isRequired,
  route: PropTypes.object,
  tokenRefresher: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  loaded: state.reduxAsyncConnect.loaded,
  noHotelsAssigned: state.ui.noHotelsAssigned
});

const mapDispatchToProps = {
  tokenRefresher: authSignals.tokenRefresher
};

export default connect(mapStateToProps, mapDispatchToProps)(Scaffold);
