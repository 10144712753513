import { createUseStyles } from 'react-jss';

import Colors from 'consts/Colors';

export default createUseStyles({
  container: {
    marginLeft: '256px'
  },
  appBar: {
    height: '50px',
    justifyContent: 'center',
    backgroundColor: Colors.roombonus,
    '& > div': {
      backgroundColor: Colors.roombonus,
      maxHeight: '50px',
      minHeight: 'initial'
    }
  },
  menuWrapper: {
    marginLeft: 'auto'
  },
  menuButton: {
    marginTop: '0 !important',
    color: `${Colors.white} !important`,
    marginRight: `6px`,
    '& span': {
      fontSize: '14px !important',
      verticalAlign: 'middle'
    }
  },
  addHotel: {
    marginTop: '6px !important',
    color: `${Colors.white} !important`,
    marginRight: '6px',
    width: '115px'
  },
  center: {
    textAlign: 'center'
  },
  addPropertyButton: {
    width: 40,
    height: 40,
    borderRadius: '50%',
    background: '#bdbdbd',
    color: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    '& > *': {
      fontSize: '30px !important'
    }
  }
});
