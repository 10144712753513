import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { ListItem, List } from '@material-ui/core';
import { FormContainer } from 'rb-toolset/lib/ui/FormControls';

import PaperContainer from 'components/PaperContainer/PaperContainer';
import HelpboxContainer from 'components/HelpboxContainer/HelpboxContainer';

const Rooms = ({ propertyId, rooms }) => {
  // const styles = require('./Rooms.scss');
  const { push } = useHistory();
  const onRoomSelected = React.useCallback(room => {
    push(`/${propertyId}/housing/rooms/edit/${room.id}`);
  });

  const onNew = React.useCallback(() => {
    push(`/${propertyId}/housing/rooms/new`);
  });

  return (
    <PaperContainer
      helpBox={[
        <HelpboxContainer headline="Zimmerkategorien">
          In diesem Menü können Sie Ihre Zimmerkategorien und Preise anlegen.
          Bitte fügen Sie mit dem Plus Symbol eine neue Zimmerkategorie hinzu
          und bearbeiten Sie die Kategorie <strong>gewissenhaft</strong> bevor
          Sie Ihre Verfügbarkeiten einpflegen!
        </HelpboxContainer>
      ]}
    >
      <FormContainer
        title="Zimmerkategorien / Ratenpläne"
        hideFooter
        handleNew={onNew}
        fullWidth
      >
        <List>
          {rooms.map(room =>
            <ListItem button key={room.id} onClick={() => onRoomSelected(room)}>
              {room.name}
            </ListItem>
          )}
        </List>
      </FormContainer>
    </PaperContainer>
  );
};

Rooms.propTypes = {
  rooms: PropTypes.array.isRequired,
  propertyId: PropTypes.number.isRequired
};

export default connect((state, params) => ({
  rooms: state.property.rooms,
  propertyId: parseInt(params.match.params.propertyId, 10)
}))(Rooms);
