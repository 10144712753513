import React from 'react';
import PropTypes from 'prop-types';
import { BackLink } from 'rb-toolset/lib/ui/FormControls';
import { connect } from 'react-redux';
import PaperContainer from 'components/PaperContainer/PaperContainer';
import HelpboxContainer from 'components/HelpboxContainer/HelpboxContainer';
import {
  updateCancellationGroup as updateCancellationGroupSignal,
  deleteCancellationGroup as deleteCancellationGroupSignal,
  deleteCancellation as deleteCancellationSignal
} from 'signals/property-policy';

import CancellationGroupForm from './CancellationGroupForm/CancellationGroupForm';

const CancellationGroup = ({
  cancellationGroup,
  deleteCancellationGroup,
  deleteCancellation,
  propertyId,
  updateCancellationGroup
}) => {
  const onSubmit = React.useCallback(formData =>
    updateCancellationGroup({
      propertyId,
      cancellationGroup: formData
    })
  );

  const onDelete = React.useCallback(id =>
    deleteCancellationGroup({ id, propertyId })
  );

  const onDeleteCancellation = React.useCallback(id =>
    deleteCancellation({ id, propertyId })
  );

  return (
    <div>
      <PaperContainer
        helpBox={[
          <HelpboxContainer headline="Stornierungrichtlinien">
            <strong>Beispiel Freizeithotel</strong>
            <br />
            <p>
              Low Season 01.03.-31.05. / Stornierung bis 2 Wochen vor Anreise
              50%, Stornierung bis 1 Woche vor Anreise 75%, Stornierung bis 3
              Tage vor Anreise 90%
            </p>
            <p>
              High Season 01.06.-15.09. / Stornierung bis 4 Wochen vor Anreise
              50%, Stornierung bis 2 Woche vor Anreise 75%, Stornierung bis 7
              Tage vor Anreise 90%
            </p>
            <strong>Beispiel Stadthotel</strong>
            <br />
            <p>Non-Refundable – 90% Stonierungskosten</p>
            <p>
              Anreise bis 18:00 Uhr garantiert / Stornierung bis 18:00 Uhr am
              Anreisetag möglich
            </p>
            <p>
              Bis 2 Wochen vor Anreise kostenfreie Stornierung möglich /
              danach 90% (z.B. bei einer Messerate)
            </p>
          </HelpboxContainer>
        ]}
      >
        <div>
          <BackLink to={`/${propertyId}/housing/policies`}>
            « zurück zur AGBs/Stornierung
          </BackLink>
          <CancellationGroupForm
            initialValues={cancellationGroup}
            {...{
              onSubmit,
              onDelete,
              onDeleteCancellation,
              cancellationGroup
            }}
          />
        </div>
      </PaperContainer>
    </div>
  );
};

CancellationGroup.propTypes = {
  propertyId: PropTypes.number.isRequired,
  cancellationGroup: PropTypes.object.isRequired,
  updateCancellationGroup: PropTypes.func.isRequired,
  deleteCancellationGroup: PropTypes.func.isRequired,
  deleteCancellation: PropTypes.func.isRequired
};

export default connect(
  (state, options) => ({
    isUploading: state.ui.isUploading,
    cancellationGroup: options.match.params.id
      ? state.property.cancellationGroups.filter(
          item => item.id === parseInt(options.match.params.id, 10)
        )[0]
      : {},
    propertyId: parseInt(state.property.id, 10)
  }),
  {
    updateCancellationGroup: updateCancellationGroupSignal,
    deleteCancellationGroup: deleteCancellationGroupSignal,
    deleteCancellation: deleteCancellationSignal
  }
)(CancellationGroup);
