import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { SelectInput } from 'rb-toolset/lib/ui/FormControls';
import { Column, Unit } from 'rb-toolset/lib/ui/Layout';

const AvailabilityScreenHeader = ({ selectedRoom, rooms }) => {
  const categories = Object.values(rooms).map(room => ({
    value: room.id,
    label: room.name
  }));

  const roomOptions = selectedRoom
    ? Object.values(selectedRoom.options).map(option => ({
        label: option.name,
        value: option.id
      }))
    : [];

  return (
    <Unit>
      <Column width={1 / 2}>
        <Field
          component={SelectInput}
          fullWidth
          label="Verfügbarkeiten für Kategorie"
          name="selectedRoomId"
          options={categories}
        />
      </Column>
      <Column width={1 / 2}>
        <Field
          component={SelectInput}
          fullWidth
          label="Zeige Preise für die folgende Rate"
          name="selectedRoomOptionId"
          options={roomOptions}
        />
      </Column>
    </Unit>
  );
};

AvailabilityScreenHeader.propTypes = {
  rooms: PropTypes.object,
  selectedRoom: PropTypes.object
};

export default AvailabilityScreenHeader;
