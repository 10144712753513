import { createUseStyles } from 'react-jss';

import Colors from 'consts/Colors';

export default createUseStyles({
  appBar: {
    backgroundColor: `${Colors.roombonus} !important`,
    height: '50px !important',
    width: '256px !important',
    fontSize: '24px',
    fontWeight: '400',
    justifyContent: 'center',
    padding: '0 16px'
  },
  paper: {
    overflowY: 'hidden !important',
    boxShadow:
      'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px !important',
    border: 'none !important',
    zIndex: '2 !important'
  },
  listRoot: {
    overflowY: 'auto'
  }
});
