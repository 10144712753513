import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';

import './RangeSlider.scss';

const Range = Slider.Range;

const RangeSlider = ({ input, min, max, marks }) => {
  const onChange = React.useCallback(value => {
    const newValue = value.indexOf(min) === -1
      ? [min, ...value.slice(1)]
      : value;
    input.onChange(newValue);
  });

  return (
    <Range
      {...{ marks, min, max, onChange }}
      value={input.value}
      min={min}
      max={max}
      pushable={1}
    />
  );
};

RangeSlider.propTypes = {
  input: PropTypes.object.isRequired,
  marks: PropTypes.object.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired
};

export default RangeSlider;
