import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { FormContainer, WYSIWYGEditor } from 'rb-toolset/lib/ui/FormControls';
import { connect } from 'react-redux';
import { TextInput } from 'rb-toolset/lib/ui/FormControls';
import { pick } from 'lodash';

let TermsForm = ({ handleSubmit }) =>
  <FormContainer
    title="Allgemeine Geschäftsbedingungen / Stornierungsbedingungen"
    handleSubmit={handleSubmit}
  >
    <br />
    <strong>Allgemeine Geschäftsbedingungen</strong>
    <Field
      component={WYSIWYGEditor}
      name="termsText"
      placeholder="Ihre Allgemeinen Geschäftsbedingungen"
    />
    <br />
    <strong>Stornierungsbedingungen</strong>
    <Field
      component={WYSIWYGEditor}
      name="cancellationTermsText"
      placeholder="Ihre Stornierungsbedigungen"
    />
    <Field
      component={TextInput}
      name="termsLink"
      label="Link auf ihrer Geschäftsbedigungen"
      placeholder="http://www.example.com"
      fullWidth
    />
    <br />
    <br />
  </FormContainer>;

TermsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

TermsForm = reduxForm({
  form: 'Terms'
})(TermsForm);

export default connect(state => ({
  initialValues: {
    ...pick(state.property, ['termsText', 'cancellationTermsText', 'termsLink'])
  }
}))(TermsForm);
