import { createMuiTheme } from '@material-ui/core/styles';

export default () => {
  // This replaces the textColor value on the palette
  // and then update the keys for each component that depends on it.
  // More on Colors: http://www.material-ui.com/#/customization/colors

  return createMuiTheme({
    palette: {
      primary: {
        main: '#A5245F'
      }
    },
    overrides: {
      MuiToolbar: {
        root: { backgroundColor: '#E8E8E8' }
      }
    }
  });

  // return getMuiTheme(
  //   {
  //     palette: {
  //       primary1Color:
  //     },

  //     typography: {
  //       fontSize: 16,
  //       htmlFontSize: 16
  //     }
  //   },
  //   {
  //     // Server-side vs. client-side rendering
  //     userAgent: request ? request.headers['user-agent'] : navigator.userAgent
  //   }
  // );
};
