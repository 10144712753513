import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { VictoryPie } from 'victory';
import { pink, blue, amber, green } from '@material-ui/core/colors';
import clientRender from './ClientRender';

const colorScale = [pink['800'], blue['800'], amber['800'], green['800']];

class PieChart extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired
  };

  static defaultProps = {};

  constructor(props) {
    super(props);

    this.state = {
      data: [{ x: '', y: 1 }]
    };
  }

  componentWillMount() {
    setTimeout(() => {
      this.setState({
        data: this.props.data
      });
    }, 200);
  }

  render() {
    const { data } = this.state;

    return (
      <VictoryPie
        data={data}
        animate={{
          duration: 1500
        }}
        colorScale={colorScale}
        innerRadius={110}
      />
    );
  }
}

export default clientRender()(PieChart);
