import React from 'react';
import PropTypes from 'prop-types';
import { range, times } from 'lodash';
import {
  reduxForm,
  formValueSelector,
  Field,
  FieldArray,
  propTypes
} from 'redux-form';
import { connect } from 'react-redux';
import {
  FormContainer,
  TextInput,
  SelectInput,
  FormHeader
} from 'rb-toolset/lib/ui/FormControls';
import { Fab, ListSubheader } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { showConfirmation as showConfirmationAction } from 'actions/ui/ui';

import RoomFormDialog from './RoomFormDialog';
import RoomFormOptions from './RoomFormOptions';

import useStyles from './RoomFormStyles';

const selector = formValueSelector('Room');

const parseNumber = value => (value ? parseInt(value, 10) : value);

let RoomForm = ({
  anyTouched,
  array,
  cancellationTerms,
  change,
  cleanupFees,
  formValues: { id, name, occupancyMax, options },
  guarantees,
  handleSubmit,
  invalid,
  onDelete,
  onDeleteOption,
  showConfirmation,
  vistorFees
}) => {
  const styles = useStyles();
  const persons = React.useMemo(() =>
    range(7).map(person => ({
      label: person,
      value: person
    }))
  );

  const [expandedOption, setExpandedOption] = React.useState(null);

  const handleDelete = React.useCallback(({ roomId }) =>
    onDelete({
      roomId
    })
  );

  const handleDeleteRoomOption = React.useCallback(
    ({ roomId, roomOptionId, index }) => {
      onDeleteOption({
        roomId,
        roomOptionId,
        index
      });
      setExpandedOption(null);
      array.remove('options', index);
    }
  );

  const addNewOption = React.useCallback(() => {
    setExpandedOption(options.length);
    array.push('options', {
      name: '',
      prices: new Array(occupancyMax).fill(null),
      pricesWeekend: new Array(occupancyMax).fill(null)
    });
  });

  const initForm = React.useCallback(
    maxPersons => {
      options.forEach((option, index) => {
        const prices = option.prices;
        const pricesWeekend = option.pricesWeekend;

        const diffPrices = Math.abs(maxPersons - prices.length);
        const diffWeekendPrices = Math.abs(maxPersons - pricesWeekend.length);

        const addPricesFields = maxPersons > prices.length;
        const addWeekendPricesFields = maxPersons > pricesWeekend.length;

        if (addPricesFields) {
          times(diffPrices, () => array.push(`options[${index}].prices`));
        } else {
          times(diffPrices, () => array.pop(`options[${index}].prices`));
        }

        if (addWeekendPricesFields) {
          times(diffWeekendPrices, () =>
            array.push(`options[${index}].pricesWeekend`)
          );
        } else {
          times(diffPrices, () => array.pop(`options[${index}].pricesWeekend`));
        }
      });
    },
    [options]
  );

  const [initialized, setInitialized] = React.useState(false);
  React.useEffect(
    () => {
      if (options && !initialized) {
        initForm(occupancyMax);
        setInitialized(true);
      }
    },
    [options]
  );

  const changePersons = React.useCallback((event, maxPersons) => {
    initForm(maxPersons);
    change('occupancyMax', maxPersons);
  });

  return (
    <div>
      <RoomFormDialog
        {...{
          handleDelete,
          handleDeleteRoomOption
        }}
      />
      <FormContainer
        title={id ? `Zimmer ${name} ändern` : 'Neues Zimmer anlegen'}
        handleSubmit={handleSubmit}
        invalid={invalid && anyTouched}
        handleDelete={() =>
          id && showConfirmation('room.delete', { roomId: id })}
        fullWidth
      >
        <div className={styles.formContent}>
          <Field component={TextInput} name="name" label="Zimmername" />
          <br />
          <Field
            component={TextInput}
            name="description"
            label="Zimmerbeschreibung"
            fullWidth
            multiline
            rows={3}
          />
          <br />
          <FormHeader>Zimmerbelegung</FormHeader>
          <Field
            autoWidth
            component={SelectInput}
            options={persons}
            name="occupancyMax"
            parse={parseNumber}
            onChange={changePersons}
            label="Maximalbelegung"
          />
          <br />
          <Field
            autoWidth
            component={SelectInput}
            options={persons}
            parse={parseNumber}
            name="occupancyMin"
            label="Minimalbelegung"
          />&nbsp;
          <Field
            autoWidth
            component={SelectInput}
            options={persons}
            parse={parseNumber}
            name="occupancyStd"
            label="Standardbelegung"
          />
        </div>

        {id &&
          <div className={styles.optionContainer}>
            <ListSubheader disableSticky>Verfügbare Ratenpläne</ListSubheader>
            <div className={styles.optionAdd}>
              <Fab size="small" color="primary" onClick={addNewOption}>
                <Add />
              </Fab>
            </div>
            <FieldArray
              name="options"
              props={{
                cancellationTerms,
                cleanupFees,
                expandedOption,
                guarantees,
                occupancyMax,
                options,
                setExpandedOption,
                showConfirmation,
                vistorFees
              }}
              component={RoomFormOptions}
            />
          </div>}
      </FormContainer>
    </div>
  );
};

RoomForm.propTypes = {
  ...propTypes,
  anyTouched: PropTypes.bool.isRequired,
  cancellationTerms: PropTypes.array.isRequired,
  cleanupFees: PropTypes.array.isRequired,
  formValues: PropTypes.object.isRequired,
  guarantees: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  onDelete: PropTypes.func,
  onDeleteOption: PropTypes.func,
  showConfirmation: PropTypes.func.isRequired,
  vistorFees: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  formValues: selector(state, 'id', 'occupancyMax', 'options', 'name')
});

const mapDispatchToProps = { showConfirmation: showConfirmationAction };

RoomForm = connect(mapStateToProps, mapDispatchToProps)(RoomForm);

export default reduxForm({
  form: 'Room',
  validate: (values = {}) => {
    const errors = {};

    if (values.occupancyMax < values.occupancyMin) {
      errors.occupancyMax =
        'Maximalwert kann nicht kleiner sein als Minimalwert.';
    }

    if (
      values.occupancyStd < values.occupancyMin ||
      values.occupancyMax < values.occupancyStd
    ) {
      errors.occupancyStd =
        'Standardbelegung muss zwischen Minimal- und Maximalwert liegen.';
    }

    errors.options = [];

    if (values.options) {
      values.options.forEach(option => {
        const error = {};

        if (!option.name) {
          error.name = 'Bitte geben Sie einen Ratennamen an.';
        }

        if (option.prices.filter(value => !value).length > 0) {
          error.prices = {
            _error: 'Es sind nicht alle Preise angegeben'
          };
        }

        if (option.pricesWeekend.filter(value => !value).length > 0) {
          error.pricesWeekend = {
            _error: 'Es sind nicht alle Preise angegeben'
          };
        }

        errors.options.push(error);
      });
    }

    return errors;
  }
})(RoomForm);
