import React from 'react';
import PropTypes from 'prop-types';
import { asyncConnect } from 'redux-connect';
import { connect } from 'react-redux';
import { pickBy, mapValues, mapKeys } from 'lodash';

import { getAllProviderTypes } from 'actions/property/property-provider';
import { getAllAccommodationTypes } from 'actions/property/property-accomodation';
import { getAllTags } from 'actions/property/property-tag';

import { saveProperty } from 'signals/property';

import PaperContainer from 'components/PaperContainer/PaperContainer';
import HelpboxContainer from 'components/HelpboxContainer/HelpboxContainer';

import HousingForm from './HousingForm';

let Housing = ({
  accommodationTypes,
  propertyId,
  providers,
  savePropertySignal
}) => {
  const onSubmit = React.useCallback(formData => {
    const currentTypes = mapValues(
      mapKeys(accommodationTypes, 'id'),
      () => true
    );
    const aChanges = pickBy(formData.accommodationTypes, (flag, id) => {
      return currentTypes[id] !== flag;
    });

    const currentProviders = mapValues(mapKeys(providers, 'id'), () => true);
    const pChanges = pickBy(formData.providers, (flag, id) => {
      return currentProviders[id] !== flag;
    });

    savePropertySignal({
      propertyId,
      data: formData,
      accommodationTypeIds: aChanges,
      providerIds: pChanges
    });
  });

  return (
    <PaperContainer
      helpBox={[
        <HelpboxContainer headline="Hoteltyp">
          Klicken Sie den passenden Hoteltyp für Ihr Haus einfach an.
          Selbstverständlich ist eine Mehrfacheingabe von verschiedenen
          Hoteltypen möglich.
        </HelpboxContainer>,
        <HelpboxContainer headline="Buchungsplattformen">
          Wir bitten Sie, uns Buchungsplattformen, auf welchen Ihr Haus
          derzeit gelistet ist, ebenso bekannt zu geben und diese einfach bei
          unten stehender Auswahl auszuwählen. Diese Information benötigen wir
          für Hotels, welche auf Anfragebasis auf ROOMBONUS gelistet sind aber
          auch für die zukünftige Programmierung unserer App sowie Browser
          Add-in.
        </HelpboxContainer>
      ]}
    >
      <HousingForm {...{ onSubmit }} />
    </PaperContainer>
  );
};

Housing.propTypes = {
  savePropertySignal: PropTypes.func.isRequired,
  propertyId: PropTypes.number.isRequired,
  accommodationTypes: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  providers: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  propertyId: parseInt(state.property.id, 10),
  accommodationTypes: state.reduxAsyncConnect.data[0].payload,
  providers: state.reduxAsyncConnect.data[1].payload,
  tags: state.reduxAsyncConnect.data[2].payload
});

const mapDispatchToProps = {
  savePropertySignal: saveProperty
};

Housing = connect(mapStateToProps, mapDispatchToProps)(Housing);

export default asyncConnect([
  {
    key: 'data',
    promise: options => {
      const { store: { dispatch } } = options;

      const promises = [];
      promises.push(dispatch(getAllAccommodationTypes()));
      promises.push(dispatch(getAllProviderTypes()));
      promises.push(dispatch(getAllTags()));
      return Promise.all(promises);
    }
  }
])(Housing);
