import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveProperty as savePropertySignal } from 'signals/property';
import PaperContainer from 'components/PaperContainer/PaperContainer';
import HelpboxContainer from 'components/HelpboxContainer/HelpboxContainer';

import TermsForm from './TermsForm';

const Terms = ({ propertyId, saveProperty }) => {
  const onSubmit = React.useCallback(data => {
    saveProperty({
      propertyId,
      data
    });
  });

  return (
    <PaperContainer
      helpBox={[
        <HelpboxContainer headline="Geschäftsbedingungen">
          <p>
            In diesem Menü können Sie Ihre Allg. Geschäftsbedingungen und
            generelle Stornierungsbedingungen einfügen. Diese können u.a.
            essentielle Informationen zu Saisonzeiten enthalten.
          </p>
          <p>
            <strong>Als Beispiel:</strong>
            <br />
            Low Season 01.03.-31.05. / Stornierung bis 2 Wochen vor Anreise
            50%, Stornierung bis 1 Woche vor Anreise 75%, Stornierung bis 3
            Tage vor Anreise 90%
          </p>
          Sind Ihre Raten bereits über einen Channel Manager mit ROOMBONUS
          verknüpft, werden die Stornierungsbedingungen/Restriktionen
          automatisch mit abgebildet.
        </HelpboxContainer>
      ]}
    >
      <TermsForm onSubmit={onSubmit} />
    </PaperContainer>
  );
};

Terms.propTypes = {
  propertyId: PropTypes.number.isRequired,
  saveProperty: PropTypes.func.isRequired
};

const mapStateToProps = (state, { match: { params: { propertyId } } }) => ({
  propertyId: parseInt(propertyId, 10)
});

const mapDispatchToProps = { saveProperty: savePropertySignal };

export default connect(mapStateToProps, mapDispatchToProps)(Terms);
