import { createUseStyles } from 'react-jss';
import Colors from 'consts/Colors';

export default createUseStyles({
  helperLink: {
    display: 'flex',
    padding: '20px 0 12px',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    '& div:first-child': {
      color: Colors.roombonus,
      cursor: 'pointer'
    }
  },
  mapContainer: {
    height: 300
  },
  container: {
    '& .MuiListSubheader-root:first-child': {
      marginTop: '0 !important'
    }
  },
  formContainer: {
    paddingTop: '0 !important'
  }
});
