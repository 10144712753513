import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout$ } from 'signals/auth';
import { push } from 'connected-react-router';

@connect(() => ({}), { logout$, push })
export default class Logout extends Component {
  static propTypes = {
    logout$: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.logout$();
    this.props.push('/');
  }

  render() {
    return <strong>Logging out</strong>;
  }
}
