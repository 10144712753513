import * as actions from '../actionTypes';

export function getAllBonuses() {
  return (dispatch, getState, { api }) => {
    return api().entity(`bonus`).get('list').then(result => {
      dispatch({
        type: actions.API_BONUS_LIST,
        payload: result.items
      });
    });
  };
}
