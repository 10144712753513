import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { saveProperty as savePropertySignal } from 'signals/property';

import PaperContainer from 'components/PaperContainer/PaperContainer';
import HelpboxContainer from 'components/HelpboxContainer/HelpboxContainer';

import DescriptionForm from './DescriptionForm';

const Description = ({ description, propertyId, saveProperty }) => {
  const onSubmit = React.useCallback(({ customizedHotelDescription }) =>
    saveProperty({
      propertyId,
      data: {
        customizedHotelDescription
      }
    })
  );

  return (
    <PaperContainer
      helpBox={
        <HelpboxContainer>
          {`Bitte geben Sie im Feld "eigene Beschreibung" eine Kurzbeschreibung
            zu Ihrer Unterkunft ein. Beachten Sie, dass wir diesen Text nur als
            Vorschlagstext intern verwenden. Um einen einheitlichen
            Hoteltext-Standard auf ROOMBONUS zu gewährleisten, werden alle
            Hotelbeschreibungen durch unser Content Team professionell
            aufbereitet. Dementsprechend wird Ihre Hotelbeschreibung auf unserer
            Website anders dargestellt werden.`}
        </HelpboxContainer>
      }
    >
      <DescriptionForm
        onSubmit={onSubmit}
        description={description}
        initialValues={{ categories: 'machs' }}
      />
    </PaperContainer>
  );
};

Description.propTypes = {
  description: PropTypes.string.isRequired,
  propertyId: PropTypes.number.isRequired,
  saveProperty: PropTypes.func.isRequired
};

export default connect(
  (state, params) => ({
    propertyId: parseInt(params.match.params.propertyId, 10),
    description: state.property.description
  }),
  { saveProperty: savePropertySignal }
)(Description);
