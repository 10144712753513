import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { Provider, ReactReduxContext } from 'react-redux';
// import useScroll from 'scroll-behavior/lib/useStandardScroll';
import smoothscroll from 'smoothscroll-polyfill';
import ReactDOM from 'react-dom';
// import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';
// import MomentUtils from '@date-io/moment';
import { ConnectedRouter } from 'connected-react-router';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { ReduxAsyncConnect } from 'redux-connect';
// import messages from 'rb-domain/lib/Error/de.error.messages';
// import { IntlProvider } from 'react-intl';
// import injectTapEventPlugin from 'react-tap-event-plugin';
import createStore from './redux/create';
import theme from './theme';
import { getRoutes } from './routes';
// import MuiPickersUtilsProvider from "material-ui-pickers/MuiPickersUtilsProvider";

// Needed for onClick
// http://stackoverflow.com/a/34015469/988941
// injectTapEventPlugin();

smoothscroll.polyfill();

const browserHistory = createHistory();

const store = createStore({
  reduxData: window.reduxData,
  history: browserHistory
  // reduxAsyncConnect: reduxAsyncConnect
});

const render = () => {
  ReactDOM.render(
    <CookiesProvider>
      <CssBaseline />
      <Provider store={store} context={ReactReduxContext}>
        <ConnectedRouter history={browserHistory} context={ReactReduxContext}>
          <ThemeProvider theme={theme()}>
            <ReduxAsyncConnect routes={getRoutes()} />
          </ThemeProvider>
        </ConnectedRouter>
      </Provider>
    </CookiesProvider>,
    document.getElementById('root')
  );
};

render();

if (module.hot) {
  module.hot.accept('./redux/create', () => {
    createStore({ store });
    render();
  });
}
