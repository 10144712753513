import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { SelectInput, TextInput } from 'rb-toolset/lib/ui/FormControls';
import { IconButton } from '@material-ui/core';
import { RemoveCircle } from '@material-ui/icons';
import { Unit, Column } from 'rb-toolset/lib/ui/Layout';

import { calculationBasesOptions, getFieldNames } from './CalculationsUtils';

const parseAmount = value => {
  return value ? parseFloat(value) : null;
};

// to also have 0 as possible values
const validateAmount = value => {
  return !value && value !== 0 ? 'Wert' : null;
};
const validateBase = value =>
  !value ? 'Bitte wählen Sie einen Anzahlungstyp aus' : null;
const validateMaxAmount = value => (!value ? 'Nächte' : null);

const textFieldStyle = { width: '70px' };

const CalculationsDropdowns = ({ array, calculationBases, fields, name }) => {
  const { calculationAmountsName, calculationBasesName } = getFieldNames(name);

  return (
    <div>
      {fields.map((calculationBase, index) => {
        return (
          <Unit key={index}>
            <Column width={6 / 12}>
              <Field
                name={calculationBase}
                component={SelectInput}
                options={calculationBasesOptions}
                label="Anzahlungstyp"
                autoWidth
                validate={validateBase}
              />
            </Column>
            <Column width={2 / 12}>
              <Field
                name={`${calculationAmountsName}[${index}].value`}
                component={TextInput}
                type="number"
                label="Wert"
                style={textFieldStyle}
                validate={validateAmount}
                parse={parseAmount}
              />
            </Column>
            {calculationBases[index] &&
              calculationBases[index] === 'PERCENT_OF_TOTAL_NIGHTS' &&
              <Column width={2 / 12}>
                <Field
                  name={`${calculationAmountsName}[${index}].maxNights`}
                  component={TextInput}
                  label="Nächte"
                  validate={validateMaxAmount}
                  style={textFieldStyle}
                  type="number"
                />
              </Column>}
            <br />
            {index > 0 &&
              <Column width={1 / 6} align="right" style={{ marginTop: 20 }}>
                <IconButton
                  onClick={() => {
                    array.remove(calculationAmountsName, index);
                    array.remove(calculationBasesName, index);
                  }}
                >
                  <RemoveCircle />
                </IconButton>
              </Column>}
          </Unit>
        );
      })}
    </div>
  );
};

CalculationsDropdowns.propTypes = {
  array: PropTypes.object.isRequired,
  calculationBases: PropTypes.array,
  fields: PropTypes.object,
  name: PropTypes.string
};

export default CalculationsDropdowns;
