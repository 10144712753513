import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { asyncConnect } from 'redux-connect';

import {
  getChildageGroups,
  saveChildageGroups as saveChildageGroupsAction
} from 'actions/property/property-childage';

import PaperContainer from 'components/PaperContainer/PaperContainer';
import HelpboxContainer from 'components/HelpboxContainer/HelpboxContainer';

import ChildrenForm from './ChildrenForm/ChildrenForm';

let Children = ({ childAges, propertyId, saveChildageGroups }) => {
  const onSubmit = React.useCallback(formData =>
    saveChildageGroups(propertyId, formData.categories)
  );

  return (
    <PaperContainer
      helpBox={[
        <HelpboxContainer headline="Altersgruppen">
          {`Erstellen Sie ganz einfach über dieses Menü Ihre Altersgruppen mit
            Hilfe unserer Lebenslinie und mit zwei untersch. Discountvarianten
            (Festbetrag oder % Variante).`}
          <p>
            <strong>Als Beispiel</strong>
            {`: In Ihrem Haus können Kinder von 0-3
              Jahren kostenfrei übernachten, Kinder von 4-12 Jahren zahlen nur
              einen Festbetrag von EUR 50,-/Zimmer und Nacht. Kreieren Sie
              dementsprechend zwei neue Gruppen (mit + Symbol hinzufügen) zum
              Beispiel mit den Namen "Baby" und "Kinder". Verschieben Sie die
              Punkte an der Lebenslinie entsprechend Ihrer Alterseinstufungen.
              Wählen Sie bei der Baby-Gruppe Prozent aus und geben 0 ein, da
              alle Gäste in dieser Altersstufe kostenfrei bei Ihnen übernachten.
              Bei Ihrer Kinder-Gruppe wählen Sie Fixbetrag und geben EUR 50,-
              ein. Die Erwachsenengruppe ist immer als Standardgruppe mit dem
              Normalpreis hinterlegt. Diese kann nicht gelöscht und nur
              hinsichtlich der Altersbegrenzung über die Schieberegler der
              Lebenslinie editiert werden.`}
          </p>
        </HelpboxContainer>
      ]}
    >
      <ChildrenForm
        initialValues={{
          categories: childAges
        }}
        {...{ onSubmit }}
      />
    </PaperContainer>
  );
};

Children.propTypes = {
  childAges: PropTypes.array,
  propertyId: PropTypes.number.isRequired,
  saveChildageGroups: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  childAges: state.childAges.currentAges,
  propertyId: parseInt(state.property.id, 10)
});

const mapDispatchToProps = {
  saveChildageGroups: saveChildageGroupsAction
};

Children = connect(mapStateToProps, mapDispatchToProps)(Children);

export default asyncConnect([
  {
    promise: options => {
      const { store: { dispatch } } = options;

      const promises = [];
      promises.push(
        dispatch(getChildageGroups(options.match.params.propertyId))
      );
      return Promise.all(promises);
    }
  }
])(Children);
