import { createUseStyles } from 'react-jss';

export default createUseStyles({
  container: {
    display: 'flex'
  },
  formContainer: {
    width: '60%'
  },
  imageContainer: {
    paddingTop: '18px',
    paddingLeft: '16px',
    width: '40%',
    maxHeight: '275px',
    overflow: 'hidden'
  },
  tile: {
    height: '150px'
  },
  image: {
    width: '100%'
  }
});
