import { splitPrices } from './property-room-option';

export function getAllRoomsForPropertyAction({ input: { propertyId }, api }) {
  return api()
    .entity(`property-room?propertyId=${propertyId}`)
    .get()
    .then(result => {
      // Convert price1, price2 into array
      const patchedItems = result.items.reduce((acc, item) => {
        const patchedOptions = item.options.reduce((accO, option) => {
          const { prices, pricesWeekend } = splitPrices(option);

          return {
            ...accO,
            [option.id]: {
              ...option,
              prices,
              pricesWeekend
            }
          };
        }, {});

        return {
          ...acc,
          [item.id]: {
            ...item,
            options: patchedOptions
          }
        };
      }, {});

      return { rooms: patchedItems };
    });
}

export function updateRoomAction({ input: { roomData }, api }) {
  if (roomData.propertyRoomId) {
    return api().entity('property-room').patch(roomData).then(() => ({}));
  }
  return api().entity('property-room').post(roomData).then(() => ({}));
}

export function deleteRoomAction({
  input: { propertyRoomId, propertyId },
  api
}) {
  return api().entity(`property-room`).delete({
    propertyRoomId,
    propertyId
  });
}
