import * as actions from 'actions/actionTypes';

const initialState = {
  upcomingBookings: []
};

export default function dashboardReducer(state = initialState, action = {}) {
  switch (action.type) {
    case actions.API_UPCOMING_BOOKINGS_LOADED:
      return {
        ...state,
        upcomingBookings: action.payload
      };
    default:
      return state;
  }
}
