export function addTouristicRegion(propertyId, regionTouristicId, api) {
  return api()
    .entity('property-regiontouristic')
    .post({ propertyId, regionTouristicId });
}

export function deleteTouristicRegion(propertyId, regionTouristicId, api) {
  return api()
    .entity('property-regiontouristic')
    .delete({ propertyId, regionTouristicId });
}

export function toggleTouristicRegionsAction({
  input: { propertyId, regionTouristicIds = {} },
  api
}) {
  return Promise.all(
    Object.keys(regionTouristicIds).map(id => {
      if (regionTouristicIds[id]) {
        return addTouristicRegion(propertyId, id, api);
      }
      return deleteTouristicRegion(propertyId, id, api);
    })
  ).then(() => ({}));
  // For now we have to return empty object when calling Promise.all in function-tree
}
