import { createUseStyles } from 'react-jss';

export default createUseStyles({
  container: {
    position: 'relative'
  },
  gridList: {
    marginTop: '24px',
    minHeight: '200px'
  },
  uploadButton: {
    position: 'absolute',
    right: '4%',
    top: '34px',
    zIndex: '10'
  }
});
