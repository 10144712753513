import { Property } from 'rb-domain';

export const OPTIONS = [
  {
    value: 0,
    label: 'keine'
  },
  {
    value: 1,
    label: '1'
  },
  {
    value: 2,
    label: '2'
  },
  {
    value: 2.5,
    label: '2S'
  },
  {
    value: 3,
    label: '3'
  },
  {
    value: 3.5,
    label: '3S'
  },
  {
    value: 4,
    label: '4'
  },
  {
    value: 4.5,
    label: '4S'
  },
  {
    value: 5,
    label: '5'
  },
  {
    value: 5.5,
    label: '5S'
  }
];

export const THEMES = Object.keys(Property.THEME_TYPE).map(theme => ({
  value: theme,
  label: theme
}));

export const CHAINS = [
  {
    value: 0,
    label: 'IBIS'
  },
  {
    value: 2,
    label: 'VHS'
  },
  {
    value: 3,
    label: 'Hilton'
  }
];
