import React from 'react';
import PropTypes from 'prop-types';
import { FormContainer } from 'rb-toolset/lib/ui/FormControls';
import { CheckboxInput, TextInput } from 'rb-toolset/lib/ui/FormControls';
import { FieldArray, Field } from 'redux-form';

import AvailabilityPriceFormField from './AvailabilityPriceFormField';

const AvailabilityPriceForm = ({
  handleSubmit,
  selectedRoomOption,
  onResetToDefaultPrices
}) =>
  <div>
    <FormContainer
      title={`Preis für ${selectedRoomOption.name}`}
      {...{ handleSubmit }}
    >
      <div>
        <FieldArray
          name="prices"
          component={AvailabilityPriceFormField}
          {...{ onResetToDefaultPrices }}
        />
      </div>
      <br />
      <Field
        component={CheckboxInput}
        name="blocked"
        label="Keine Anreise möglich"
      />
      <Field
        component={TextInput}
        label="Minimale Anzahl an Nächten"
        name="minimumDaysToStay"
        fullWidth
      />
    </FormContainer>
  </div>;

AvailabilityPriceForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onResetToDefaultPrices: PropTypes.func.isRequired,
  selectedRoomOption: PropTypes.object.isRequired
};

export default AvailabilityPriceForm;
